var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var $M,bN,dN,pN,tN,BN;$CLJS.ZM=new $CLJS.M(null,"get-month","get-month",-369374731);$M=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.aN=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);bN=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.cN=new $CLJS.M(null,"iso","iso",-1366207543);dN=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.eN=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.fN=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.gN=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.hN=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.iN=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.jN=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.kN=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.lN=new $CLJS.M(null,"us","us",746429226);$CLJS.mN=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.nN=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.oN=new $CLJS.M(null,"now","now",-1650525531);pN=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.qN=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.rN=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.sN=new $CLJS.M(null,"get-week","get-week",-888059349);
tN=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.uN=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.vN=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.wN=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.xN=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.yN=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.zN=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.AN=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);BN=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.CN=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.DN=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.EN=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.FN=new $CLJS.M(null,"target","target",253001721);$CLJS.EJ($CLJS.kN,$CLJS.G([$CLJS.qu,$CLJS.PG,$CLJS.yt,$CLJS.vL]));$CLJS.NI.m(null,$CLJS.hN,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.OI(a);return $CLJS.wd(a)?(a=$CLJS.Qv.g(a,new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.wG,null,$CLJS.eH,null],null),null)),$CLJS.F.g($CLJS.E(a),1)?$CLJS.B(a):a):a});$CLJS.xJ($CLJS.hN,$CLJS.iJ);
for(var GN=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uN,$CLJS.AN],null)),HN=null,IN=0,JN=0;;)if(JN<IN){var KN=HN.X(null,JN);$CLJS.EJ(KN,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZI],null),$CLJS.yt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vL],null)]));$CLJS.xJ(KN,$CLJS.hN);JN+=1}else{var LN=$CLJS.z(GN);if(LN){var MN=LN;if($CLJS.Bd(MN)){var NN=$CLJS.kc(MN),gba=$CLJS.lc(MN),hba=NN,iba=$CLJS.E(NN);GN=gba;HN=hba;IN=iba}else{var ON=$CLJS.B(MN);$CLJS.EJ(ON,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.ZI],null),$CLJS.yt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vL],null)]));$CLJS.xJ(ON,$CLJS.hN);GN=$CLJS.C(MN);HN=null;IN=0}JN=0}else break}
for(var PN=$CLJS.z(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.fN,$CLJS.ZM,$CLJS.rN,$CLJS.zN,$CLJS.vN,$CLJS.eN,$CLJS.CN],null)),QN=null,RN=0,SN=0;;)if(SN<RN){var jba=QN.X(null,SN);$CLJS.EJ(jba,$CLJS.G([$CLJS.qu,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZI],null)],null)]));SN+=1}else{var TN=$CLJS.z(PN);if(TN){var UN=TN;if($CLJS.Bd(UN)){var VN=$CLJS.kc(UN),kba=$CLJS.lc(UN),lba=VN,mba=$CLJS.E(VN);PN=kba;QN=lba;RN=mba}else{var nba=$CLJS.B(UN);$CLJS.EJ(nba,$CLJS.G([$CLJS.qu,
$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZI],null)],null)]));PN=$CLJS.C(UN);QN=null;RN=0}SN=0}else break}$CLJS.EJ($CLJS.jN,$CLJS.G([$CLJS.qu,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.sL],null)]));
for(var WN=$CLJS.z(new $CLJS.dh(null,new $CLJS.k(null,8,[$CLJS.CN,null,$CLJS.vN,null,$CLJS.zN,null,$CLJS.wN,null,$CLJS.eN,null,$CLJS.ZM,null,$CLJS.fN,null,$CLJS.rN,null],null),null)),XN=null,YN=0,ZN=0;;)if(ZN<YN){var oba=XN.X(null,ZN);$CLJS.EJ(oba,$CLJS.G([$CLJS.qu,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZI],null)],null)]));ZN+=1}else{var $N=$CLJS.z(WN);if($N){var aO=$N;if($CLJS.Bd(aO)){var bO=$CLJS.kc(aO),pba=$CLJS.lc(aO),qba=bO,rba=$CLJS.E(bO);
WN=pba;XN=qba;YN=rba}else{var sba=$CLJS.B(aO);$CLJS.EJ(sba,$CLJS.G([$CLJS.qu,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZI],null)],null)]));WN=$CLJS.C(aO);XN=null;YN=0}ZN=0}else break}$CLJS.X(BN,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ht,$CLJS.cN,$CLJS.lN,$CLJS.qN],null));
$CLJS.GJ($CLJS.sN,$CLJS.G([$CLJS.qu,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BN],null)],null)],null)],null)]));
$CLJS.X($CLJS.xN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,$CLJS.vI,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,2,[$CLJS.Au,"valid timezone ID",$CLJS.Cu,function(a){a=$CLJS.Ge(a);a=$CLJS.J.g(a,$CLJS.Cj);return["invalid timezone ID: ",$CLJS.Ph.l($CLJS.G([a]))].join("")}],null)],null),$CLJS.mL($CLJS.Jd,$CLJS.YM.tz.names()))],null));
$CLJS.GJ($CLJS.aN,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xN],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xN],null)],null)],null)],
null)]));$CLJS.xJ($CLJS.aN,$CLJS.hN);$CLJS.EJ($CLJS.oN,$CLJS.G([$CLJS.qu,$CLJS.BG]));$CLJS.X(dN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EI],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Ne(function(b){return $CLJS.QF(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wG,$CLJS.eH],null))}],null)],null));
$CLJS.X($M,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dN],null)],null)],null)],null));
$CLJS.MK.g($CLJS.gN,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ys,new $CLJS.k(null,1,[$CLJS.Au,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.gN],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$M],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$s,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ys,new $CLJS.k(null,1,[$CLJS.Au,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OM],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QM],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JM],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.gi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uL],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ys,new $CLJS.k(null,1,[$CLJS.Au,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.Lx],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SM],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.gi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pL],null)],null)],null)],null)],null)],null));
$CLJS.NI.m(null,$CLJS.gN,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.F.g(b,$CLJS.Lx)?$CLJS.F.g(a,$CLJS.gi)?$CLJS.eH:$CLJS.n($CLJS.wJ($CLJS.uL,a))?$CLJS.wG:$CLJS.eH:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.qh($CLJS.WM,b))?$CLJS.wG:$CLJS.n($CLJS.qh($CLJS.XM,b))?$CLJS.wG:null:null;if($CLJS.n(a))return a;b=$CLJS.OI(b);b=$CLJS.wd(b)?$CLJS.eg.j($CLJS.eh,$CLJS.hf(function(c){return $CLJS.QF(c,$CLJS.Uj)}),b):b;return $CLJS.wd(b)&&
$CLJS.F.g($CLJS.E(b),1)?$CLJS.B(b):b});$CLJS.X(tN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.Lx],null),$CLJS.yt],null));
$CLJS.GJ($CLJS.mN,$CLJS.G([$CLJS.qu,$CLJS.eH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bz,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tN],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vL],null)],null)],null)],null)]));
$CLJS.EJ($CLJS.pC,$CLJS.G([$CLJS.qu,$CLJS.UH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tL],null)]));$CLJS.X(bN,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.ht,$CLJS.rL,$CLJS.Ei,$CLJS.ik,$CLJS.iN,$CLJS.DN,$CLJS.EN,$CLJS.Fj,$CLJS.Ji,$CLJS.Ai,$CLJS.hj,$CLJS.oL],null));$CLJS.X(pN,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ht,$CLJS.cN,$CLJS.lN,$CLJS.qN],null));
$CLJS.GJ($CLJS.yN,$CLJS.G([$CLJS.qu,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bN],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pN],null)],null)],
null)],null)]));