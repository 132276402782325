var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var baa,caa,daa,eaa,faa,gaa,haa,iaa,jaa;baa=function(a,b){a.sort(b||$CLJS.Ea)};caa=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Ea;baa(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.lL=function(a){return $CLJS.F.g(a,$CLJS.Jd)?$CLJS.Jd:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.mL=function(a,b){if($CLJS.z(b)){var c=$CLJS.we.h?$CLJS.we.h(b):$CLJS.we.call(null,b);caa(c,$CLJS.lL(a));return $CLJS.qd($CLJS.z(c),$CLJS.rd(b))}return $CLJS.Kc};$CLJS.nL=new $CLJS.M(null,"second","second",-444702010);$CLJS.oL=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);
$CLJS.pL=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.qL=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.rL=new $CLJS.M(null,"year-of-era","year-of-era",682445876);daa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
eaa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.sL=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.tL=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);
$CLJS.uL=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);faa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);gaa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);
haa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);iaa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.vL=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);
$CLJS.wL=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.xL=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.yL=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
jaa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var zL=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Ji,$CLJS.Fj,$CLJS.Ej,$CLJS.ui,$CLJS.ik,$CLJS.Ei,$CLJS.ni,$CLJS.rL],null),AL=$CLJS.ih(zL),kaa,laa,maa;$CLJS.X(haa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid date extraction unit"],null)],null),AL));var BL=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Gv,$CLJS.nj,$CLJS.wj,$CLJS.ti,$CLJS.ni],null),CL=$CLJS.ih(BL);
$CLJS.X(gaa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid date truncation unit"],null)],null),CL));$CLJS.DL=$CLJS.eg.j($CLJS.Bf,$CLJS.al.o(),$CLJS.cf.g(BL,zL));kaa=$CLJS.ih($CLJS.DL);$CLJS.X($CLJS.uL,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid date bucketing unit"],null)],null),kaa));var EL=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oL,$CLJS.hj,$CLJS.Ai],null),FL=$CLJS.ih(EL);
$CLJS.X(faa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid time extraction unit"],null)],null),FL));var GL=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qL,$CLJS.nL,$CLJS.Ev,$CLJS.Fv],null),HL=$CLJS.ih(GL);$CLJS.X(jaa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid time truncation unit"],null)],null),HL));$CLJS.IL=$CLJS.eg.j($CLJS.Bf,$CLJS.al.o(),$CLJS.cf.g(GL,EL));laa=$CLJS.ih($CLJS.IL);
$CLJS.X(eaa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid time bucketing unit"],null)],null),laa));$CLJS.JL=$CLJS.eg.j($CLJS.Bf,$CLJS.al.o(),$CLJS.cf.l(GL,BL,$CLJS.G([EL,zL])));maa=$CLJS.ih($CLJS.JL);$CLJS.X($CLJS.pL,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid datetime bucketing unit"],null)],null),$CLJS.JL));var naa=$CLJS.ae.g(maa,$CLJS.gi);
$CLJS.X($CLJS.xL,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid temporal bucketing unit"],null)],null),naa));var oaa=$CLJS.Hv.g(CL,HL);$CLJS.X($CLJS.sL,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid datetime truncation unit"],null)],null),oaa));$CLJS.KL=$CLJS.Hv.g(AL,FL);$CLJS.X(daa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid datetime extraction unit"],null)],null),$CLJS.KL));
var LL=$CLJS.ae.g(CL,$CLJS.ni);$CLJS.X($CLJS.yL,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid date interval unit"],null)],null),LL));$CLJS.X($CLJS.tL,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid time interval unit"],null)],null),HL));var paa=$CLJS.Hv.g(LL,HL);$CLJS.X($CLJS.vL,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid datetime interval unit"],null)],null),paa));
$CLJS.X(iaa,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.wL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wi,$CLJS.xL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Ct],null)],null));