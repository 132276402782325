var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var rI,tI,wI,HI;$CLJS.lI=new $CLJS.M("metabase.lib.schema.id","metric","metabase.lib.schema.id/metric",369478774);$CLJS.mI=new $CLJS.M("metabase.lib.schema.common","positive-int","metabase.lib.schema.common/positive-int",-733946956);$CLJS.nI=new $CLJS.M("metabase.lib.schema.id","segment","metabase.lib.schema.id/segment",-983297351);$CLJS.oI=new $CLJS.M(null,"num-bins","num-bins",847672055);$CLJS.pI=new $CLJS.M(null,"mbql","mbql",69346710);
$CLJS.qI=new $CLJS.M(null,"display-name","display-name",694513143);rI=new $CLJS.M("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.sI=new $CLJS.M("metabase.lib.schema.common","semantic-or-relation-type","metabase.lib.schema.common/semantic-or-relation-type",-158604052);tI=new $CLJS.M("metabase.lib.schema.common","relation-type","metabase.lib.schema.common/relation-type",1412287664);$CLJS.uI=new $CLJS.M("lib","external-op","lib/external-op",-1470923877);
$CLJS.vI=new $CLJS.M("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);wI=new $CLJS.M("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);$CLJS.xI=new $CLJS.M(null,"database-type","database-type",-426840562);$CLJS.yI=new $CLJS.M(null,"operator","operator",-1860875338);
$CLJS.zI=new $CLJS.M("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.AI=new $CLJS.M("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);$CLJS.BI=new $CLJS.M("lib","uuid","lib/uuid",-2145250720);$CLJS.CI=new $CLJS.M(null,"x","x",2099068185);$CLJS.DI=new $CLJS.M("metabase.lib.schema.id","field","metabase.lib.schema.id/field",-985236431);
$CLJS.EI=new $CLJS.M("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);$CLJS.FI=new $CLJS.M(null,"bin-width","bin-width",1377922579);$CLJS.GI=new $CLJS.M(null,"strategy","strategy",-1471631918);HI=new $CLJS.M("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.X($CLJS.vI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xj,new $CLJS.k(null,1,[$CLJS.On,1],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"non-blank string"],null),$CLJS.Oe($CLJS.bE)],null)],null));$CLJS.X($CLJS.zI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yt,new $CLJS.k(null,1,[$CLJS.On,0],null)],null));$CLJS.X($CLJS.mI,$CLJS.Gl);$CLJS.X(HI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xj,new $CLJS.k(null,2,[$CLJS.On,36,$CLJS.gk,36],null)],null));
$CLJS.X(wI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,2,[$CLJS.Au,"valid semantic type",$CLJS.Cu,function(a){a=$CLJS.Ge(a);a=$CLJS.J.g(a,$CLJS.Cj);return["Not a valid semantic type: ",$CLJS.Ph.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.QF(a,$CLJS.rj)}],null));
$CLJS.X(tI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,2,[$CLJS.Au,"valid relation type",$CLJS.Cu,function(a){a=$CLJS.Ge(a);a=$CLJS.J.g(a,$CLJS.Cj);return["Not a valid relation type: ",$CLJS.Ph.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.QF(a,$CLJS.qi)}],null));$CLJS.X($CLJS.sI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tI],null)],null));
$CLJS.X($CLJS.EI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,2,[$CLJS.Au,"valid base type",$CLJS.Cu,function(a){a=$CLJS.Ge(a);a=$CLJS.J.g(a,$CLJS.Cj);return["Not a valid base type: ",$CLJS.Ph.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.QF(a,$CLJS.zj)}],null));
$CLJS.X($CLJS.AI,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BI,HI],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.EI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ii,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.EI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tj,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,
$CLJS.sI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xI,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.vI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.vI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qI,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.vI],null)],null)],null));
$CLJS.X(rI,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.uI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,$CLJS.Xj,$CLJS.Wi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.Dt],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.AI],null)],null));