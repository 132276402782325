var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var L_,Afa,M_,Bfa,Cfa,O_,Efa,Ffa,Gfa,Hfa,R_,S_,T_,U_,W_,Jfa,X_,Y_,Kfa,Q_,Dfa,Z_,$_,Ifa,a0;L_=function(a,b){var c=$CLJS.z(b);for(a=$CLJS.z($CLJS.Ye(a,b));;)if(a)c=$CLJS.C(c),a=$CLJS.C(a);else return c};
Afa=function(){return function(){function a(h,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj.j?$CLJS.lj.j(h,l,m):$CLJS.lj.call(null,h,l,m),$CLJS.el.j?$CLJS.el.j(h,l,m):$CLJS.el.call(null,h,l,m)],null)}function b(h,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj.g?$CLJS.lj.g(h,l):$CLJS.lj.call(null,h,l),$CLJS.el.g?$CLJS.el.g(h,l):$CLJS.el.call(null,h,l)],null)}function c(h){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj.h?$CLJS.lj.h(h):$CLJS.lj.call(null,h),$CLJS.el.h?$CLJS.el.h(h):$CLJS.el.call(null,
h)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj.o?$CLJS.lj.o():$CLJS.lj.call(null),$CLJS.el.o?$CLJS.el.o():$CLJS.el.call(null)],null)}var e=null,f=function(){function h(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.y(A,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.O.N($CLJS.lj,m,t,u,v),$CLJS.O.N($CLJS.el,m,t,u,v)],null)}h.A=3;h.B=function(m){var t=
$CLJS.B(m);m=$CLJS.C(m);var u=$CLJS.B(m);m=$CLJS.C(m);var v=$CLJS.B(m);m=$CLJS.Lc(m);return l(t,u,v,m)};h.l=l;return h}();e=function(h,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,h);case 2:return b.call(this,h,l);case 3:return a.call(this,h,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.y(v,0,null)}return f.l(h,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};M_=function(a){return $CLJS.xP($CLJS.$Z)(a,$CLJS.Bf,$CLJS.Bf)};Bfa=function(a,b,c){return $CLJS.bb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.aK,b),$CLJS.ju(2,2,c))};$CLJS.N_=function(a,b){return $CLJS.fc($CLJS.bb(function(c,d){return $CLJS.hg.j(c,a.h?a.h(d):a.call(null,d),d)},$CLJS.cc($CLJS.N),b))};Cfa=function(a){return $CLJS.cf.g($CLJS.EZ(a),$CLJS.FZ(a))};
O_=function(a,b,c){var d=new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.Ot,null,$CLJS.kl,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.fw(2,c):$CLJS.fw(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?L_(2,c):L_(1,c);var f=$CLJS.I(b,0,null);return $CLJS.z(e)?$CLJS.tZ.j(a,e,function(h){var l=$CLJS.R.j(h,f,null);return $CLJS.Ad(h)?$CLJS.eg.j($CLJS.Bf,$CLJS.Ik.h($CLJS.Ra),l):$CLJS.yd(h)?$CLJS.dE($CLJS.Ua,l):l}):$CLJS.Ak.g(a,f)};
Efa=function(a){return $CLJS.bb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return O_(b,Dfa,d)},a,Cfa(a))};
Ffa=function(a){for(var b=0;;){var c=$CLJS.cd($CLJS.iP.h(a),b);a:{var d=c;for(var e=$CLJS.Bf;;){var f=$CLJS.B($CLJS.ff.g(Afa(),$CLJS.jf($CLJS.Hk.j(P_,$CLJS.B,$CLJS.el),$CLJS.St.h(M_(d)))));if($CLJS.n(f)){var h=f;f=$CLJS.I(h,0,null);h=$CLJS.I(h,1,null);var l=O_(d,f,h),m=$CLJS.iE($CLJS.oC);if($CLJS.n($CLJS.hE("metabase.lib.convert",m))){var t=$CLJS.ME.l($CLJS.G([$CLJS.jD,$CLJS.Ph.l($CLJS.G([h]))])),u=$CLJS.ME,v=u.l,x=$CLJS.Ph,A=x.l;var D=f;D=$CLJS.n(D)?D:$CLJS.CP(M_(d));$CLJS.gE("metabase.lib.convert",
m,$CLJS.n_("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.G([t,v.call(u,$CLJS.G([$CLJS.jD,A.call(x,$CLJS.G([D]))])),$CLJS.ME.l($CLJS.G([$CLJS.ZC,$CLJS.o_($CLJS.B($CLJS.Lv(d,l)))]))])),null)}if($CLJS.F.g(l,d))break a;d=l;e=$CLJS.ae.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,h],null))}else break a}}d=Efa(d);if($CLJS.F.g(c,d)){if($CLJS.F.g(b,$CLJS.E($CLJS.iP.h(a))-1))return a;b+=1}else a=$CLJS.Kk.N(a,$CLJS.iP,$CLJS.R,b,d)}};
Gfa=function(a){var b=$CLJS.A_();return $CLJS.Jk.g(function(c){return $CLJS.F.g($CLJS.$I.h(c),"__join")?$CLJS.Kk.j(c,$CLJS.$I,b):c},a)};Hfa=function(a){return"string"===typeof $CLJS.TS.h(a)?$CLJS.Ak.g($CLJS.R.j(a,$CLJS.GZ,$CLJS.y_($CLJS.TS.h(a))),$CLJS.TS):a};R_=function(a,b){var c=$CLJS.J.j(a,b,Q_);if($CLJS.F.g(c,Q_))throw $CLJS.li(["Unable to find ",$CLJS.Ph.l($CLJS.G([b]))," in map."].join(""),new $CLJS.k(null,2,[Ifa,a,$CLJS.Vy,b],null));return c};
S_=function(){return $CLJS.Ik.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.le(b))?(b=$CLJS.he(b),b=$CLJS.n(b)?$CLJS.F.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};T_=function(a){return $CLJS.eg.j($CLJS.N,S_(),a)};U_=function(a){return $CLJS.He($CLJS.eg.j($CLJS.N,$CLJS.Hk.g(S_(),$CLJS.Ik.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.F.g(c,$CLJS.Ii)})),a))};
W_=function(a){var b=$CLJS.z(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);var d=$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.ff.h($CLJS.V_),c);a=$CLJS.F.g(a,$CLJS.WJ)&&$CLJS.C(c)?$CLJS.ae.g($CLJS.td(d),new $CLJS.k(null,1,[$CLJS.gi,$CLJS.sd(d)],null)):d;b=$CLJS.He(U_(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dQ,a,b],null):a};Jfa=function(a){return $CLJS.eg.j($CLJS.Bf,$CLJS.Hk.g($CLJS.ff.h(function(b){return $CLJS.YD(b,$CLJS.QE)}),$CLJS.ff.h($CLJS.V_)),$CLJS.v_.h(a))};
X_=function(a){a=$CLJS.Ge(a);a=$CLJS.J.g(a,$CLJS.iP);a=$CLJS.B($CLJS.bb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.V_.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.wU,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.FS,Jfa(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.cZ.h(c)],null)},null,a));return $CLJS.n($CLJS.XT.h(a))?$CLJS.qZ(a,new $CLJS.k(null,1,[$CLJS.XT,$CLJS.rQ],null)):a};
Y_=function(a,b,c){var d=$CLJS.F.g($CLJS.E($CLJS.J.g(a,b)),1)?$CLJS.uZ.j(a,b,$CLJS.Hk.g($CLJS.V_,$CLJS.B)):a;a=1<$CLJS.E($CLJS.J.g(a,b))?$CLJS.uZ.j(d,b,function(e){return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.st],null),$CLJS.ff.h($CLJS.V_),e)}):d;return $CLJS.qZ(a,$CLJS.Ee([b,c]))};Kfa=function(a){var b=$CLJS.GZ.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Ak.g(a,$CLJS.GZ),$CLJS.TS,["card__",$CLJS.p.h(b)].join("")):a};
Q_=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Dfa=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);Z_=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);$_=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);Ifa=new $CLJS.M(null,"m","m",1632677161);
a0=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var b0,c0,P_,Lfa,Mfa,Nfa,Ofa,Pfa,Qfa,Rfa,Sfa,Tfa,Ufa;b0=$CLJS.N;c0=$CLJS.N;P_=new $CLJS.dh(null,new $CLJS.k(null,7,[$CLJS.XZ,null,$CLJS.WI,null,$CLJS.oR,null,$CLJS.NT,null,$CLJS.uS,null,$CLJS.DV,null,$CLJS.aK,null],null),null);Lfa=$CLJS.Te($CLJS.N);Mfa=$CLJS.Te($CLJS.N);Nfa=$CLJS.Te($CLJS.N);Ofa=$CLJS.Te($CLJS.N);Pfa=$CLJS.J.j(new $CLJS.k(null,1,[$CLJS.gj,$CLJS.DF],null),$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));
$CLJS.d0=new $CLJS.hi($CLJS.Ih.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.BF,Pfa,Lfa,Mfa,Nfa,Ofa);$CLJS.d0.m(null,$CLJS.gi,function(a){if($CLJS.Ad(a)&&$CLJS.B(a)instanceof $CLJS.M){var b=$CLJS.sd(a);var c=$CLJS.yd(b);a=c?$CLJS.td(a):a;var d=$CLJS.z(a);a=$CLJS.B(d);d=$CLJS.C(d);b=$CLJS.g_($CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.ff.h($CLJS.d0),d))}else b=a;return b});$CLJS.d0.m(null,$CLJS.WZ,function(a){return a});
$CLJS.d0.m(null,$CLJS.LZ,function(a){var b=$CLJS.d0.h($CLJS.aK.h(a)),c=$CLJS.He($CLJS.Jk.g(function(h){var l=$CLJS.I(h,0,null);h=$CLJS.I(h,1,null);return $CLJS.s_($CLJS.d0.h(h),l)},$CLJS.NT.h(a))),d=c0;c0=$CLJS.eg.j($CLJS.N,$CLJS.Xl(function(h,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.Ge(l);l=$CLJS.J.g(l,$CLJS.BI);return new $CLJS.P(null,2,5,$CLJS.Q,[h,l],null)}),b);try{var e=Bfa(Hfa(a),b,$CLJS.G([$CLJS.NT,c])),f=$CLJS.bb(function(h,l){return $CLJS.Ta($CLJS.J.g(h,l))?h:$CLJS.Kk.j(h,l,$CLJS.d0)},
e,$CLJS.Bk.l(P_,$CLJS.aK,$CLJS.G([$CLJS.NT])));return $CLJS.n($CLJS.oR.h(f))?$CLJS.Kk.j(f,$CLJS.oR,Gfa):f}finally{c0=d}});$CLJS.d0.m(null,$CLJS.RZ,function(a){return $CLJS.uZ.v(a,$CLJS.tU,$CLJS.Lu,function(b){return $CLJS.uZ.j(b,$CLJS.SP,$CLJS.d0)})});
$CLJS.d0.m(null,$CLJS.dP,function(a){a=$CLJS.Kk.j($CLJS.Kk.j(a,$CLJS.lP,$CLJS.d0),$CLJS.iP,$CLJS.d0);var b=$CLJS.n($CLJS.WI.h(a))?$CLJS.Kk.j(a,$CLJS.WI,function(c){return $CLJS.Dl(c)?$CLJS.Jk.g($CLJS.d0,c):$CLJS.Ch.h(c)}):a;return $CLJS.Ta($CLJS.$I.h(a))?$CLJS.R.j(b,$CLJS.$I,"__join"):b});$CLJS.d0.m(null,$CLJS.wE,function(a){return $CLJS.Jk.g($CLJS.d0,a)});
$CLJS.d0.m(null,$CLJS.yE,function(a){return $CLJS.n($CLJS.lj.h(a))?Ffa($CLJS.Kk.j($CLJS.w_(a),$CLJS.iP,function(b){return $CLJS.Jk.g($CLJS.d0,b)})):$CLJS.Lu(a,$CLJS.d0)});$CLJS.d0.m(null,$CLJS.lK,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.yd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.g_(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lK,a,b],null))});
$CLJS.d0.m(null,$CLJS.Cj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.qZ(a,new $CLJS.k(null,3,[$CLJS.SG,$CLJS.CE,$CLJS.qS,$CLJS.Tj,$CLJS.LV,$CLJS.xI],null));var c=$CLJS.R.j;var d=$CLJS.Ii.h(a);$CLJS.n(d)||(d=$CLJS.CE.h(a),d=$CLJS.n(d)?d:$CLJS.OI(b));a=c.call($CLJS.R,a,$CLJS.Ii,d);return $CLJS.g_(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,a,b],null))});
$CLJS.d0.m(null,$CLJS.WJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.gi.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WJ,$CLJS.Ak.g(c,$CLJS.gi),$CLJS.Jk.g($CLJS.d0,b)],null);b=$CLJS.g_(b);return null!=a?$CLJS.ae.g(b,$CLJS.d0.h(a)):b});$CLJS.d0.m(null,$CLJS.zE,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.g_(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.d0.m(null,$CLJS.aK,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.g_(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,R_(c0,c)],null))});$CLJS.d0.m(null,$CLJS.dQ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.d0.h(b);var c=$CLJS.z(b);b=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);d=$CLJS.C(d);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.Zk.l($CLJS.G([c,a]))],null),d)});Qfa=$CLJS.Te($CLJS.N);Rfa=$CLJS.Te($CLJS.N);Sfa=$CLJS.Te($CLJS.N);
Tfa=$CLJS.Te($CLJS.N);Ufa=$CLJS.J.j(new $CLJS.k(null,1,[$CLJS.gj,$CLJS.DF],null),$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));$CLJS.V_=new $CLJS.hi($CLJS.Ih.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.BF,Ufa,Qfa,Rfa,Sfa,Tfa);
$CLJS.V_.m(null,$CLJS.gi,function(a){if($CLJS.Ad(a)&&$CLJS.B(a)instanceof $CLJS.M){a=$CLJS.z(a);var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);if($CLJS.yd(a)){c=$CLJS.eg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.cf.g;d=$CLJS.ff.g($CLJS.V_,d);a=U_(a);a=c.call($CLJS.eg,b,e.call($CLJS.cf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.ff.g($CLJS.V_,$CLJS.de(a,d)))}else a=$CLJS.yd(a)?$CLJS.Lu(T_(a),$CLJS.V_):
a;return a});for(var e0=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[a0,$_],null)),f0=null,g0=0,h0=0;;)if(h0<g0){var Vfa=f0.X(null,h0);$CLJS.xJ(Vfa,Z_);h0+=1}else{var i0=$CLJS.z(e0);if(i0){var j0=i0;if($CLJS.Bd(j0)){var k0=$CLJS.kc(j0),Wfa=$CLJS.lc(j0),Xfa=k0,Yfa=$CLJS.E(k0);e0=Wfa;f0=Xfa;g0=Yfa}else{var Zfa=$CLJS.B(j0);$CLJS.xJ(Zfa,Z_);e0=$CLJS.C(j0);f0=null;g0=0}h0=0}else break}
for(var l0=$CLJS.z(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.TA,$CLJS.fK,$CLJS.uK,$CLJS.SJ,$CLJS.gk,$CLJS.zK,$CLJS.On,$CLJS.YJ,$CLJS.sK,$CLJS.CK,$CLJS.yK,$CLJS.GK],null)),m0=null,n0=0,o0=0;;)if(o0<n0){var $fa=m0.X(null,o0);$CLJS.xJ($fa,a0);o0+=1}else{var p0=$CLJS.z(l0);if(p0){var q0=p0;if($CLJS.Bd(q0)){var r0=$CLJS.kc(q0),aga=$CLJS.lc(q0),bga=r0,cga=$CLJS.E(r0);l0=aga;m0=bga;n0=cga}else{var dga=$CLJS.B(q0);$CLJS.xJ(dga,a0);l0=$CLJS.C(q0);m0=null;n0=0}o0=0}else break}
for(var s0=$CLJS.z($CLJS.xf([$CLJS.Os,$CLJS.qu,$CLJS.Ps,$CLJS.HK,$CLJS.WJ,$CLJS.oK,$CLJS.OJ,$CLJS.JK,$CLJS.HJ,$CLJS.$J,$CLJS.KJ,$CLJS.jK,$CLJS.qK,$CLJS.LJ,$CLJS.kN,$CLJS.mN,$CLJS.pC,$CLJS.gN,$CLJS.oN,$CLJS.aN,$CLJS.sN,$CLJS.fN,$CLJS.ZM,$CLJS.rN,$CLJS.zN,$CLJS.vN,$CLJS.eN,$CLJS.CN,$CLJS.uN,$CLJS.AN,$CLJS.NJ,$CLJS.IJ,$CLJS.KK,$CLJS.sM,$CLJS.nK,$CLJS.KA,$CLJS.DK,$CLJS.FK,$CLJS.LK,$CLJS.Qu,$CLJS.ev],!0)),t0=null,u0=0,v0=0;;)if(v0<u0){var ega=t0.X(null,v0);$CLJS.xJ(ega,$_);v0+=1}else{var w0=$CLJS.z(s0);
if(w0){var x0=w0;if($CLJS.Bd(x0)){var y0=$CLJS.kc(x0),fga=$CLJS.lc(x0),gga=y0,hga=$CLJS.E(y0);s0=fga;t0=gga;u0=hga}else{var iga=$CLJS.B(x0);$CLJS.xJ(iga,$_);s0=$CLJS.C(x0);t0=null;u0=0}v0=0}else break}$CLJS.V_.m(null,Z_,function(a){return W_(a)});$CLJS.V_.m(null,$CLJS.yE,function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Hk.g(S_(),$CLJS.ff.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.V_.h(b)],null)})),a)});
$CLJS.V_.m(null,$CLJS.aK,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);return $CLJS.yd(b)?(a=U_(b),c=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aK,R_(b0,c)],null),$CLJS.n(a)?$CLJS.ae.g(c,a):c):a});$CLJS.V_.m(null,$CLJS.wE,function(a){return $CLJS.Jk.g($CLJS.V_,a)});
$CLJS.V_.m(null,$CLJS.lK,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.yd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lK,$CLJS.V_.h(a),U_(b)],null)});
$CLJS.V_.m(null,$CLJS.Cj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=U_($CLJS.qZ(b,new $CLJS.k(null,3,[$CLJS.CE,$CLJS.SG,$CLJS.Tj,$CLJS.qS,$CLJS.xI,$CLJS.LV],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,a,b],null)});
$CLJS.V_.m(null,$CLJS.dP,function(a){var b=T_(a);a=0==$CLJS.$I.h(a).lastIndexOf("__join",0)?$CLJS.Ak.g(b,$CLJS.$I):b;return $CLJS.Zk.l($CLJS.G([$CLJS.Lu($CLJS.Ak.l(a,$CLJS.iP,$CLJS.G([$CLJS.lP])),$CLJS.V_),Y_($CLJS.bh(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.lP],null)),$CLJS.lP,$CLJS.FV),X_(a)]))});
$CLJS.V_.m(null,$CLJS.LZ,function(a){var b=b0;b0=$CLJS.eg.j($CLJS.N,$CLJS.Xl(function(c,d){$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=$CLJS.Ge(d);d=$CLJS.J.g(d,$CLJS.BI);return new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null)}),$CLJS.aK.h(a));try{return $CLJS.bb(function(c,d){return $CLJS.uZ.j(c,d,$CLJS.V_)},Y_($CLJS.uZ.j($CLJS.uZ.j(Kfa(T_(a)),$CLJS.aK,function(c){return $CLJS.Jk.g(W_,c)}),$CLJS.NT,function(c){return $CLJS.eg.g($CLJS.N,function(){return function f(e){return new $CLJS.me(null,function(){for(;;){var h=
$CLJS.z(e);if(h){if($CLJS.Bd(h)){var l=$CLJS.kc(h),m=$CLJS.E(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.md(l,u),x=$CLJS.V_.h(v);v=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.r_(v),$CLJS.F.g($CLJS.Cj,$CLJS.B(x))?$CLJS.jd(x):x],null);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.lc(h))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.B(h);l=$CLJS.V_.h(t);return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.r_(t),$CLJS.F.g($CLJS.Cj,$CLJS.B(l))?$CLJS.jd(l):l],null),f($CLJS.Lc(h)))}return null}},
null,null)}(c)}())}),$CLJS.XZ,$CLJS.SQ),$CLJS.Bk.l(P_,$CLJS.aK,$CLJS.G([$CLJS.XZ,$CLJS.NT])))}finally{b0=b}});$CLJS.V_.m(null,$CLJS.RZ,function(a){return $CLJS.Lu(T_(a),$CLJS.V_)});
$CLJS.V_.m(null,$CLJS.WZ,function(a){var b=T_(a),c=$CLJS.eR.h(b),d=X_(b),e=$CLJS.F.g($CLJS.Dj.h($CLJS.kd($CLJS.iP.h(a))),$CLJS.RZ)?$CLJS.XT:$CLJS.rQ;a=$CLJS.Zk.l;b=$CLJS.Lu($CLJS.Ak.l(b,$CLJS.iP,$CLJS.G([$CLJS.eR])),$CLJS.V_);d=$CLJS.Ee([$CLJS.lj,e,e,d]);c=$CLJS.z(c)?$CLJS.R.j(d,$CLJS.eR,c):d;return a.call($CLJS.Zk,$CLJS.G([b,c]))});