var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.formatting.internal.numbers.js");require("./metabase.shared.formatting.internal.numbers_core.js");require("./metabase.util.js");
'use strict';var gja=function(a,b){return new $CLJS.me(null,function(){a:for(var c=a,d=b;;){d=$CLJS.z(d);var e;if(e=d)e=$CLJS.B(d),e=c.h?c.h(e):c.call(null,e);if($CLJS.n(e))d=$CLJS.Lc(d);else break a}return d},null,null)},hja=function(a,b){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ew(a,b),gja(a,b)],null)},w5=function(a){var b=$CLJS.Zk.l;var c=$CLJS.Ge(a);var d=$CLJS.J.g(c,$CLJS.p5);c=$CLJS.J.g(c,$CLJS.q5);if($CLJS.n($CLJS.n(d)?$CLJS.F.g(c,"currency"):d)){c=$CLJS.k5.h;d=$CLJS.Ch.h(d);var e=$CLJS.q($CLJS.h5);
d=e.h?e.h(d):e.call(null,d);c=c.call($CLJS.k5,d);c=new $CLJS.k(null,2,[$CLJS.o5,c,$CLJS.r5,c],null)}else c=new $CLJS.k(null,1,[$CLJS.r5,2],null);b=b.call($CLJS.Zk,$CLJS.G([c,a]));return $CLJS.n($CLJS.s5.h(a))?$CLJS.R.l($CLJS.Ak.g(b,$CLJS.s5),$CLJS.r5,$CLJS.s5.h(a),$CLJS.G([$CLJS.o5,$CLJS.s5.h(a)])):b},x5=function(a,b){if(null!=a&&null!=a.De)a=a.De(a,b);else{var c=x5[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=x5._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Za("NumberFormatter.format-number-basic",
a);}return a},ija=function(a,b){var c=$CLJS.DD(a.formatToParts(b),$CLJS.G([new $CLJS.k(null,1,[$CLJS.Di,!0],null)]));a=$CLJS.n($CLJS.Ne(function(d){return $CLJS.F.g($CLJS.lj.h(d),"exponentMinusSign")},c))?c:function(){var d=hja(function(f){return $CLJS.ah.g($CLJS.lj.h(f),"exponentInteger")},c),e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.cf.l(e,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.k(null,2,[$CLJS.lj,"exponentPlusSign",$CLJS.Cj,"+"],null)],null),$CLJS.G([d]))}();return $CLJS.O.g($CLJS.p,
$CLJS.ff.g(function(d){var e=$CLJS.Ge(d);d=$CLJS.J.g(e,$CLJS.lj);e=$CLJS.J.g(e,$CLJS.Cj);switch(d){case "exponentSeparator":return"e";default:return e}},a))},jja=function(a,b){switch($CLJS.q5.h(b)){case "scientific":return function(c){return ija(a,c)};default:return function(c){return a.format(c)}}},y5=function(a,b,c,d,e){this.options=a;this.gg=b;this.jg=c;this.Ud=d;this.Oh=e;this.C=393216;this.I=0},kja=function(a,b){return $CLJS.O.g($CLJS.p,function(){return function e(d){return new $CLJS.me(null,
function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.Bd(f)){var h=$CLJS.kc(f),l=$CLJS.E(h),m=$CLJS.qe(l);return function(){for(var v=0;;)if(v<l){var x=$CLJS.md(h,v),A=$CLJS.Ge(x);x=$CLJS.J.g(A,$CLJS.lj);A=$CLJS.J.g(A,$CLJS.Cj);var D=new $CLJS.dh(null,new $CLJS.k(null,2,["currency",null,"integer",null],null),null);D=D.h?D.h(x):D.call(null,x);$CLJS.n(D)&&(x=$CLJS.F.g(x,"integer")?b:A,m.add(x));v+=1}else return!0}()?$CLJS.te($CLJS.ve(m),e($CLJS.lc(f))):$CLJS.te($CLJS.ve(m),null)}var t=$CLJS.B(f);t=$CLJS.Ge(t);
var u=$CLJS.J.g(t,$CLJS.lj);t=$CLJS.J.g(t,$CLJS.Cj);if($CLJS.n(function(){var v=new $CLJS.dh(null,new $CLJS.k(null,2,["currency",null,"integer",null],null),null);return v.h?v.h(u):v.call(null,u)}()))return $CLJS.de($CLJS.F.g(u,"integer")?b:t,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}($CLJS.DD(a.gg.formatToParts(1),$CLJS.G([$CLJS.Di,!0])))}())},z5=function(a){var b=$CLJS.F.g($CLJS.q5.h(a),"currency")?2:null;b=new Intl.NumberFormat("en",$CLJS.bl($CLJS.dE($CLJS.Ua,$CLJS.Ig([$CLJS.dja,
$CLJS.Wia,$CLJS.Zia,$CLJS.aja,$CLJS.fja,$CLJS.p5,$CLJS.$ia,$CLJS.bja,$CLJS.Xia,$CLJS.Yia],[$CLJS.r5.g(a,b),$CLJS.u5.h(a),$CLJS.t5.h(a),$CLJS.cja.h(a),$CLJS.F.g($CLJS.q5.h(a),"scientific")?null:$CLJS.q5.g(a,"decimal"),$CLJS.p5.h(a),!0,$CLJS.o5.g(a,b),$CLJS.F.g($CLJS.q5.h(a),"scientific")?"scientific":null,$CLJS.v5.h(a)]))));var c=$CLJS.u5.h(a);var d=$CLJS.p5.h(a);c=$CLJS.n(d)?null==c||$CLJS.F.g(c,"symbol"):d;d=jja(b,a);return new y5(a,b,c,d,$CLJS.N)},A5=function(a,b){return z5(w5(b)).De(null,a)},C5=
function(a,b){var c=$CLJS.Ak.l(b,$CLJS.LE,$CLJS.G([$CLJS.q5])),d=Math.abs(a);if(0===a)return"0";if(1E3>d)return $CLJS.B5.g?$CLJS.B5.g(a,c):$CLJS.B5.call(null,a,c);b=$CLJS.B($CLJS.jf(function(f){return d>=$CLJS.B(f)},lja));var e=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return[$CLJS.p.h(function(){var f=a/e,h=$CLJS.Zk.l($CLJS.G([c,new $CLJS.k(null,2,[$CLJS.o5,1,$CLJS.r5,1],null)]));return $CLJS.B5.g?$CLJS.B5.g(f,h):$CLJS.B5.call(null,f,h)}()),$CLJS.p.h(b)].join("")},mja=function(a,b){var c=w5(b);if($CLJS.n(D5.h(c)))b=
D5.h(c);else if($CLJS.Ta($CLJS.s5.h(c))&&$CLJS.Ta($CLJS.o5.h(c))&&$CLJS.ah.g($CLJS.q5.h(c),"currency")&&Math.abs(a)<($CLJS.F.g($CLJS.q5.h(c),"percent")?.01:1)){b=$CLJS.R.j;var d=$CLJS.Ak.g(c,$CLJS.r5);c=$CLJS.v5.g(c,0);b=z5(b.call($CLJS.R,d,$CLJS.t5,2>c?2:c))}else b=z5(c);return x5(b,a)};y5.prototype.P=function(a,b){return new y5(this.options,this.gg,this.jg,this.Ud,b)};y5.prototype.O=function(){return this.Oh};
y5.prototype.De=function(a,b){a=this.Ud.h?this.Ud.h(b):this.Ud.call(null,b);var c=$CLJS.eja.h(this.options);$CLJS.n($CLJS.n(c)?$CLJS.ah.g(c,".,"):c)&&(b=$CLJS.B(c),c=$CLJS.jd(c),a=$CLJS.aE(a,/[\.,]/,new $CLJS.k(null,2,[",",$CLJS.n(c)?c:"",".",b],null)));$CLJS.n(this.jg)&&(b=$CLJS.p5.h(this.options),c=$CLJS.i5(b),a=$CLJS.aE($CLJS.aE(a,[$CLJS.jh(b)," "].join(""),c),$CLJS.jh(b),c));return a};
var D5=new $CLJS.M(null,"number-formatter","number-formatter",929788393),E5=new $CLJS.M(null,"scale","scale",-230427353),F5=new $CLJS.M(null,"negative-in-parentheses","negative-in-parentheses",-1676084141),nja=new $CLJS.M(null,"scientific","scientific",316285837);var G5=new $CLJS.k(null,1,[$CLJS.u5,"symbol"],null),oja=$CLJS.bl(G5),lja=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[1E12,"T"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E9,"B"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E6,"M"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E3,"k"],null)],null),H5=function(){var a=$CLJS.Te($CLJS.N),b=$CLJS.Te($CLJS.N),c=$CLJS.Te($CLJS.N),d=$CLJS.Te($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.shared.formatting.numbers",
"format-number-compact*"),function(f,h){f=$CLJS.Ge(h);return $CLJS.J.g(f,$CLJS.q5)},e,a,b,c,d)}();H5.m(null,$CLJS.gi,function(a,b){return C5(a,b)});H5.m(null,"percent",function(a,b){return[$CLJS.p.h(C5(100*a,b)),"%"].join("")});H5.m(null,"currency",function(a,b){b=$CLJS.Zk.l($CLJS.G([b,G5]));var c=z5(b);return 1E3>Math.abs(a)?c.De(null,a):kja(c,C5(a,b))});H5.m(null,"scientific",function(a,b){return A5(a,$CLJS.Zk.l($CLJS.G([b,new $CLJS.k(null,2,[$CLJS.r5,1,$CLJS.o5,1],null)])))});
$CLJS.B5=function B5(a,b){b=$CLJS.oE(b);var d=$CLJS.Ge(b);b=$CLJS.J.g(d,$CLJS.LE);var e=$CLJS.J.g(d,F5),f=$CLJS.J.g(d,$CLJS.q5),h=$CLJS.J.g(d,E5);return $CLJS.n($CLJS.n(h)?!isNaN(h):h)?(b=h*a,f=$CLJS.Ak.g(d,E5),B5.g?B5.g(b,f):B5.call(null,b,f)):$CLJS.n(function(){var l=0>a;return l?e:l}())?["(",$CLJS.p.h(function(){var l=-a,m=$CLJS.R.j(d,F5,!1);return B5.g?B5.g(l,m):B5.call(null,l,m)}()),")"].join(""):$CLJS.n(b)?H5.g(a,w5($CLJS.Ak.g(d,$CLJS.LE))):$CLJS.F.g($CLJS.Ch.h(f),nja)?A5(a,d):mja(a,d)};
module.exports={compact_currency_options_js:oja,format_number:$CLJS.B5};