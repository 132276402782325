var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.schema.common.js");require("./metabase.mbql.predicates.js");require("./metabase.mbql.schema.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var gZ,lZ,oZ;$CLJS.hZ=function(a,b){if("string"===typeof b)return gZ(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.iZ=function(a,b){return $CLJS.fc($CLJS.bb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.ec(c,d):c},$CLJS.cc($CLJS.Bf),b))};$CLJS.jZ=function(a){return a instanceof Error?a.message:null};
$CLJS.kZ=function(a,b,c){if($CLJS.Zd(c)){var d=$CLJS.O.g($CLJS.V,$CLJS.ff.g(a,c));return b.h?b.h(d):b.call(null,d)}return $CLJS.yf(c)?(d=new $CLJS.Yf(function(){var e=$CLJS.Ab(c);return a.h?a.h(e):a.call(null,e)}(),function(){var e=$CLJS.Bb(c);return a.h?a.h(e):a.call(null,e)}()),b.h?b.h(d):b.call(null,d)):$CLJS.Ed(c)?(d=$CLJS.ph($CLJS.ff.g(a,c)),b.h?b.h(d):b.call(null,d)):$CLJS.zd(c)?(d=$CLJS.bb(function(e,f){return $CLJS.ae.g(e,a.h?a.h(f):a.call(null,f))},c,c),b.h?b.h(d):b.call(null,d)):$CLJS.vd(c)?
(d=$CLJS.eg.g($CLJS.ld(c),$CLJS.ff.g(a,c)),b.h?b.h(d):b.call(null,d)):b.h?b.h(c):b.call(null,c)};$CLJS.mZ=function(a){function b(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return"string"===typeof d?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ch.h(d),c],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null)}return lZ(function(c){return $CLJS.yd(c)?$CLJS.eg.g($CLJS.N,$CLJS.ff.g(b,c)):c},a)};$CLJS.nZ=function(a){var b=new $CLJS.Fa;for(a=$CLJS.z(a);;)if(null!=a)b=b.append($CLJS.p.h($CLJS.B(a))),a=$CLJS.C(a);else return b.toString()};
$CLJS.pZ=function(a,b,c){a=$CLJS.aE(a,/''/,"'");var d=$CLJS.tv(a,oZ);d=$CLJS.F.g($CLJS.E(d),1)?new $CLJS.P(null,2,5,$CLJS.Q,[a,""],null):d;a=$CLJS.rh(/.*\{0\}.*/,a);return $CLJS.Fu.ngettext($CLJS.Fu.msgid($CLJS.bl(d),$CLJS.n(a)?c:""),$CLJS.aE($CLJS.aE(b,/''/,"'"),oZ,$CLJS.p.h(c)),c)};$CLJS.qZ=function(a,b){return $CLJS.bb(function(c,d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.Id(a,e)?$CLJS.R.j(c,d,$CLJS.J.g(a,e)):c},$CLJS.O.j($CLJS.Ak,a,$CLJS.Xg(b)),b)};
$CLJS.rZ=function(a,b,c){return $CLJS.yd(c)?$CLJS.gf(function(d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);e=$CLJS.ae.g(b,e);return a.g?a.g(e,d):a.call(null,e,d)},$CLJS.G([c])):$CLJS.xd(c)?$CLJS.gf($CLJS.Qe(a,$CLJS.B(c)instanceof $CLJS.M?$CLJS.ae.g(b,$CLJS.B(c)):b),$CLJS.G([c])):null};
$CLJS.sZ=function(a,b,c){return $CLJS.yd(c)?$CLJS.eg.g(c,function(){return function f(e){return new $CLJS.me(null,function(){for(;;){var h=$CLJS.z(e);if(h){if($CLJS.Bd(h)){var l=$CLJS.kc(h),m=$CLJS.E(l),t=$CLJS.qe(m);return function(){for(var A=0;;)if(A<m){var D=$CLJS.md(l,A),H=$CLJS.I(D,0,null),K=$CLJS.I(D,1,null);D=t;var U=$CLJS.Q,Z=H;H=$CLJS.ae.g(b,H);H=a.g?a.g(H,K):a.call(null,H,K);D.add(new $CLJS.P(null,2,5,U,[Z,H],null));A+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.lc(h))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.B(h),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var A=$CLJS.ae.g(b,v),D=x;return a.g?a.g(A,D):a.call(null,A,D)}()],null),f($CLJS.Lc(h)))}return null}},null,null)}(c)}()):$CLJS.xd(c)?$CLJS.Jk.g($CLJS.Qe(a,$CLJS.B(c)instanceof $CLJS.M?$CLJS.ae.g(b,$CLJS.B(c)):b),c):c};
gZ=function gZ(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Cf(d);return $CLJS.de(f,new $CLJS.me(null,function(){var h=e.length;h=d.index+(1>h?1:h);return h<=b.length?(h=b.substring(h),gZ.g?gZ.g(a,h):gZ.call(null,a,h)):null},null,null))};
$CLJS.tZ=function tZ(a){switch(arguments.length){case 3:return tZ.j(arguments[0],arguments[1],arguments[2]);case 4:return tZ.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return tZ.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return tZ.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return tZ.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.y(c.slice(6),0,null))}};$CLJS.tZ.j=function(a,b,c){var d=$CLJS.z(b);b=$CLJS.B(d);if(d=$CLJS.C(d))a=$CLJS.R.j(a,b,$CLJS.tZ.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.tZ.v=function(a,b,c,d){var e=$CLJS.z(b);b=$CLJS.B(e);if(e=$CLJS.C(e))a=$CLJS.R.j(a,b,$CLJS.tZ.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.tZ.N=function(a,b,c,d,e){var f=$CLJS.z(b);b=$CLJS.B(f);if(f=$CLJS.C(f))a=$CLJS.R.j(a,b,$CLJS.tZ.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var h=$CLJS.J.g(a,b);c=c.j?c.j(h,d,e):c.call(null,h,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.tZ.W=function(a,b,c,d,e,f){var h=$CLJS.z(b);b=$CLJS.B(h);if(h=$CLJS.C(h))a=$CLJS.R.j(a,b,$CLJS.tZ.W($CLJS.J.g(a,b),h,c,d,e,f));else{h=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=h.call($CLJS.R,a,b,c)}return a};$CLJS.tZ.l=function(a,b,c,d,e,f,h){var l=$CLJS.z(b);b=$CLJS.B(l);return(l=$CLJS.C(l))?$CLJS.R.j(a,b,$CLJS.O.l($CLJS.tZ,$CLJS.J.g(a,b),l,c,d,$CLJS.G([e,f,h]))):$CLJS.R.j(a,b,$CLJS.O.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.G([h])))};
$CLJS.tZ.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);var e=$CLJS.C(d);d=$CLJS.B(e);var f=$CLJS.C(e);e=$CLJS.B(f);var h=$CLJS.C(f);f=$CLJS.B(h);h=$CLJS.C(h);return this.l(b,a,c,d,e,f,h)};$CLJS.tZ.A=6;lZ=function lZ(a,b){return $CLJS.kZ($CLJS.Qe(lZ,a),a,b)};oZ=/\{0\}/;
$CLJS.uZ=function uZ(a){switch(arguments.length){case 3:return uZ.j(arguments[0],arguments[1],arguments[2]);case 4:return uZ.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return uZ.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return uZ.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return uZ.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.y(c.slice(6),0,null))}};$CLJS.uZ.j=function(a,b,c){var d=$CLJS.Wl(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Bb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.uZ.v=function(a,b,c,d){var e=$CLJS.Wl(a,b);if($CLJS.n(e)){var f=$CLJS.R.j;e=$CLJS.Bb(e);c=c.g?c.g(e,d):c.call(null,e,d);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.uZ.N=function(a,b,c,d,e){var f=$CLJS.Wl(a,b);if($CLJS.n(f)){var h=$CLJS.R.j;f=$CLJS.Bb(f);c=c.j?c.j(f,d,e):c.call(null,f,d,e);a=h.call($CLJS.R,a,b,c)}return a};$CLJS.uZ.W=function(a,b,c,d,e,f){var h=$CLJS.Wl(a,b);if($CLJS.n(h)){var l=$CLJS.R.j;h=$CLJS.Bb(h);c=c.v?c.v(h,d,e,f):c.call(null,h,d,e,f);a=l.call($CLJS.R,a,b,c)}return a};$CLJS.uZ.l=function(a,b,c,d,e,f,h){var l=$CLJS.Wl(a,b);return $CLJS.n(l)?$CLJS.R.j(a,b,$CLJS.O.l(c,$CLJS.Bb(l),d,e,f,$CLJS.G([h]))):a};
$CLJS.uZ.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);var e=$CLJS.C(d);d=$CLJS.B(e);var f=$CLJS.C(e);e=$CLJS.B(f);var h=$CLJS.C(f);f=$CLJS.B(h);h=$CLJS.C(h);return this.l(b,a,c,d,e,f,h)};$CLJS.uZ.A=6;$CLJS.vZ=new $CLJS.M(null,"unique-alias-fn","unique-alias-fn",32555420);$CLJS.wZ=new $CLJS.M(null,"field-id","field-id",-353751335);$CLJS.xZ=new $CLJS.M("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);
$CLJS.yZ=new $CLJS.M(null,"name-key-fn","name-key-fn",-1634839199);var Zea=$CLJS.Ig([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CN,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fN,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sN,$CLJS.lN],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sN,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zN,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rN,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vN,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZM,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wN,null],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.sN,$CLJS.cN],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sN,$CLJS.qN],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eN,null],null)],[$CLJS.Ei,$CLJS.rL,$CLJS.DN,$CLJS.iN,$CLJS.Ai,$CLJS.Fj,$CLJS.hj,$CLJS.ik,$CLJS.Ji,$CLJS.iN,$CLJS.EN,$CLJS.oL]),AZ;$CLJS.ih($CLJS.ff.g($CLJS.B,$CLJS.Xg(Zea)));var zZ,$ea=$CLJS.Te($CLJS.N),afa=$CLJS.Te($CLJS.N),bfa=$CLJS.Te($CLJS.N),cfa=$CLJS.Te($CLJS.N),dfa=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));
zZ=new $CLJS.hi($CLJS.Ih.g("metabase.mbql.util","negate*"),$CLJS.B,dfa,$ea,afa,bfa,cfa);zZ.m(null,$CLJS.nt,function(a){$CLJS.I(a,0,null);return $CLJS.I(a,1,null)});zZ.m(null,$CLJS.st,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.lt],null),$CLJS.ff.g(zZ,a))});zZ.m(null,$CLJS.lt,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.st],null),$CLJS.ff.g(zZ,a))});
zZ.m(null,$CLJS.Ls,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EK,b,a],null)});zZ.m(null,$CLJS.EK,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ls,b,a],null)});zZ.m(null,$CLJS.Es,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,b,a],null)});
zZ.m(null,$CLJS.Is,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,b,a],null)});zZ.m(null,$CLJS.Gs,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Is,b,a],null)});zZ.m(null,$CLJS.Ks,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,b,a],null)});
zZ.m(null,$CLJS.QJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Is,b,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,b,a],null)],null)});zZ.m(null,$CLJS.kK,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,a],null)});zZ.m(null,$CLJS.cK,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,a],null)});
zZ.m(null,$CLJS.wK,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,a],null)});
AZ=function AZ(a){return $CLJS.yd(a)?$CLJS.He($CLJS.eg.g($CLJS.N,function(){return function e(d){return new $CLJS.me(null,function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.Bd(f)){var h=$CLJS.kc(f),l=$CLJS.E(h),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.md(h,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=AZ.h?AZ.h(u):AZ.call(null,u);null!=u&&m.add(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null));t+=1}else{h=!0;break a}return h?$CLJS.te($CLJS.ve(m),e($CLJS.lc(f))):$CLJS.te($CLJS.ve(m),null)}h=$CLJS.B(f);
m=$CLJS.I(h,0,null);h=$CLJS.I(h,1,null);h=AZ.h?AZ.h(h):AZ.call(null,h);if(null!=h)return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[m,h],null),e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(a)}())):$CLJS.xd(a)?$CLJS.He($CLJS.eg.g($CLJS.ld(a),$CLJS.jf($CLJS.Ua,$CLJS.ff.g(AZ,a)))):a};
$CLJS.BZ=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var h=Array(arguments.length-2);f<h.length;)h[f]=arguments[f+2],++f;f=new $CLJS.y(h,0,null)}return b.call(this,c,d,f)}function b(c,d,e){var f=$CLJS.I(c,0,null),h=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);d=$CLJS.He(AZ($CLJS.O.j(d,c,e)));return $CLJS.n(d)?new $CLJS.P(null,3,5,$CLJS.Q,[f,h,d],null):$CLJS.F.g(f,$CLJS.lK)?new $CLJS.P(null,3,5,$CLJS.Q,[f,h,null],null):new $CLJS.P(null,2,5,$CLJS.Q,[f,h],null)}a.A=2;a.B=function(c){var d=
$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.Lc(c);return b(d,e,c)};a.l=b;return a}();