var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./no.en.core.js");
'use strict';var f2,Uga,g2,k2,m2;f2=function(a,b,c,d,e){this.pattern=a;this.zc=b;this.S=c;this.G=d;this.J=e;this.C=2230716170;this.I=139264};Uga=function(a,b){return new f2(a,b,null,null,null)};g2=function(a,b){$CLJS.Id($CLJS.ih($CLJS.q(a)),b)||$CLJS.Rh.j(a,$CLJS.ae,b)};$CLJS.h2=function(a){if(null==a||"string"===typeof a)return a;if(a instanceof $CLJS.M||a instanceof $CLJS.w){var b=$CLJS.he(a);return $CLJS.n(b)?[b,"/",$CLJS.jh(a)].join(""):$CLJS.jh(a)}return null};
k2=function(a){a=$CLJS.z($CLJS.O.g(i2,a));for(var b=null,c=0,d=0;;)if(d<c){var e=b.X(null,d);g2($CLJS.j2,e);d+=1}else if(a=$CLJS.z(a))b=a,$CLJS.Bd(b)?(a=$CLJS.kc(b),c=$CLJS.lc(b),b=a,e=$CLJS.E(a),a=c,c=e):(e=$CLJS.B(b),g2($CLJS.j2,e),a=$CLJS.C(b),b=null,c=0),d=0;else break};
m2=function(a){a=$CLJS.z($CLJS.O.g(i2,a));for(var b=null,c=0,d=0;;)if(d<c){var e=b.X(null,d);g2(l2,e);d+=1}else if(a=$CLJS.z(a))b=a,$CLJS.Bd(b)?(a=$CLJS.kc(b),c=$CLJS.lc(b),b=a,e=$CLJS.E(a),a=c,c=e):(e=$CLJS.B(b),g2(l2,e),a=$CLJS.C(b),b=null,c=0),d=0;else break};var l2,o2;$CLJS.g=f2.prototype;$CLJS.g.pa=function(a,b){return this.ca(null,b,null)};$CLJS.g.ca=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "pattern":return this.pattern;case "replacement":return this.zc;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Cb=function(a,b,c){return $CLJS.bb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.vh(b,function(d){return $CLJS.vh(b,$CLJS.Bh,""," ","",c,d)},"#inflections.core.Rule{",", ","}",c,$CLJS.cf.g(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pu,this.pattern],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uu,this.zc],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.Ku(this,2,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pu,$CLJS.Uu],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Je())};$CLJS.g.O=function(){return this.S};
$CLJS.g.ha=function(){return 2+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1327743444^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.pattern,b.pattern)&&$CLJS.F.g(this.zc,b.zc)&&$CLJS.F.g(this.G,b.G)};
$CLJS.g.Mb=function(a,b){return $CLJS.Id(new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.Uu,null,$CLJS.Pu,null],null),null),b)?$CLJS.Ak.g($CLJS.Lb($CLJS.eg.g($CLJS.N,this),this.S),b):new f2(this.pattern,this.zc,this.S,$CLJS.He($CLJS.Ak.g(this.G,b)),null)};$CLJS.g.kb=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "pattern":case "replacement":return!0;default:return $CLJS.Id(this.G,b)}};
$CLJS.g.na=function(a,b,c){return $CLJS.n($CLJS.ge.g?$CLJS.ge.g($CLJS.Pu,b):$CLJS.ge.call(null,$CLJS.Pu,b))?new f2(c,this.zc,this.S,this.G,null):$CLJS.n($CLJS.ge.g?$CLJS.ge.g($CLJS.Uu,b):$CLJS.ge.call(null,$CLJS.Uu,b))?new f2(this.pattern,c,this.S,this.G,null):new f2(this.pattern,this.zc,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.z($CLJS.cf.g(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.Yf($CLJS.Pu,this.pattern),new $CLJS.Yf($CLJS.Uu,this.zc)],null),this.G))};
$CLJS.g.P=function(a,b){return new f2(this.pattern,this.zc,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.Ad(b)?this.na(null,$CLJS.md(b,0),$CLJS.md(b,1)):$CLJS.bb($CLJS.lb,this,b)};var i2=function i2(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return i2.l(0<c.length?new $CLJS.y(c.slice(0),0,null):null)};i2.l=function(a){return $CLJS.ff.g(function(b){return $CLJS.O.g(Uga,b)},$CLJS.ju(2,2,a))};i2.A=0;i2.B=function(a){return this.l($CLJS.z(a))};
$CLJS.n2=$CLJS.Te(new $CLJS.dh(null,new $CLJS.k(null,81,["gossip",null,"sadness",null,"space",null,"happiness",null,"blood",null,"silver",null,"cotton",null,"species",null,"mist",null,"paper",null,"education",null,"wine",null,"up",null,"food",null,"sugar",null,"gold",null,"literature",null,"pork",null,"lightning",null,"sheep",null,"shopping",null,"fresh",null,"news",null,"pepper",null,"experience",null,"milk",null,"honey",null,"oxygen",null,"fiction",null,"luck",null,"vinegar",null,"coffee",null,
"ground",null,"peanut",null,"confusion",null,"tennis",null,"power",null,"meat",null,"butter",null,"music",null,"luggage",null,"love",null,"grass",null,"knowledge",null,"time",null,"cheese",null,"jam",null,"thunder",null,"electricity",null,"gum",null,"sunshine",null,"history",null,"snow",null,"money",null,"wool",null,"rice",null,"series",null,"liquid",null,"equipment",null,"pressure",null,"oil",null,"information",null,"steam",null,"chewing",null,"petrol",null,"research",null,"patience",null,"toothpaste",
null,"speed",null,"entertainment",null,"wood",null,"tea",null,"art",null,"washing",null,"forgiveness",null,"traffic",null,"alcohol",null,"ice",null,"homework",null,"fish",null,"air",null],null),null));$CLJS.j2=$CLJS.Te($CLJS.Bf);l2=$CLJS.Te($CLJS.Bf);o2=$CLJS.Te(function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.bb($CLJS.lb,$CLJS.$k,0<b.length?new $CLJS.y(b.slice(0),0,null):null)}());
k2($CLJS.G([/$/i,"s",/s$/i,"s",/(ax|test)is$/i,"$1es",/(octop|vir)us$/i,"$1i",/(alias|status)$/i,"$1es",/(bu)s$/i,"$1ses",/(buffal|tomat)o$/i,"$1oes",/([ti])um$/i,"$1a",/sis$/i,"ses",/(?:([^f])fe|([lr])f)$/i,"$1$2ves",/(hive)$/i,"$1s",/([^aeiouy]|qu)y$/i,"$1ies",/(x|ch|ss|sh)$/i,"$1es",/(matr|vert|ind)(?:ix|ex)$/i,"$1ices",/([m|l])ouse$/i,"$1ice",/^(ox)$/i,"$1en",/(quiz)$/i,"$1zes"]));
m2($CLJS.G([/s$/i,"",/(ss)$/i,"$1",/(n)ews$/i,"$1ews",/([ti])a$/i,"$1um",/((a)naly|(b)a|(d)iagno|(p)arenthe|(p)rogno|(s)ynop|(t)he)(sis|ses)$/i,"$1$2sis",/(^analy)(sis|ses)$/i,"$1sis",/([^f])ves$/i,"$1fe",/(hive)s$/i,"$1",/(tive)s$/i,"$1",/([lr])ves$/i,"$1f",/([^aeiouy]|qu)ies$/i,"$1y",/(s)eries$/i,"$1eries",/(m)ovies$/i,"$1ovie",/(x|ch|ss|sh)es$/i,"$1",/([m|l])ice$/i,"$1ouse",/(bus)(es)?$/i,"$1",/(o)es$/i,"$1",/(shoe)s$/i,"$1",/(cris|ax|test)(is|es)$/i,"$1is",/(octop|vir)(us|i)$/i,"$1us",/(alias|status)(es)?$/i,
"$1",/^(ox)en/i,"$1",/(vert|ind)ices$/i,"$1ex",/(matr)ices$/i,"$1ix",/(quiz)zes$/i,"$1",/(database)s$/i,"$1"]));
$CLJS.ph($CLJS.ff.g(function(a){var b=$CLJS.B(a);a=$CLJS.jd(a);b=$CLJS.h2(b).toLowerCase();a=$CLJS.h2(a).toLowerCase();$CLJS.Rh.j($CLJS.n2,$CLJS.Bk,$CLJS.h2(b).toLowerCase());$CLJS.Rh.j($CLJS.n2,$CLJS.Bk,$CLJS.h2(a).toLowerCase());m2($CLJS.G([$CLJS.sh(["^",a,"$"].join("")),b]));k2($CLJS.G([$CLJS.sh(["^",b,"$"].join("")),a]));$CLJS.Rh.j(o2,$CLJS.ae,b);return $CLJS.Rh.j(o2,$CLJS.ae,a)},new $CLJS.P(null,14,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,["amenity","amenities"],null),new $CLJS.P(null,2,5,$CLJS.Q,
["child","children"],null),new $CLJS.P(null,2,5,$CLJS.Q,["cow","kine"],null),new $CLJS.P(null,2,5,$CLJS.Q,["foot","feet"],null),new $CLJS.P(null,2,5,$CLJS.Q,["louse","lice"],null),new $CLJS.P(null,2,5,$CLJS.Q,["mailman","mailmen"],null),new $CLJS.P(null,2,5,$CLJS.Q,["man","men"],null),new $CLJS.P(null,2,5,$CLJS.Q,["mouse","mice"],null),new $CLJS.P(null,2,5,$CLJS.Q,["move","moves"],null),new $CLJS.P(null,2,5,$CLJS.Q,["ox","oxen"],null),new $CLJS.P(null,2,5,$CLJS.Q,["person","people"],null),new $CLJS.P(null,
2,5,$CLJS.Q,["sex","sexes"],null),new $CLJS.P(null,2,5,$CLJS.Q,["tooth","teeth"],null),new $CLJS.P(null,2,5,$CLJS.Q,["woman","women"],null)],null)));