var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var M4=function(a,b,c){var d=$CLJS.z0(a,b);d=$CLJS.Ge(d);d=$CLJS.J.g(d,$CLJS.aK);d=$CLJS.NL($CLJS.Hk.j($CLJS.fh([c]),$CLJS.BI,$CLJS.jd),d);if(!$CLJS.n(d))throw $CLJS.li($CLJS.KI("No aggregation with uuid {0}",$CLJS.G([c])),new $CLJS.k(null,3,[$CLJS.Bt,c,$CLJS.rQ,a,$CLJS.s1,b],null));return d},N4=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592),O4=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",
-21314018),P4=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815),Fia=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.D1.m(null,$CLJS.aK,function(a,b){var c=$CLJS.He($CLJS.aK.h($CLJS.z0(a,b)));return $CLJS.n(c)?$CLJS.g3($CLJS.LI("and"),function(){return function f(e){return new $CLJS.me(null,function(){for(;;){var h=$CLJS.z(e);if(h){if($CLJS.Bd(h)){var l=$CLJS.kc(h),m=$CLJS.E(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.md(l,u);v=$CLJS.e1.v(a,b,v,$CLJS.f1);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.lc(h))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.B(h);return $CLJS.de($CLJS.e1.v(a,
b,t,$CLJS.f1),f($CLJS.Lc(h)))}return null}},null,null)}(c)}()):null});$CLJS.H1.m(null,$CLJS.aK,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.Ge(d);d=$CLJS.J.g(e,$CLJS.CE);e=$CLJS.J.g(e,$CLJS.Ii);c=$CLJS.I(c,2,null);c=M4(a,b,c);return $CLJS.Zk.l($CLJS.G([$CLJS.d1.j(a,b,c),new $CLJS.k(null,2,[$CLJS.B_,$CLJS.I_,$CLJS.h3,$CLJS.BI.h($CLJS.jd(c))],null),$CLJS.n(d)?new $CLJS.k(null,1,[$CLJS.CE,d],null):null,$CLJS.n(e)?new $CLJS.k(null,1,[$CLJS.Ii,e],null):null]))});
$CLJS.A1.m(null,$CLJS.aK,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.e1.v(a,b,M4(a,b,c),d)});$CLJS.xJ(O4,P4);
for(var Q4=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TA,$CLJS.mK],null)),R4=null,S4=0,T4=0;;)if(T4<S4){var Gia=R4.X(null,T4);$CLJS.xJ(Gia,O4);T4+=1}else{var U4=$CLJS.z(Q4);if(U4){var V4=U4;if($CLJS.Bd(V4)){var W4=$CLJS.kc(V4),Hia=$CLJS.lc(V4),Iia=W4,Jia=$CLJS.E(W4);Q4=Hia;R4=Iia;S4=Jia}else{var Kia=$CLJS.B(V4);$CLJS.xJ(Kia,O4);Q4=$CLJS.C(V4);R4=null;S4=0}T4=0}else break}
$CLJS.A1.m(null,O4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.e1.v(a,b,c,d),e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.KI("Count of {0}",$CLJS.G([a]));case "cum-count":return $CLJS.KI("Cumulative count of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.LI("Count");case "cum-count":return $CLJS.LI("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.B1.m(null,O4,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.H1.m(null,O4,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.ei($CLJS.H1,P4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.Tj,$CLJS.zG)});$CLJS.A1.m(null,$CLJS.WJ,function(){return $CLJS.LI("Case")});
$CLJS.B1.m(null,$CLJS.WJ,function(){return"case"});$CLJS.xJ(N4,P4);for(var X4=$CLJS.z(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.fK,$CLJS.IK,$CLJS.SJ,$CLJS.gk,$CLJS.zK,$CLJS.On,$CLJS.CK,$CLJS.yK,$CLJS.TJ],null)),Y4=null,Z4=0,$4=0;;)if($4<Z4){var Lia=Y4.X(null,$4);$CLJS.xJ(Lia,N4);$4+=1}else{var a5=$CLJS.z(X4);if(a5){var b5=a5;if($CLJS.Bd(b5)){var c5=$CLJS.kc(b5),Mia=$CLJS.lc(b5),Nia=c5,Oia=$CLJS.E(c5);X4=Mia;Y4=Nia;Z4=Oia}else{var Pia=$CLJS.B(b5);$CLJS.xJ(Pia,N4);X4=$CLJS.C(b5);Y4=null;Z4=0}$4=0}else break}
$CLJS.B1.m(null,N4,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.A1.m(null,N4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.e1.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "avg":return $CLJS.KI("Average of {0}",$CLJS.G([a]));case "cum-sum":return $CLJS.KI("Cumulative sum of {0}",$CLJS.G([a]));case "distinct":return $CLJS.KI("Distinct values of {0}",$CLJS.G([a]));case "max":return $CLJS.KI("Max of {0}",$CLJS.G([a]));case "median":return $CLJS.KI("Median of {0}",$CLJS.G([a]));case "min":return $CLJS.KI("Min of {0}",
$CLJS.G([a]));case "stddev":return $CLJS.KI("Standard deviation of {0}",$CLJS.G([a]));case "sum":return $CLJS.KI("Sum of {0}",$CLJS.G([a]));case "var":return $CLJS.KI("Variance of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.A1.m(null,$CLJS.YJ,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.KI("{0}th percentile of {1}",$CLJS.G([c,$CLJS.e1.v(a,b,e,d)]))});$CLJS.B1.m(null,$CLJS.YJ,function(){return"percentile"});
$CLJS.xJ($CLJS.YJ,P4);$CLJS.A1.m(null,$CLJS.GK,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.KI("Sum of {0} matching condition",$CLJS.G([$CLJS.e1.v(a,b,e,d)]))});$CLJS.B1.m(null,$CLJS.GK,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.C1.j(a,b,d))].join("")});$CLJS.xJ($CLJS.GK,P4);$CLJS.A1.m(null,$CLJS.sK,function(){return $CLJS.LI("Share of rows matching condition")});
$CLJS.B1.m(null,$CLJS.sK,function(){return"share"});$CLJS.xJ($CLJS.sK,P4);$CLJS.A1.m(null,$CLJS.uK,function(){return $CLJS.LI("Count of rows matching condition")});$CLJS.B1.m(null,$CLJS.uK,function(){return"count-where"});$CLJS.xJ($CLJS.uK,P4);
$CLJS.H1.m(null,P4,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.Zk.l;d=$CLJS.n(d)?$CLJS.bh($CLJS.d1.j(a,b,d),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.xU],null)):null;var f=$CLJS.ei($CLJS.H1,$CLJS.gi);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.Zk,$CLJS.G([d,a]))});$CLJS.c_.m(null,$CLJS.aK,function(a){return a});
$CLJS.Qia=function(){function a(d,e,f){for(;;)if($CLJS.F.g($CLJS.BF(f),$CLJS.j_))f=$CLJS.h_(f);else return $CLJS.r2(d,e,$CLJS.aK,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Ria=function(){function a(d,e){return $CLJS.He($CLJS.aK.h($CLJS.z0(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.d5=function(){function a(d,e){var f=$CLJS.He($CLJS.aK.h($CLJS.z0(d,e)));return null==f?null:$CLJS.eg.j($CLJS.Bf,$CLJS.ff.h(function(h){var l=$CLJS.d1.j(d,e,h),m=$CLJS.R.l,t=$CLJS.CE.h(l);return m.call($CLJS.R,$CLJS.f3(l,$CLJS.Ii,$CLJS.n(t)?t:$CLJS.zj),$CLJS.B_,$CLJS.I_,$CLJS.G([$CLJS.h3,$CLJS.BI.h($CLJS.jd(h))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.A1.m(null,$CLJS.jL,function(a,b,c){a=$CLJS.Ge(c);a=$CLJS.J.g(a,$CLJS.VK);return $CLJS.qI.h(a.o?a.o():a.call(null))});$CLJS.I1.m(null,$CLJS.jL,function(a,b,c){var d=$CLJS.Ge(c);a=$CLJS.J.g(d,$CLJS.eK);b=$CLJS.J.g(d,$CLJS.VK);c=$CLJS.J.g(d,$CLJS.SK);d=$CLJS.J.g(d,$CLJS.P0);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.y1,$CLJS.jE(a),$CLJS.G([$CLJS.x1,c]));return null!=d?$CLJS.R.j(a,$CLJS.m1,d):a});
$CLJS.Sia=function(){function a(d,e){var f=function(){var m=$CLJS.Zu.h($CLJS.Z0($CLJS.D0(d)));return $CLJS.n(m)?m:$CLJS.eh}(),h=$CLJS.z0(d,e),l=$CLJS.M1.j(d,e,h);return $CLJS.He($CLJS.eg.j($CLJS.Bf,$CLJS.Hk.j($CLJS.hf(function(m){m=$CLJS.gL.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.Ol(function(m){m=$CLJS.Ge(m);var t=$CLJS.J.g(m,$CLJS.SK),u=$CLJS.J.g(m,$CLJS.NK);if($CLJS.Ta(t))return m;if($CLJS.F.g(u,$CLJS.Dt))return $CLJS.R.j(m,$CLJS.v_,l);t=$CLJS.He($CLJS.iZ(function(v){return $CLJS.T2(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.v_,t):null}),$CLJS.ff.h(function(m){return $CLJS.R.j(m,$CLJS.Dj,$CLJS.jL)})),$CLJS.kL))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Tia=function(){function a(d,e){return $CLJS.g_(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eK.h(d),$CLJS.N,$CLJS.k_.h(e)],null))}function b(d){if($CLJS.Ta($CLJS.SK.h(d)))return $CLJS.g_(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eK.h(d),$CLJS.N],null));var e=$CLJS.eK.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.li(e,new $CLJS.k(null,1,[Fia,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();