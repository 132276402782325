var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var sJ,BJ,yJ,AK;sJ=function(a){if(null!=a&&null!=a.ia)a=a.cache;else{var b=sJ[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=sJ._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.Za("Cached.-cache",a);}return a};
$CLJS.tJ=function(a,b,c){if(null!=a&&$CLJS.r===a.Ta){var d=sJ(a);var e=$CLJS.q(d);e=e.h?e.h(b):e.call(null,b);if($CLJS.n(e))return e;a=$CLJS.Rh.v(d,$CLJS.R,b,c.h?c.h(a):c.call(null,a));return a.h?a.h(b):a.call(null,b)}return c.h?c.h(a):c.call(null,a)};$CLJS.vJ=function(a,b,c){a=$CLJS.uJ.g(a,c);return a.h?a.h(b):a.call(null,b)};$CLJS.wJ=function(a,b){return $CLJS.vJ(a,b,null)};$CLJS.xJ=function(a,b){$CLJS.Rh.v($CLJS.DF,$CLJS.pE,a,b)};$CLJS.zJ=function(a,b){a=yJ(a,b);return $CLJS.n(a)?a:$CLJS.zj};
BJ=function(){return $CLJS.eg.j(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Qi,$CLJS.B,$CLJS.Cu,function(a){a=$CLJS.Ge(a);a=$CLJS.J.g(a,$CLJS.Cj);return $CLJS.Ad(a)?["Invalid ",$CLJS.Ph.l($CLJS.G([$CLJS.B(a)]))," clause: ",$CLJS.Ph.l($CLJS.G([a]))].join(""):"not an MBQL clause"}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Po,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"not a known MBQL clause"],null),$CLJS.Pe(!1)],null)],null)],null),$CLJS.ff.h(function(a){return new $CLJS.P(null,
2,5,$CLJS.Q,[a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.Ch.g("mbql.clause",$CLJS.jh(a))],null)],null)}),$CLJS.q(AJ))};$CLJS.EJ=function(a,b){$CLJS.O.v(CJ,DJ,a,b)};$CLJS.GJ=function(a,b){$CLJS.O.v(CJ,FJ,a,b)};$CLJS.op.prototype.ia=$CLJS.va(26,function(){return this.cache});$CLJS.qp.prototype.ia=$CLJS.va(25,function(){return this.cache});$CLJS.sp.prototype.ia=$CLJS.va(24,function(){return this.cache});$CLJS.up.prototype.ia=$CLJS.va(23,function(){return this.cache});
$CLJS.wp.prototype.ia=$CLJS.va(22,function(){return this.cache});$CLJS.yp.prototype.ia=$CLJS.va(21,function(){return this.cache});$CLJS.Bp.prototype.ia=$CLJS.va(20,function(){return this.cache});$CLJS.Dp.prototype.ia=$CLJS.va(19,function(){return this.cache});$CLJS.Fp.prototype.ia=$CLJS.va(18,function(){return this.cache});$CLJS.Hp.prototype.ia=$CLJS.va(17,function(){return this.cache});$CLJS.Jp.prototype.ia=$CLJS.va(16,function(){return this.cache});$CLJS.Lp.prototype.ia=$CLJS.va(15,function(){return this.cache});
$CLJS.Np.prototype.ia=$CLJS.va(14,function(){return this.cache});$CLJS.Pp.prototype.ia=$CLJS.va(13,function(){return this.cache});$CLJS.Rp.prototype.ia=$CLJS.va(12,function(){return this.cache});$CLJS.Tp.prototype.ia=$CLJS.va(11,function(){return this.cache});$CLJS.Wp.prototype.ia=$CLJS.va(10,function(){return this.cache});$CLJS.aq.prototype.ia=$CLJS.va(9,function(){return this.cache});$CLJS.cq.prototype.ia=$CLJS.va(8,function(){return this.cache});$CLJS.fq.prototype.ia=$CLJS.va(7,function(){return this.cache});
$CLJS.pq.prototype.ia=$CLJS.va(6,function(){return this.cache});$CLJS.dI.prototype.ia=$CLJS.va(5,function(){return this.cache});$CLJS.uJ=function uJ(a){switch(arguments.length){case 1:return uJ.h(arguments[0]);case 2:return uJ.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.uJ.h=function(a){return $CLJS.uJ.g(a,null)};$CLJS.uJ.g=function(a,b){return $CLJS.tJ($CLJS.no.g(a,b),$CLJS.qk,$CLJS.ln)};$CLJS.uJ.A=2;
yJ=function yJ(a,b){return $CLJS.F.g(a,$CLJS.zj)?null:$CLJS.F.g(b,$CLJS.zj)?null:$CLJS.QF(a,b)?b:$CLJS.QF(b,a)?a:$CLJS.Ne(function(d){return $CLJS.Ne(function(e){return $CLJS.F.g(new $CLJS.P(null,2,5,$CLJS.Q,[d,e],null),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null))?null:yJ.g?yJ.g(d,e):yJ.call(null,d,e)},$CLJS.de(b,$CLJS.ai(b)))},$CLJS.de(a,$CLJS.ai(a)))};$CLJS.HJ=new $CLJS.M(null,"exp","exp",-261706262);$CLJS.IJ=new $CLJS.M(null,"substring","substring",1140866276);
$CLJS.JJ=new $CLJS.M(null,"basic-aggregations","basic-aggregations",-1628959228);$CLJS.KJ=new $CLJS.M(null,"ceil","ceil",-1824929952);$CLJS.LJ=new $CLJS.M(null,"power","power",-937852079);$CLJS.MJ=new $CLJS.M(null,"segment","segment",-964921196);$CLJS.NJ=new $CLJS.M(null,"concat","concat",-2108183992);$CLJS.OJ=new $CLJS.M(null,"abs","abs",-246026477);$CLJS.PJ=new $CLJS.M(null,"is-null","is-null",-1997050930);$CLJS.QJ=new $CLJS.M(null,"between","between",1131099276);
$CLJS.RJ=new $CLJS.M(null,"is-empty","is-empty",-1040302908);$CLJS.SJ=new $CLJS.M(null,"distinct","distinct",-1788879121);$CLJS.TJ=new $CLJS.M(null,"var","var",-769682797);$CLJS.UJ=new $CLJS.M(null,"next","next",-117701485);$CLJS.VJ=new $CLJS.M(null,"inside","inside",1972503011);$CLJS.WJ=new $CLJS.M(null,"case","case",1143702196);$CLJS.XJ=new $CLJS.M(null,"not-empty","not-empty",388922063);$CLJS.YJ=new $CLJS.M(null,"percentile","percentile",-601188752);
$CLJS.ZJ=new $CLJS.M(null,"not-null","not-null",-1326718535);$CLJS.$J=new $CLJS.M(null,"sqrt","sqrt",-1270051929);$CLJS.aK=new $CLJS.M(null,"aggregation","aggregation",1597476696);$CLJS.bK=new $CLJS.M(null,"desc","desc",2093485764);$CLJS.cK=new $CLJS.M(null,"starts-with","starts-with",366503009);$CLJS.dK=new $CLJS.M(null,"does-not-contain","does-not-contain",-274946853);$CLJS.eK=new $CLJS.M(null,"short","short",1928760516);$CLJS.fK=new $CLJS.M(null,"avg","avg",197406200);
$CLJS.gK=new $CLJS.M(null,"metric","metric",408798077);$CLJS.hK=new $CLJS.M(null,"percentile-aggregations","percentile-aggregations",1241763983);$CLJS.iK=new $CLJS.M(null,"asc","asc",356854569);$CLJS.jK=new $CLJS.M(null,"floor","floor",1882041021);$CLJS.kK=new $CLJS.M(null,"contains","contains",676899812);$CLJS.lK=new $CLJS.M(null,"field","field",-1302436500);$CLJS.mK=new $CLJS.M(null,"cum-count","cum-count",-1964431543);$CLJS.nK=new $CLJS.M(null,"regex-match-first","regex-match-first",-657299718);
$CLJS.oK=new $CLJS.M(null,"coalesce","coalesce",654622029);$CLJS.pK=new $CLJS.M("metabase.lib.schema.ref","ref","metabase.lib.schema.ref/ref",-1124116498);$CLJS.qK=new $CLJS.M(null,"round","round",2009433328);$CLJS.rK=new $CLJS.M(null,"temporal-unit","temporal-unit",-1892306358);$CLJS.sK=new $CLJS.M(null,"share","share",-589433933);$CLJS.tK=new $CLJS.M(null,"case-sensitive","case-sensitive",-1005239404);$CLJS.uK=new $CLJS.M(null,"count-where","count-where",385407720);
$CLJS.vK=new $CLJS.M(null,"standard-deviation-aggregations","standard-deviation-aggregations",-1683333464);$CLJS.wK=new $CLJS.M(null,"ends-with","ends-with",-456791011);$CLJS.xK=new $CLJS.M(null,"time-interval","time-interval",704622015);$CLJS.yK=new $CLJS.M(null,"sum","sum",136986814);$CLJS.zK=new $CLJS.M(null,"median","median",569566131);AK=new $CLJS.M("metabase.lib.schema.mbql-clause","update-schemas","metabase.lib.schema.mbql-clause/update-schemas",-447263953);
$CLJS.BK=new $CLJS.M(null,"include-current","include-current",-1602371981);$CLJS.CK=new $CLJS.M(null,"stddev","stddev",-865474939);$CLJS.DK=new $CLJS.M(null,"trim","trim",774319767);$CLJS.EK=new $CLJS.M(null,"!\x3d","!\x3d",-1841737356);$CLJS.FK=new $CLJS.M(null,"ltrim","ltrim",1654269283);$CLJS.GK=new $CLJS.M(null,"sum-where","sum-where",2135348428);$CLJS.HK=new $CLJS.M(null,"/","/",1282502798);$CLJS.IK=new $CLJS.M(null,"cum-sum","cum-sum",2064902349);$CLJS.JK=new $CLJS.M(null,"log","log",-1595516004);
$CLJS.KK=new $CLJS.M(null,"replace","replace",-786587770);$CLJS.LK=new $CLJS.M(null,"rtrim","rtrim",-661336449);var AJ=$CLJS.Te($CLJS.eh),FJ;$CLJS.X($CLJS.PI,BJ());$CLJS.EF(AJ,AK,function(){return $CLJS.X($CLJS.PI,BJ())});
$CLJS.MK=function(){function a(d,e,f,h){c.g?c.g(d,h):c.call(null,d,h);$CLJS.NI.m(null,d,function(){return f});return null}function b(d,e){var f=$CLJS.Ch.g("mbql.clause",$CLJS.jh(d));$CLJS.X(f,e);$CLJS.Id($CLJS.q(AJ),d)||$CLJS.Rh.j(AJ,$CLJS.ae,d);return null}var c=null;c=function(d,e,f,h){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,h)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
FJ=function FJ(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return FJ.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
FJ.l=function(a,b){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,$CLJS.eg.g(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.at,new $CLJS.k(null,1,[$CLJS.Au,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,a],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AI],null)],null)],null)],null),b)],null)};FJ.A=1;
FJ.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};var DJ=function DJ(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return DJ.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
DJ.l=function(a,b){return $CLJS.eg.g(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ot,new $CLJS.k(null,1,[$CLJS.Au,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AI],null)],null),b)};DJ.A=1;DJ.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};
var CJ=function CJ(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return CJ.l(arguments[0],arguments[1],2<c.length?new $CLJS.y(c.slice(2),0,null):null)};CJ.l=function(a,b,c){c=$CLJS.F.g($CLJS.B(c),$CLJS.qu)?$CLJS.de($CLJS.jd(c),$CLJS.Ye(2,c)):$CLJS.de(null,c);var d=$CLJS.z(c);c=$CLJS.B(d);d=$CLJS.C(d);a=$CLJS.O.j(a,b,d);return $CLJS.n(c)?$CLJS.MK.v(b,$CLJS.qu,c,a):$CLJS.MK.g(b,a)};CJ.A=2;
CJ.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return this.l(b,a,c)};