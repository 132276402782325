var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.breakout.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';var Nla=function(a){a=$CLJS.Ge(a);var b=$CLJS.J.g(a,$CLJS.CE);return $CLJS.Ne(function(c){return $CLJS.QF(b,c)},$CLJS.rJ)},L8=new $CLJS.M("metabase.lib.order-by","order-by-clause","metabase.lib.order-by/order-by-clause",774979647);$CLJS.xJ($CLJS.iK,L8);$CLJS.xJ($CLJS.bK,L8);
$CLJS.D1.m(null,$CLJS.DV,function(a,b){var c=$CLJS.He($CLJS.DV.h($CLJS.z0(a,b)));return $CLJS.n(c)?$CLJS.KI("Sorted by {0}",$CLJS.G([$CLJS.g3($CLJS.LI("and"),function(){return function f(e){return new $CLJS.me(null,function(){for(;;){var h=$CLJS.z(e);if(h){if($CLJS.Bd(h)){var l=$CLJS.kc(h),m=$CLJS.E(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.md(l,u);v=$CLJS.e1.v(a,b,v,$CLJS.f1);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.lc(h))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.B(h);
return $CLJS.de($CLJS.e1.v(a,b,t,$CLJS.f1),f($CLJS.Lc(h)))}return null}},null,null)}(c)}())])):null});$CLJS.A1.m(null,L8,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.e1.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "asc":return $CLJS.KI("{0} ascending",$CLJS.G([a]));case "desc":return $CLJS.KI("{0} descending",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});
$CLJS.I1.m(null,L8,function(a,b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.R.j($CLJS.h1.j(a,b,c),$CLJS.q1,d)});var M8,Ola=$CLJS.Te($CLJS.N),Pla=$CLJS.Te($CLJS.N),Qla=$CLJS.Te($CLJS.N),Rla=$CLJS.Te($CLJS.N),Sla=$CLJS.J.j(new $CLJS.k(null,1,[$CLJS.gj,$CLJS.DF],null),$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));M8=new $CLJS.hi($CLJS.Ih.g("metabase.lib.order-by","order-by-clause-method"),$CLJS.BF,Sla,Ola,Pla,Qla,Rla);M8.m(null,L8,function(a){return $CLJS.g_(a)});
M8.m(null,$CLJS.gi,function(a){if(null==a)throw $CLJS.li($CLJS.LI("Can''t order by nil"),$CLJS.N);return $CLJS.g_(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iK,$CLJS.h_(a)],null))});
$CLJS.Tla=function(){function a(d,e){d=M8.h(d);e=$CLJS.n(e)?e:$CLJS.iK;return $CLJS.R.j($CLJS.Cf(d),0,e)}function b(d){return c.g?c.g(d,$CLJS.iK):c.call(null,d,$CLJS.iK)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Ula=function(){function a(e,f,h,l){f=$CLJS.n(f)?f:-1;var m=function(){var t=M8.h(h);return $CLJS.n(l)?$CLJS.R.j($CLJS.Cf(t),0,l):t}();return $CLJS.J_.l(e,f,$CLJS.Kk,$CLJS.G([$CLJS.DV,function(t){return $CLJS.ae.g($CLJS.Cf(t),m)}]))}function b(e,f,h){return d.v?d.v(e,-1,f,h):d.call(null,e,-1,f,h)}function c(e,f){return d.v?d.v(e,-1,f,null):d.call(null,e,-1,f,null)}var d=null;d=function(e,f,h,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,h);case 4:return a.call(this,
e,f,h,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();$CLJS.N8=function(){function a(d,e){return $CLJS.He($CLJS.J.g($CLJS.z0(d,e),$CLJS.DV))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Vla=function(){function a(d,e){var f=$CLJS.He($CLJS.Q5(d,e)),h=$CLJS.He($CLJS.d5.g(d,e));f=$CLJS.n($CLJS.n(f)?f:h)?$CLJS.cf.g(f,h):function(){var t=$CLJS.z0(d,e);return $CLJS.M1.j(d,e,t)}();f=$CLJS.jf(Nla,f);h=$CLJS.N8.g(d,e);if($CLJS.ud(f))return null;if($CLJS.ud(h))return $CLJS.Cf(f);var l=$CLJS.Jk.g($CLJS.h_,f),m=$CLJS.eg.j($CLJS.N,$CLJS.Xl(function(t,u){$CLJS.I(u,0,null);$CLJS.I(u,1,null);u=$CLJS.I(u,2,null);u=$CLJS.w2(d,$CLJS.h_(u),l);return $CLJS.n(u)?new $CLJS.P(null,2,5,$CLJS.Q,[u,t],
null):null}),h);return $CLJS.Jk.j(function(t,u){u=m.h?m.h(u):m.call(null,u);return $CLJS.n(u)?$CLJS.R.j(t,$CLJS.o1,u):t},f,l)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.O8=new $CLJS.k(null,2,[$CLJS.iK,$CLJS.bK,$CLJS.bK,$CLJS.iK],null);