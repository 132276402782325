var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.types.coercion_hierarchies.js");require("./metabase.util.js");
'use strict';var PF,RF,SF,TF,UF,WF,YF,ZF,$F,aG,bG,cG,dG,eG,fG,VF,gG,hG,iG,jG,kG,lG,mG,oG,rG,sG,uG,vG,xG,yG,AG,CG,DG,EG,GG,HG,IG,JG,KG,LG,MG,NG,OG,QG,RG,TG,UG,VG,WG,XG,ZG,$G,aH,cH,dH,fH,gH,hH,iH,kH,lH,mH,oH,pH,qH,rH,sH,tH,uH,vH,wH,xH,yH,AH,BH,CH,DH,EH,FH,GH,JH,KH,LH,MH,PH,QH,SH,TH,VH;
$CLJS.OF=function(a,b,c){return function(){function d(m,t,u){var v=a.h?a.h(m):a.call(null,m);if($CLJS.n(v))return v;v=a.h?a.h(t):a.call(null,t);if($CLJS.n(v))return v;v=a.h?a.h(u):a.call(null,u);if($CLJS.n(v))return v;v=b.h?b.h(m):b.call(null,m);if($CLJS.n(v))return v;v=b.h?b.h(t):b.call(null,t);if($CLJS.n(v))return v;v=b.h?b.h(u):b.call(null,u);if($CLJS.n(v))return v;m=c.h?c.h(m):c.call(null,m);if($CLJS.n(m))return m;t=c.h?c.h(t):c.call(null,t);return $CLJS.n(t)?t:c.h?c.h(u):c.call(null,u)}function e(m,
t){var u=a.h?a.h(m):a.call(null,m);if($CLJS.n(u))return u;u=a.h?a.h(t):a.call(null,t);if($CLJS.n(u))return u;u=b.h?b.h(m):b.call(null,m);if($CLJS.n(u))return u;u=b.h?b.h(t):b.call(null,t);if($CLJS.n(u))return u;m=c.h?c.h(m):c.call(null,m);return $CLJS.n(m)?m:c.h?c.h(t):c.call(null,t)}function f(m){var t=a.h?a.h(m):a.call(null,m);if($CLJS.n(t))return t;t=b.h?b.h(m):b.call(null,m);return $CLJS.n(t)?t:c.h?c.h(m):c.call(null,m)}var h=null,l=function(){function m(u,v,x,A){var D=null;if(3<arguments.length){D=
0;for(var H=Array(arguments.length-3);D<H.length;)H[D]=arguments[D+3],++D;D=new $CLJS.y(H,0,null)}return t.call(this,u,v,x,D)}function t(u,v,x,A){u=h.j(u,v,x);return $CLJS.n(u)?u:$CLJS.Ne(function(D){var H=a.h?a.h(D):a.call(null,D);if($CLJS.n(H))return H;H=b.h?b.h(D):b.call(null,D);return $CLJS.n(H)?H:c.h?c.h(D):c.call(null,D)},A)}m.A=3;m.B=function(u){var v=$CLJS.B(u);u=$CLJS.C(u);var x=$CLJS.B(u);u=$CLJS.C(u);var A=$CLJS.B(u);u=$CLJS.Lc(u);return t(v,x,A,u)};m.l=t;return m}();h=function(m,t,u,v){switch(arguments.length){case 0:return null;
case 1:return f.call(this,m);case 2:return e.call(this,m,t);case 3:return d.call(this,m,t,u);default:var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.y(A,0,null)}return l.l(m,t,u,x)}throw Error("Invalid arity: "+arguments.length);};h.A=3;h.B=l.B;h.o=function(){return null};h.h=f;h.g=e;h.j=d;h.l=l.l;return h}()};PF=function(a,b){return $CLJS.He($CLJS.J.g($CLJS.Vh.h(a),b))};
$CLJS.QF=function(a,b){return $CLJS.Zh($CLJS.q($CLJS.Yh()),a,b)};RF=function(a){return $CLJS.n($CLJS.OF($CLJS.xd,$CLJS.wd,$CLJS.Ra)(a))?a:new $CLJS.P(null,1,5,$CLJS.Q,[a],null)};SF=function(a,b,c){b=$CLJS.ih(RF(b));$CLJS.Rh.v($CLJS.IF,$CLJS.R,a,b);$CLJS.Rh.v($CLJS.JF,$CLJS.R,a,c)};
TF=function(a){return $CLJS.bb(function(b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return $CLJS.pE.j(b,d,c)},$CLJS.q(function(){var b=new $CLJS.Gc(function(){return $CLJS.Yh},$CLJS.HF,$CLJS.Ig([$CLJS.pk,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$CLJS.yi,$CLJS.T,$CLJS.jk],[!0,$CLJS.pi,$CLJS.FF,"cljs/core.cljs",28,1,11153,11153,$CLJS.V($CLJS.Bf),null,$CLJS.n($CLJS.Yh)?$CLJS.Yh.H:null]));return b.o?b.o():b.call(null)}()),a)};
UF=function(){$CLJS.n($CLJS.q($CLJS.LF))||$CLJS.n($CLJS.q($CLJS.LF))||$CLJS.Ue($CLJS.LF,TF(function(){return function c(b){return new $CLJS.me(null,function(){for(var d=b;;){var e=$CLJS.z(d);if(e){var f=e,h=$CLJS.B(f),l=$CLJS.I(h,0,null),m=$CLJS.I(h,1,null);if(e=$CLJS.z(function(t,u,v,x,A,D){return function U(K){return new $CLJS.me(null,function(Z,ca,ra){return function(){for(;;){var Ca=$CLJS.z(K);if(Ca){if($CLJS.Bd(Ca)){var hb=$CLJS.kc(Ca),Ob=$CLJS.E(hb),Tb=$CLJS.qe(Ob);a:for(var zc=0;;)if(zc<Ob){var dc=
$CLJS.md(hb,zc);Tb.add(new $CLJS.P(null,2,5,$CLJS.Q,[dc,ra],null));zc+=1}else{hb=!0;break a}return hb?$CLJS.te($CLJS.ve(Tb),U($CLJS.lc(Ca))):$CLJS.te($CLJS.ve(Tb),null)}Tb=$CLJS.B(Ca);return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[Tb,ra],null),U($CLJS.Lc(Ca)))}return null}}}(t,u,v,x,A,D),null,null)}}(d,h,l,m,f,e)(m)))return $CLJS.cf.g(e,c($CLJS.Lc(d)));d=$CLJS.Lc(d)}else return null}},null,null)}($CLJS.q($CLJS.IF))}()));return $CLJS.q($CLJS.LF)};
WF=function(a){var b=UF();$CLJS.n($CLJS.q($CLJS.MF))||$CLJS.n($CLJS.q($CLJS.MF))||$CLJS.Ue($CLJS.MF,TF($CLJS.z($CLJS.q($CLJS.JF))));var c=$CLJS.q($CLJS.MF);return $CLJS.He($CLJS.bb($CLJS.Qe($CLJS.aA,$CLJS.Hv),$CLJS.bh($CLJS.q($CLJS.KF),new $CLJS.P(null,1,5,$CLJS.Q,[a],null)),function(){return function f(e){return new $CLJS.me(null,function(){for(var h=e;;){var l=$CLJS.z(h);if(l){var m=l,t=$CLJS.B(m);if($CLJS.QF(t,VF)){var u=$CLJS.$h(c,t);if(l=$CLJS.z(function(v,x,A,D,H,K,U){return function ra(ca){return new $CLJS.me(null,
function(Ca,hb,Ob){return function(){for(var Tb=ca;;)if(Tb=$CLJS.z(Tb)){if($CLJS.Bd(Tb)){var zc=$CLJS.kc(Tb),dc=$CLJS.E(zc),Ac=$CLJS.qe(dc);a:for(var wb=0;;)if(wb<dc){var Ib=$CLJS.md(zc,wb);$CLJS.QF(Ib,VF)||(Ib=$CLJS.Ee([Ib,$CLJS.fh([Ob])]),Ac.add(Ib));wb+=1}else{zc=!0;break a}return zc?$CLJS.te($CLJS.ve(Ac),ra($CLJS.lc(Tb))):$CLJS.te($CLJS.ve(Ac),null)}Ac=$CLJS.B(Tb);if($CLJS.QF(Ac,VF))Tb=$CLJS.Lc(Tb);else return $CLJS.de($CLJS.Ee([Ac,$CLJS.fh([Ob])]),ra($CLJS.Lc(Tb)))}else return null}}(v,x,A,D,
H,K,U),null,null)}}(h,u,t,m,l,b,c)(u)))return $CLJS.cf.g(l,f($CLJS.Lc(h)))}h=$CLJS.Lc(h)}else return null}},null,null)}(PF(b,a))}()))};$CLJS.XF=function XF(a){switch(arguments.length){case 1:return XF.h(arguments[0]);case 2:return XF.g(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return XF.l(arguments[0],arguments[1],new $CLJS.y(c.slice(2),0,null))}};$CLJS.XF.h=function(){return!0};
$CLJS.XF.g=function(a,b){return!$CLJS.F.g(a,b)};$CLJS.XF.l=function(a,b,c){if($CLJS.F.g(a,b))return!1;a=$CLJS.fh([a,b]);for(b=c;;){var d=$CLJS.B(b);c=$CLJS.C(b);if($CLJS.n(b)){if($CLJS.Id(a,d))return!1;a=$CLJS.ae.g(a,d);b=c}else return!0}};$CLJS.XF.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return this.l(b,a,c)};$CLJS.XF.A=2;YF=new $CLJS.M("type","Location","type/Location",-1929284186);ZF=new $CLJS.M("type","Score","type/Score",188189565);
$F=new $CLJS.M("type","CancelationTime","type/CancelationTime",1076177064);aG=new $CLJS.M("type","Company","type/Company",-1114287726);bG=new $CLJS.M("type","IPAddress","type/IPAddress",-808425343);cG=new $CLJS.M("Coercion","Number-\x3eTemporal","Coercion/Number-\x3eTemporal",-1061171580);dG=new $CLJS.M("type","CreationTimestamp","type/CreationTimestamp",-687400081);eG=new $CLJS.M("type","DateTimeWithLocalTZ","type/DateTimeWithLocalTZ",339704031);
fG=new $CLJS.M("type","PostgresEnum","type/PostgresEnum",-900722397);VF=new $CLJS.M("Coercion","*","Coercion/*",1713686116);gG=new $CLJS.M("Coercion","ISO8601-\x3eDateTime","Coercion/ISO8601-\x3eDateTime",-1255976090);hG=new $CLJS.M("type","Source","type/Source",1060815848);iG=new $CLJS.M("entity","SubscriptionTable","entity/SubscriptionTable",1660366729);jG=new $CLJS.M("type","JoinTemporal","type/JoinTemporal",-1465575331);kG=new $CLJS.M("type","DeletionTimestamp","type/DeletionTimestamp",-935598691);
lG=new $CLJS.M("type","JoinTimestamp","type/JoinTimestamp",1554527110);mG=new $CLJS.M("type","JSON","type/JSON",-14729800);$CLJS.nG=new $CLJS.M("type","Currency","type/Currency",713609092);oG=new $CLJS.M("Coercion","String-\x3eTemporal","Coercion/String-\x3eTemporal",946586714);$CLJS.pG=new $CLJS.M("type","ZipCode","type/ZipCode",845484542);$CLJS.qG=new $CLJS.M("type","URL","type/URL",-1433976351);rG=new $CLJS.M("type","CreationTime","type/CreationTime",-1473681229);
sG=new $CLJS.M("Coercion","UNIXSeconds-\x3eDateTime","Coercion/UNIXSeconds-\x3eDateTime",-1635093627);$CLJS.tG=new $CLJS.M("type","Comment","type/Comment",-1406574403);uG=new $CLJS.M("Coercion","ISO8601-\x3eTemporal","Coercion/ISO8601-\x3eTemporal",410003391);vG=new $CLJS.M("type","GrossMargin","type/GrossMargin",1961535799);$CLJS.wG=new $CLJS.M("type","Date","type/Date",-690428629);xG=new $CLJS.M("type","SerializedJSON","type/SerializedJSON",300065547);yG=new $CLJS.M("type","Cost","type/Cost",363698341);
$CLJS.zG=new $CLJS.M("type","Quantity","type/Quantity",-1936151227);AG=new $CLJS.M("Coercion","UNIXMilliSeconds-\x3eDateTime","Coercion/UNIXMilliSeconds-\x3eDateTime",-1296453709);$CLJS.BG=new $CLJS.M("type","DateTimeWithTZ","type/DateTimeWithTZ",-1919106635);CG=new $CLJS.M("type","Share","type/Share",-1285033895);DG=new $CLJS.M("type","JoinDate","type/JoinDate",-793235819);EG=new $CLJS.M("type","Structured","type/Structured",-1651495863);$CLJS.FG=new $CLJS.M("type","Latitude","type/Latitude",-1080544141);
GG=new $CLJS.M("type","Decimal","type/Decimal",-1657568790);HG=new $CLJS.M("type","Dictionary","type/Dictionary",1352048818);IG=new $CLJS.M("type","DeletionTime","type/DeletionTime",-1426373145);JG=new $CLJS.M("type","TimeWithZoneOffset","type/TimeWithZoneOffset",959948573);KG=new $CLJS.M("type","Product","type/Product",1803490713);LG=new $CLJS.M("type","DateTimeWithZoneID","type/DateTimeWithZoneID",-1588069560);MG=new $CLJS.M("entity","GoogleAnalyticsTable","entity/GoogleAnalyticsTable",910090965);
NG=new $CLJS.M("type","UpdatedTimestamp","type/UpdatedTimestamp",-754878742);OG=new $CLJS.M("type","CreationDate","type/CreationDate",-1102411433);$CLJS.PG=new $CLJS.M("type","Interval","type/Interval",-365323617);QG=new $CLJS.M("type","Income","type/Income",-342566883);RG=new $CLJS.M("Coercion","Bytes-\x3eTemporal","Coercion/Bytes-\x3eTemporal",-2023274781);$CLJS.SG=new $CLJS.M(null,"base_type","base_type",1908272670);TG=new $CLJS.M("type","Discount","type/Discount",109235331);
UG=new $CLJS.M("type","User","type/User",832931932);VG=new $CLJS.M("type","UpdatedTime","type/UpdatedTime",-1278858780);WG=new $CLJS.M("type","MongoBSONID","type/MongoBSONID",663302764);XG=new $CLJS.M("type","Instant","type/Instant",1168385286);$CLJS.YG=new $CLJS.M("type","Email","type/Email",-1486863280);ZG=new $CLJS.M("type","CancelationDate","type/CancelationDate",561287015);$G=new $CLJS.M("entity","GenericTable","entity/GenericTable",1152424804);
aH=new $CLJS.M("Coercion","UNIXMicroSeconds-\x3eDateTime","Coercion/UNIXMicroSeconds-\x3eDateTime",1299502399);$CLJS.bH=new $CLJS.M("type","City","type/City",420361040);cH=new $CLJS.M("type","CancelationTemporal","type/CancelationTemporal",-532782161);dH=new $CLJS.M("type","DeletionDate","type/DeletionDate",-2052285784);$CLJS.eH=new $CLJS.M("type","DateTime","type/DateTime",352113310);fH=new $CLJS.M("type","Birthdate","type/Birthdate",619594666);
gH=new $CLJS.M("type","CreationTemporal","type/CreationTemporal",-1324176405);hH=new $CLJS.M("Coercion","ISO8601-\x3eDate","Coercion/ISO8601-\x3eDate",-103814729);iH=new $CLJS.M("Coercion","YYYYMMDDHHMMSSBytes-\x3eTemporal","Coercion/YYYYMMDDHHMMSSBytes-\x3eTemporal",-624663946);$CLJS.jH=new $CLJS.M(null,"effective_type","effective_type",1699478099);kH=new $CLJS.M("Coercion","UNIXNanoSeconds-\x3eDateTime","Coercion/UNIXNanoSeconds-\x3eDateTime",925800243);
lH=new $CLJS.M("type","Duration","type/Duration",1970868302);mH=new $CLJS.M("type","TimeWithLocalTZ","type/TimeWithLocalTZ",-259094111);$CLJS.nH=new $CLJS.M("type","AvatarURL","type/AvatarURL",-425042887);oH=new $CLJS.M("entity","UserTable","entity/UserTable",-1504290772);pH=new $CLJS.M("Coercion","UNIXTime-\x3eTemporal","Coercion/UNIXTime-\x3eTemporal",-968093468);qH=new $CLJS.M("type","UUID","type/UUID",1767712212);rH=new $CLJS.M("Coercion","ISO8601-\x3eTime","Coercion/ISO8601-\x3eTime",-1197754849);
sH=new $CLJS.M("type","DeletionTemporal","type/DeletionTemporal",2136289994);tH=new $CLJS.M("type","TimeWithTZ","type/TimeWithTZ",-442869120);uH=new $CLJS.M("type","DruidHyperUnique","type/DruidHyperUnique",-585863040);vH=new $CLJS.M("type","Author","type/Author",-836053084);wH=new $CLJS.M("type","DateTimeWithZoneOffset","type/DateTimeWithZoneOffset",1768393068);xH=new $CLJS.M("type","UpdatedDate","type/UpdatedDate",-1756161562);yH=new $CLJS.M("type","UpdatedTemporal","type/UpdatedTemporal",-596775941);
$CLJS.zH=new $CLJS.M("type","Description","type/Description",-680883950);AH=new $CLJS.M("type","Enum","type/Enum",-1132893505);BH=new $CLJS.M("type","Owner","type/Owner",1745970850);CH=new $CLJS.M("type","Title","type/Title",1977060721);DH=new $CLJS.M("type","JoinTime","type/JoinTime",1290040594);EH=new $CLJS.M("entity","EventTable","entity/EventTable",-2132834802);FH=new $CLJS.M("type","Collection","type/Collection",1447925820);
GH=new $CLJS.M("type","CancelationTimestamp","type/CancelationTimestamp",-741584330);$CLJS.HH=new $CLJS.M("type","Country","type/Country",2058497652);$CLJS.IH=new $CLJS.M("type","Longitude","type/Longitude",-196788672);JH=new $CLJS.M("type","Subscription","type/Subscription",-1076112474);KH=new $CLJS.M("type","Price","type/Price",286577051);LH=new $CLJS.M("type","Array","type/Array",-2060534244);MH=new $CLJS.M("type","XML","type/XML",-804526569);
$CLJS.NH=new $CLJS.M("type","ImageURL","type/ImageURL",2081541690);$CLJS.OH=new $CLJS.M("type","Float","type/Float",1261800143);PH=new $CLJS.M("entity","CompanyTable","entity/CompanyTable",-1166853089);QH=new $CLJS.M("Coercion","YYYYMMDDHHMMSSString-\x3eTemporal","Coercion/YYYYMMDDHHMMSSString-\x3eTemporal",41716060);$CLJS.RH=new $CLJS.M("type","State","type/State",-154641657);SH=new $CLJS.M("entity","TransactionTable","entity/TransactionTable",-888813059);
TH=new $CLJS.M("entity","ProductTable","entity/ProductTable",1692844366);$CLJS.UH=new $CLJS.M("type","Time","type/Time",-814852413);VH=new $CLJS.M("type","BigInteger","type/BigInteger",1152965666);$CLJS.pE.g($G,new $CLJS.M("entity","*","entity/*",-2043291259));$CLJS.pE.g(oH,$G);$CLJS.pE.g(PH,$G);$CLJS.pE.g(SH,$G);$CLJS.pE.g(TH,$G);$CLJS.pE.g(iG,$G);$CLJS.pE.g(EH,$G);$CLJS.pE.g(MG,$G);$CLJS.pE.g($CLJS.Nj,$CLJS.zj);$CLJS.pE.g($CLJS.vj,$CLJS.Nj);$CLJS.pE.g(VH,$CLJS.vj);$CLJS.pE.g($CLJS.zG,$CLJS.rj);$CLJS.pE.g($CLJS.zG,$CLJS.vj);$CLJS.pE.g($CLJS.OH,$CLJS.Nj);$CLJS.pE.g(GG,$CLJS.OH);$CLJS.pE.g(CG,$CLJS.rj);$CLJS.pE.g(CG,$CLJS.OH);$CLJS.pE.g($CLJS.nG,GG);$CLJS.pE.g($CLJS.nG,$CLJS.rj);
$CLJS.pE.g(QG,$CLJS.nG);$CLJS.pE.g(TG,$CLJS.nG);$CLJS.pE.g(KH,$CLJS.nG);$CLJS.pE.g(vG,$CLJS.nG);$CLJS.pE.g(yG,$CLJS.nG);$CLJS.pE.g(YF,$CLJS.rj);$CLJS.pE.g($CLJS.xj,YF);$CLJS.pE.g($CLJS.xj,$CLJS.OH);$CLJS.pE.g($CLJS.FG,$CLJS.xj);$CLJS.pE.g($CLJS.IH,$CLJS.xj);$CLJS.pE.g(ZF,$CLJS.rj);$CLJS.pE.g(ZF,$CLJS.Nj);$CLJS.pE.g(lH,$CLJS.rj);$CLJS.pE.g(lH,$CLJS.Nj);$CLJS.pE.g($CLJS.bk,$CLJS.zj);$CLJS.pE.g(qH,$CLJS.bk);$CLJS.pE.g($CLJS.qG,$CLJS.rj);$CLJS.pE.g($CLJS.qG,$CLJS.bk);$CLJS.pE.g($CLJS.NH,$CLJS.qG);
$CLJS.pE.g($CLJS.nH,$CLJS.NH);$CLJS.pE.g($CLJS.YG,$CLJS.rj);$CLJS.pE.g($CLJS.YG,$CLJS.bk);$CLJS.pE.g($CLJS.Qj,$CLJS.rj);$CLJS.pE.g(AH,$CLJS.rj);$CLJS.pE.g($CLJS.Ni,YF);$CLJS.pE.g($CLJS.bH,$CLJS.Ni);$CLJS.pE.g($CLJS.bH,$CLJS.Qj);$CLJS.pE.g($CLJS.bH,$CLJS.bk);$CLJS.pE.g($CLJS.RH,$CLJS.Ni);$CLJS.pE.g($CLJS.RH,$CLJS.Qj);$CLJS.pE.g($CLJS.RH,$CLJS.bk);$CLJS.pE.g($CLJS.HH,$CLJS.Ni);$CLJS.pE.g($CLJS.HH,$CLJS.Qj);$CLJS.pE.g($CLJS.HH,$CLJS.bk);$CLJS.pE.g($CLJS.pG,$CLJS.Ni);$CLJS.pE.g($CLJS.pG,$CLJS.bk);
$CLJS.pE.g($CLJS.jj,$CLJS.Qj);$CLJS.pE.g($CLJS.jj,$CLJS.bk);$CLJS.pE.g(CH,$CLJS.Qj);$CLJS.pE.g(CH,$CLJS.bk);$CLJS.pE.g($CLJS.zH,$CLJS.rj);$CLJS.pE.g($CLJS.zH,$CLJS.bk);$CLJS.pE.g($CLJS.tG,$CLJS.rj);$CLJS.pE.g($CLJS.tG,$CLJS.bk);$CLJS.pE.g(fG,$CLJS.bk);$CLJS.pE.g($CLJS.Uj,$CLJS.zj);$CLJS.pE.g($CLJS.wG,$CLJS.Uj);$CLJS.pE.g($CLJS.UH,$CLJS.Uj);$CLJS.pE.g(tH,$CLJS.UH);$CLJS.pE.g(mH,tH);$CLJS.pE.g(JG,tH);$CLJS.pE.g($CLJS.eH,$CLJS.Uj);$CLJS.pE.g($CLJS.BG,$CLJS.eH);$CLJS.pE.g(eG,$CLJS.BG);$CLJS.pE.g(wH,$CLJS.BG);
$CLJS.pE.g(LG,$CLJS.BG);$CLJS.pE.g(XG,eG);$CLJS.pE.g(gH,$CLJS.rj);$CLJS.pE.g(dG,gH);$CLJS.pE.g(dG,$CLJS.eH);$CLJS.pE.g(rG,gH);$CLJS.pE.g(rG,$CLJS.UH);$CLJS.pE.g(OG,gH);$CLJS.pE.g(OG,$CLJS.wG);$CLJS.pE.g(jG,$CLJS.rj);$CLJS.pE.g(lG,jG);$CLJS.pE.g(lG,$CLJS.eH);$CLJS.pE.g(DH,jG);$CLJS.pE.g(DH,$CLJS.UH);$CLJS.pE.g(DG,jG);$CLJS.pE.g(DG,$CLJS.wG);$CLJS.pE.g(cH,$CLJS.rj);$CLJS.pE.g(GH,cH);$CLJS.pE.g(GH,$CLJS.eH);$CLJS.pE.g($F,cH);$CLJS.pE.g($F,$CLJS.wG);$CLJS.pE.g(ZG,cH);$CLJS.pE.g(ZG,$CLJS.wG);
$CLJS.pE.g(sH,$CLJS.rj);$CLJS.pE.g(kG,sH);$CLJS.pE.g(kG,$CLJS.eH);$CLJS.pE.g(IG,sH);$CLJS.pE.g(IG,$CLJS.UH);$CLJS.pE.g(dH,sH);$CLJS.pE.g(dH,$CLJS.wG);$CLJS.pE.g(yH,$CLJS.rj);$CLJS.pE.g(NG,yH);$CLJS.pE.g(NG,$CLJS.eH);$CLJS.pE.g(VG,yH);$CLJS.pE.g(VG,$CLJS.UH);$CLJS.pE.g(xH,yH);$CLJS.pE.g(xH,$CLJS.wG);$CLJS.pE.g(fH,$CLJS.rj);$CLJS.pE.g(fH,$CLJS.wG);$CLJS.pE.g($CLJS.PG,$CLJS.Uj);$CLJS.pE.g($CLJS.Jj,$CLJS.zj);$CLJS.pE.g(uH,$CLJS.zj);$CLJS.pE.g($CLJS.oj,$CLJS.zj);$CLJS.pE.g(WG,$CLJS.oj);$CLJS.pE.g(bG,$CLJS.oj);
$CLJS.pE.g(bG,$CLJS.rj);$CLJS.pE.g(FH,$CLJS.zj);$CLJS.pE.g(EG,$CLJS.zj);$CLJS.pE.g(HG,FH);$CLJS.pE.g(LH,FH);$CLJS.pE.g(mG,EG);$CLJS.pE.g(mG,FH);$CLJS.pE.g(MH,EG);$CLJS.pE.g(MH,FH);$CLJS.pE.g(EG,$CLJS.rj);$CLJS.pE.g(EG,$CLJS.bk);$CLJS.pE.g(xG,EG);$CLJS.pE.g(MH,EG);$CLJS.pE.g(UG,$CLJS.rj);$CLJS.pE.g(vH,UG);$CLJS.pE.g(BH,UG);$CLJS.pE.g(KG,$CLJS.Qj);$CLJS.pE.g(aG,$CLJS.Qj);$CLJS.pE.g(JH,$CLJS.Qj);$CLJS.pE.g(hG,$CLJS.Qj);$CLJS.pE.g($CLJS.oi,$CLJS.qi);$CLJS.pE.g($CLJS.si,$CLJS.qi);$CLJS.pE.g(oG,VF);
$CLJS.pE.g(uG,oG);$CLJS.pE.g(gG,uG);$CLJS.pE.g(rH,uG);$CLJS.pE.g(hH,uG);$CLJS.pE.g(QH,oG);$CLJS.pE.g(RG,VF);$CLJS.pE.g(iH,RG);$CLJS.pE.g(cG,VF);$CLJS.pE.g(pH,cG);$CLJS.pE.g(sG,pH);$CLJS.pE.g(AG,pH);$CLJS.pE.g(aH,pH);$CLJS.pE.g(kH,pH);
var XH=$CLJS.bl($CLJS.eg.g($CLJS.N,function WH(a){return new $CLJS.me(null,function(){for(;;){var c=$CLJS.z(a);if(c){if($CLJS.Bd(c)){var d=$CLJS.kc(c),e=$CLJS.E(d),f=$CLJS.qe(e);a:for(var h=0;;)if(h<e){var l=$CLJS.md(d,h);l=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jh(l),$CLJS.jE(l)],null);f.add(l);h+=1}else{d=!0;break a}return d?$CLJS.te($CLJS.ve(f),WH($CLJS.lc(c))):$CLJS.te($CLJS.ve(f),null)}f=$CLJS.B(c);return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jh(f),$CLJS.jE(f)],null),WH($CLJS.Lc(c)))}return null}},
null,null)}($CLJS.al.h($CLJS.gf($CLJS.cl,$CLJS.G([new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,$CLJS.rj,$CLJS.qi],null)]))))));SF(kH,new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.vj,null,GG,null],null),null),XG);SF(aH,new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.vj,null,GG,null],null),null),XG);SF(AG,new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.vj,null,GG,null],null),null),XG);SF(sG,new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.vj,null,GG,null],null),null),XG);SF(hH,$CLJS.bk,$CLJS.wG);SF(gG,$CLJS.bk,$CLJS.eH);
SF(rH,$CLJS.bk,$CLJS.UH);SF(QH,$CLJS.bk,$CLJS.eH);$CLJS.Rh.j($CLJS.KF,$CLJS.Qe($CLJS.aA,$CLJS.Hv),$CLJS.cw(RF($CLJS.zj),new $CLJS.Ze(null,-1,$CLJS.fh([iH]),null)));$CLJS.Rh.v($CLJS.JF,$CLJS.R,iH,$CLJS.eH);
module.exports={isa:function(a,b){return $CLJS.QF($CLJS.Ch.h(a),$CLJS.Ch.h(b))},coercions_for_type:function(a){a=$CLJS.eg.j($CLJS.Kc,$CLJS.Hk.g($CLJS.al.o(),$CLJS.gv),$CLJS.Zg(WF($CLJS.Ch.h(a))));return $CLJS.bl($CLJS.ff.g(function(b){return[$CLJS.he(b),"/",$CLJS.jh(b)].join("")},a))},is_coerceable:function(a){return $CLJS.Fd($CLJS.He(WF($CLJS.Ch.h(a))))},TYPE:XH};