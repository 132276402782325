var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var MI,RI,TI,UI,VI,fJ,kJ,lJ;
$CLJS.JI=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.Hu([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.KI=function(a,b){a=$CLJS.tv($CLJS.aE(a,/''/,"'"),/\{\d+\}/);return $CLJS.O.j($CLJS.Fu.t,$CLJS.bl(a),$CLJS.bl(b))};$CLJS.LI=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.KI(arguments[0],1<b.length?new $CLJS.y(b.slice(1),0,null):null)};
MI=function(a){return $CLJS.Ad(a)&&$CLJS.B(a)instanceof $CLJS.M};$CLJS.OI=function(a){var b=function(){var c=MI(a);return c?(c=$CLJS.yd($CLJS.jd(a)))?(c=$CLJS.Ii.h($CLJS.jd(a)),$CLJS.n(c)?c:$CLJS.CE.h($CLJS.jd(a))):c:c}();return $CLJS.n(b)?b:$CLJS.NI.h(a)};
RI=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,2,[$CLJS.Au,"valid MBQL clause",$CLJS.Cu,function(c){c=$CLJS.Ge(c);c=$CLJS.J.g(c,$CLJS.Cj);return["invalid MBQL clause: ",$CLJS.Ph.l($CLJS.G([c]))].join("")}],null),$CLJS.Oe(MI)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,b],null),function(c){c=$CLJS.OI(c);
return QI(c,a)}],null)],null)};$CLJS.SI=new $CLJS.M(null,"right-join","right-join",-56349359);TI=new $CLJS.M("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);UI=new $CLJS.M("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);VI=new $CLJS.M("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.WI=new $CLJS.M(null,"fields","fields",-1932066230);
$CLJS.XI=new $CLJS.M(null,"expr","expr",745722291);$CLJS.YI=new $CLJS.M("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.ZI=new $CLJS.M("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.$I=new $CLJS.M(null,"alias","alias",-2039751630);$CLJS.aJ=new $CLJS.M("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);
$CLJS.bJ=new $CLJS.M("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.cJ=new $CLJS.M("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.dJ=new $CLJS.M("lib","expression-name","lib/expression-name",-1799326590);$CLJS.eJ=new $CLJS.M("mbql.clause","field","mbql.clause/field",1497292735);fJ=new $CLJS.M("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);
$CLJS.gJ=new $CLJS.M(null,"left-join","left-join",-672831855);$CLJS.hJ=new $CLJS.M("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.iJ=new $CLJS.M("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.jJ=new $CLJS.M("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);
kJ=new $CLJS.w("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);lJ=new $CLJS.M("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.mJ=new $CLJS.M(null,"inner-join","inner-join",659431740);$CLJS.PI=new $CLJS.M("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);
$CLJS.nJ=new $CLJS.M("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.oJ=new $CLJS.M(null,"full-join","full-join",1305476385);$CLJS.pJ=new $CLJS.M("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.qJ=new $CLJS.M("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.NI=function(){var a=$CLJS.Te($CLJS.N),b=$CLJS.Te($CLJS.N),c=$CLJS.Te($CLJS.N),d=$CLJS.Te($CLJS.N),e=$CLJS.J.j(new $CLJS.k(null,1,[$CLJS.gj,$CLJS.DF],null),$CLJS.gj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.schema.expression","type-of-method"),function(f){var h=$CLJS.BF(f);return $CLJS.F.g(h,$CLJS.BE)?$CLJS.Ya(f):h},e,a,b,c,d)}();$CLJS.X(UI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.cJ],null),$CLJS.EI],null));
$CLJS.NI.m(null,$CLJS.gi,function(a){throw $CLJS.li($CLJS.KI("{0}: Don''t know how to determine the type of {1}",$CLJS.G([kJ,$CLJS.Ph.l($CLJS.G([a]))])),new $CLJS.k(null,1,[$CLJS.XI,a],null));});$CLJS.NI.m(null,$CLJS.iJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.OI(a)});
var QI=function QI(a,b){return $CLJS.wd(a)?$CLJS.Ne(function(d){return QI.g?QI.g(d,b):QI.call(null,d,b)},a):$CLJS.wd(b)?$CLJS.Ne(function(d){return QI.g?QI.g(a,d):QI.call(null,a,d)},b):$CLJS.F.g(a,$CLJS.cJ)?!0:$CLJS.QF(a,b)};$CLJS.X($CLJS.aJ,RI($CLJS.Jj,"expression returning a boolean"));$CLJS.X($CLJS.nJ,RI($CLJS.bk,"expression returning a string"));$CLJS.X($CLJS.qJ,RI($CLJS.vj,"expression returning an integer"));$CLJS.X(VI,RI($CLJS.OH,"expression returning a non-integer real number"));
$CLJS.X($CLJS.pJ,RI($CLJS.Nj,"expression returning a number"));$CLJS.X(fJ,RI($CLJS.wG,"expression returning a date"));$CLJS.X(lJ,RI($CLJS.UH,"expression returning a time"));$CLJS.X(TI,RI($CLJS.eH,"expression returning a date time"));$CLJS.X($CLJS.ZI,RI($CLJS.Uj,"expression returning a date, time, or date time"));$CLJS.rJ=new $CLJS.dh(null,new $CLJS.k(null,3,[$CLJS.Nj,null,$CLJS.bk,null,$CLJS.Uj,null],null),null);$CLJS.X($CLJS.jJ,RI($CLJS.rJ,"an expression that can be compared with :\x3e or :\x3c"));
$CLJS.X($CLJS.hJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,RI(new $CLJS.dh(null,new $CLJS.k(null,4,[$CLJS.Nj,null,$CLJS.bk,null,$CLJS.Uj,null,$CLJS.Jj,null],null),null),"an expression that can appear in :\x3d or :!\x3d")],null));$CLJS.X($CLJS.bJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,RI($CLJS.zj,"any type of expression")],null));
$CLJS.X($CLJS.YI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.k(null,1,[$CLJS.On,1],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bJ],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ys,$CLJS.Dt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dJ,$CLJS.Xj],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,$CLJS.Dt],null)],null)],null)],null));