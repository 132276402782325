var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var yP,zP,AP,Yba,Zba,EP,$ba,HP,IP,JP,KP,aca,LP,MP,OP,PP,BP,QP,bca,RP,cca,TP,UP,VP,dca,WP,XP,eca,ZP,fca,$P,aQ,gca,cQ,hca,ica,jca,eQ,fQ,gQ,hQ,iQ,jQ,kQ,lQ,kca,mQ,nQ,oQ,qQ,sQ,lca,tQ,uQ,vQ,wQ,mca,xQ,yQ,zQ,nca,AQ,BQ,CQ,DQ,oca,EQ,pca,FQ,GQ,HQ,qca,IQ,JQ,KQ,LQ,NQ,OQ,rca,PQ,QQ,sca,RQ,TQ,UQ,VQ,WQ,tca,XQ,uca,YQ,ZQ,aR,vca,bR,cR,dR,wca,gR,hR,xca,yca,iR,kR,lR,mR,nR,zca,qR,rR,Aca,Bca,Cca,sR,tR,uR,vR,wR,xR,Dca,yR,Eca,zR,AR,CR,ER,FR,GR,HR,Fca,JR,Gca,Hca,KR,LR,MR,NR,OR,Ica,PR,Jca,Kca,QR,RR,SR,TR,UR,Lca,
Mca,Nca,VR,Oca,WR,YR,Pca,$R,aS,Qca,cS,dS,eS,fS,Rca,iS,Sca,Tca,jS,kS,lS,Uca,mS,nS,oS,pS,Vca,rS,sS,tS,vS,wS,Wca,xS,yS,Xca,zS,AS,BS,CS,DS,ES,GS,Yca,HS,Zca,IS,JS,KS,$ca,ada,bda,MS,NS,OS,cda,PS,QS,RS,SS,dda,eda,US,fda,gda,VS,WS,XS,YS,ZS,hda,$S,aT,bT,cT,ida,dT,eT,fT,gT,iT,jda,jT,kT,kda,lT,mT,nT,lda,oT,mda,qT,nda,rT,sT,tT,uT,vT,wT,xT,oda,pda,qda,yT,rda,zT,AT,sda,tda,BT,CT,DT,uda,ET,vda,FT,wda,xda,GT,yda,zda,Ada,JT,KT,Bda,LT,MT,OT,Cda,PT,Dda,QT,RT,ST,Eda,Fda,TT,UT,VT,WT,Gda,YT,ZT,Hda,$T,aU,bU,cU,dU,eU,fU,
gU,Ida,hU,iU,Jda,jU,Kda,kU,lU,mU,Lda,nU,oU,Mda,Nda,pU,qU,rU,Oda,Pda,sU,Qda,Rda,Sda,vU,Tda,yU,Uda,Vda,zU,AU,Wda,Xda,Yda,BU,CU,DU,EU,FU,GU,HU,Zda,IU,JU,$da,KU,aea,bea,cea,LU,MU,NU,dea,OU,PU,eea,QU,fea,RU,gea,hea,TU,VU,WU,XU,YU,iea,ZU,$U,jea,aV,bV,cV,dV,eV,fV,gV,hV,kea,iV,jV,lV,mV,nV,oV,pV,qV,rV,sV,tV,lea,uV,vV,wV,xV,yV,mea,zV,AV,BV,CV,nea,EV,oea,pea,GV,qea,HV,rea,IV,sea,tea,JV,uea,KV,MV,NV,vea,RV,SV,wea,xea,TV,UV,VV,WV,XV,YV,ZV,aW,bW,yea;
$CLJS.wP=function(a,b){return function(){function c(l,m,t){return $CLJS.Fd(function(){var u=a.h?a.h(l):a.call(null,l);return $CLJS.n(u)?(u=a.h?a.h(m):a.call(null,m),$CLJS.n(u)?(u=a.h?a.h(t):a.call(null,t),$CLJS.n(u)?(u=b.h?b.h(l):b.call(null,l),$CLJS.n(u)?(u=b.h?b.h(m):b.call(null,m),$CLJS.n(u)?b.h?b.h(t):b.call(null,t):u):u):u):u):u}())}function d(l,m){return $CLJS.Fd(function(){var t=a.h?a.h(l):a.call(null,l);return $CLJS.n(t)?(t=a.h?a.h(m):a.call(null,m),$CLJS.n(t)?(t=b.h?b.h(l):b.call(null,l),
$CLJS.n(t)?b.h?b.h(m):b.call(null,m):t):t):t}())}function e(l){var m=a.h?a.h(l):a.call(null,l);l=$CLJS.n(m)?b.h?b.h(l):b.call(null,l):m;return $CLJS.Fd(l)}var f=null,h=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var D=Array(arguments.length-3);A<D.length;)D[A]=arguments[A+3],++A;A=new $CLJS.y(D,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.Fd(function(){var A=f.j(t,u,v);return $CLJS.n(A)?$CLJS.Me(function(D){var H=a.h?a.h(D):a.call(null,D);return $CLJS.n(H)?
b.h?b.h(D):b.call(null,D):H},x):A}())}l.A=3;l.B=function(t){var u=$CLJS.B(t);t=$CLJS.C(t);var v=$CLJS.B(t);t=$CLJS.C(t);var x=$CLJS.B(t);t=$CLJS.Lc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 0:return!0;case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.y(x,0,null)}return h.l(l,m,t,
v)}throw Error("Invalid arity: "+arguments.length);};f.A=3;f.B=h.B;f.o=function(){return!0};f.h=e;f.g=d;f.j=c;f.l=h.l;return f}()};
$CLJS.xP=function(a){var b=$CLJS.no.g(a,null),c=$CLJS.tJ(b,$CLJS.Lt,function(d){return $CLJS.mn(d,$CLJS.Bf)});return function(){function d(h,l,m){return(l=$CLJS.z(c.j?c.j(h,l,m):c.call(null,h,l,m)))?new $CLJS.k(null,3,[$CLJS.hl,b,$CLJS.Cj,h,$CLJS.St,l],null):null}function e(h){return f.j(h,$CLJS.Bf,$CLJS.Bf)}var f=null;f=function(h,l,m){switch(arguments.length){case 1:return e.call(this,h);case 3:return d.call(this,h,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
yP=function(a,b){return $CLJS.yd(a)?$CLJS.J.g(a,b):a};zP=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.pn($CLJS.no.g($CLJS.hl.h(a),null));if($CLJS.n(b)){var e=yP($CLJS.Cu.h(b),c);$CLJS.n(e)?(e=$CLJS.wq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:yP($CLJS.Au.h(b),c)}return null};AP=function(a,b){return $CLJS.wd(a)||$CLJS.Al(a)?$CLJS.J.g(a,b):$CLJS.xd(a)?$CLJS.J.g($CLJS.Cf(a),b):null};
Yba=function(a,b){a=$CLJS.Ge(a);var c=$CLJS.J.g(a,$CLJS.hl),d=$CLJS.J.g(a,$CLJS.lj);b=$CLJS.Ge(b);var e=$CLJS.J.j(b,$CLJS.St,$CLJS.AF),f=$CLJS.J.j(b,$CLJS.sF,!0),h=$CLJS.J.g(b,$CLJS.zF),l=$CLJS.J.j(b,$CLJS.uF,$CLJS.qF),m=zP(a,$CLJS.Iq.h(c),h,b);if($CLJS.n(m))return m;m=zP(a,$CLJS.gu.h(c),h,b);if($CLJS.n(m))return m;m=zP(a,e.h?e.h(d):e.call(null,d),h,b);if($CLJS.n(m))return m;m=zP(a,function(){var t=$CLJS.Un.h(c);return e.h?e.h(t):e.call(null,t)}(),h,b);if($CLJS.n(m))return m;m=zP(a,$CLJS.Iq.h(c),
l,b);if($CLJS.n(m))return m;m=zP(a,$CLJS.gu.h(c),l,b);if($CLJS.n(m))return m;d=zP(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=zP(a,function(){var t=$CLJS.Un.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;h=$CLJS.n(f)?zP(a,e.h?e.h($CLJS.oF):e.call(null,$CLJS.oF),h,b):f;return $CLJS.n(h)?h:$CLJS.n(f)?zP(a,e.h?e.h($CLJS.oF):e.call(null,$CLJS.oF),l,b):f};
Zba=function(a,b,c){a=$CLJS.Q;var d=$CLJS.eg.g;var e=$CLJS.el.h(b);var f=$CLJS.Ge(b);var h=$CLJS.J.g(f,$CLJS.hl),l=$CLJS.Ge(c);f=$CLJS.J.g(l,$CLJS.zF);l=$CLJS.J.j(l,$CLJS.uF,$CLJS.qF);h=$CLJS.Iq.h(h);f=yP($CLJS.rF.h(h),f);f=$CLJS.n(f)?f:yP($CLJS.rF.h(h),l);d=d.call($CLJS.eg,e,f);return new $CLJS.P(null,2,5,a,[d,Yba(b,c)],null)};
$CLJS.CP=function(a){var b=$CLJS.Ge(a),c=$CLJS.J.g(b,$CLJS.Cj);a=$CLJS.J.g(b,$CLJS.St);var d=$CLJS.Ge(null),e=$CLJS.J.j(d,$CLJS.tF,$CLJS.Si),f=$CLJS.J.j(d,$CLJS.xF,Zba);return $CLJS.n(a)?$CLJS.bb(function(h,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.Si,m);l=e.h?e.h(l):e.call(null,l);return BP(h,c,t,l)},null,a):null};
EP=function(a,b,c){var d=$CLJS.XD($CLJS.q($CLJS.hI),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Rh.v($CLJS.hI,$CLJS.DP,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.FP=function(a){return EP($CLJS.qk,a,function(){return $CLJS.uJ.h(a)})};$ba=function(){var a=GP;return EP($CLJS.Lt,a,function(){var b=$CLJS.uJ.h(a),c=$CLJS.xP(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
HP=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,$CLJS.le(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};IP=function(a){if($CLJS.Ad(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.T:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,HP(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,HP(c)],null);default:return HP(a)}}else return HP(a)};
JP=function(a,b){var c=$CLJS.Ad(b);return c?(c=$CLJS.B(b)instanceof $CLJS.M)?$CLJS.vd(a)?(b=$CLJS.B(b),a=$CLJS.ih(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,$CLJS.B(b)):c:c};
KP=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Qe(JP,a)],null),$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.at,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,a],null)],null)],null),function(){return function e(d){return new $CLJS.me(null,function(){for(;;){var f=$CLJS.z(d);if(f){if($CLJS.Bd(f)){var h=$CLJS.kc(f),l=$CLJS.E(h),m=$CLJS.qe(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.md(h,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,IP(u)],null);m.add(v);t+=1}else{h=!0;break a}return h?$CLJS.te($CLJS.ve(m),e($CLJS.lc(f))):$CLJS.te($CLJS.ve(m),null)}h=$CLJS.B(f);m=$CLJS.I(h,0,null);h=$CLJS.I(h,1,null);return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[m,IP(h)],null),e($CLJS.Lc(f)))}return null}},null,null)}($CLJS.ju(2,2,b))}())],null)};aca=function(a){return $CLJS.Ad(a)&&$CLJS.B(a)instanceof $CLJS.M?$CLJS.B(a):null};
LP=function(a){return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Qi,aca,$CLJS.Au,["valid instance of one of these MBQL clauses: ",$CLJS.Mu(", ",$CLJS.ff.g($CLJS.B,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.me(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.Bd(e)){var f=$CLJS.kc(e),h=$CLJS.E(f),l=$CLJS.qe(h);a:for(var m=0;;)if(m<h){var t=$CLJS.md(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.le(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.lc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.B(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.de(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.le(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};
MP=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vP],null)],null)};$CLJS.NP={};OP={};PP={};$CLJS.DP=function DP(a,b,c){var e=$CLJS.z(b);b=$CLJS.B(e);var f=$CLJS.C(e);if(f){e=$CLJS.R.j;var h=$CLJS.J.g(a,b);c=DP.j?DP.j(h,f,c):DP.call(null,h,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
BP=function BP(a,b,c,d){var f=$CLJS.z(c);c=$CLJS.B(f);var h=$CLJS.C(f),l=AP(b,c);f=$CLJS.n(a)?a:$CLJS.xd(b)?$CLJS.Bf:$CLJS.zd(b)?$CLJS.N:$CLJS.ld(b);return $CLJS.n($CLJS.n(c)?$CLJS.pF.h($CLJS.rd(f)):c)?a:$CLJS.n(c)?(b=AP(f,c),d=BP.v?BP.v(b,l,h,d):BP.call(null,b,l,h,d),$CLJS.Fl(c)&&$CLJS.xd(f)&&c>$CLJS.E(f)&&(b=$CLJS.$e(c-$CLJS.E(f),null),b=$CLJS.cf.g(f,b),f=null==f||$CLJS.Ed(f)?b:$CLJS.eg.g($CLJS.ld(f),b)),null==f||$CLJS.Al(f)?$CLJS.R.j(f,c,d):$CLJS.wd(f)?$CLJS.ae.g(f,d):$CLJS.O.g($CLJS.V,$CLJS.R.j($CLJS.Cf(f),
c,d))):$CLJS.yd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.wF],null),BP.v?BP.v(f,b,c,d):BP.call(null,f,b,c,d)):$CLJS.n($CLJS.pF.h($CLJS.rd(f)))?$CLJS.ae.g(f,d):$CLJS.Ad($CLJS.He(f))?f:$CLJS.qd(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.k(null,1,[$CLJS.pF,!0],null))};QP=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);bca=new $CLJS.w("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
RP=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);$CLJS.SP=new $CLJS.M(null,"dimension","dimension",543254198);cca=new $CLJS.w("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);TP=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);UP=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);
VP=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);dca=new $CLJS.w("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);WP=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);XP=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);eca=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);
$CLJS.YP=new $CLJS.M(null,"query-hash","query-hash",-1524484965);ZP=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);fca=new $CLJS.M(null,"from","from",1815293044);$P=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);aQ=new $CLJS.w(null,"does-not-contain","does-not-contain",1365584674,null);gca=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);
$CLJS.bQ=new $CLJS.M(null,"snippet-name","snippet-name",819240328);cQ=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);hca=new $CLJS.w("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);ica=new $CLJS.w("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.dQ=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);
jca=new $CLJS.w("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);eQ=new $CLJS.M("location","zip_code","location/zip_code",1641155222);fQ=new $CLJS.w(null,"ExpressionArg","ExpressionArg",1060743736,null);gQ=new $CLJS.w(null,"get-hour","get-hour",-2031721710,null);hQ=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);iQ=new $CLJS.w(null,"ag:var","ag:var",-1804440286,null);
jQ=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);kQ=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);lQ=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);kca=new $CLJS.M(null,"lon-max","lon-max",1590224717);mQ=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);
nQ=new $CLJS.w(null,"stddev","stddev",775056588,null);oQ=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);$CLJS.pQ=new $CLJS.M(null,"snippet","snippet",953581994);qQ=new $CLJS.w("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);$CLJS.rQ=new $CLJS.M(null,"query","query",-1288509510);sQ=new $CLJS.M(null,"string-expression","string-expression",-1395337766);lca=new $CLJS.M(null,"lat-field","lat-field",-830652957);
tQ=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);uQ=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);vQ=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);wQ=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);mca=new $CLJS.w("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);
xQ=new $CLJS.M("location","country","location/country",1666636202);yQ=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);zQ=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);nca=new $CLJS.w("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);AQ=new $CLJS.M(null,"unary","unary",-989314568);BQ=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
CQ=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);DQ=new $CLJS.w(null,"ceil","ceil",-184398425,null);oca=new $CLJS.M(null,"lon-min","lon-min",-787291357);EQ=new $CLJS.w(null,"NonBlankString","NonBlankString",-719244809,null);pca=new $CLJS.M(null,"match","match",1220059550);FQ=new $CLJS.w(null,"count-where","count-where",2025939247,null);GQ=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
HQ=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);qca=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);IQ=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);JQ=new $CLJS.w(null,"sum","sum",1777518341,null);KQ=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);LQ=new $CLJS.w("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.MQ=new $CLJS.M("date","range","date/range",1647265776);NQ=new $CLJS.w(null,"between","between",-1523336493,null);OQ=new $CLJS.M(null,"clause-form","clause-form",1820463737);rca=new $CLJS.w("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);PQ=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);QQ=new $CLJS.w(null,"field","field",338095027,null);sca=new $CLJS.M(null,"segment-id","segment-id",1810133590);
RQ=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);$CLJS.SQ=new $CLJS.M(null,"filter","filter",-948537934);TQ=new $CLJS.w(null,"not-null","not-null",313812992,null);UQ=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);VQ=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);WQ=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);
tca=new $CLJS.M(null,"template-tag","template-tag",310841038);XQ=new $CLJS.M(null,"invalid","invalid",412869516);uca=new $CLJS.w("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);YQ=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);ZQ=new $CLJS.w(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.$Q=new $CLJS.M(null,"context","context",-830191113);
aR=new $CLJS.w(null,"get-year","get-year",704520253,null);vca=new $CLJS.M(null,"format-rows?","format-rows?",992129486);bR=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);cR=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);dR=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.eR=new $CLJS.M(null,"parameters","parameters",-1229919748);
wca=new $CLJS.M(null,"json-download","json-download",-971130133);$CLJS.fR=new $CLJS.M(null,"card","card",-1430355152);gR=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);hR=new $CLJS.w(null,"!\x3d","!\x3d",-201205829,null);xca=new $CLJS.w("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);yca=new $CLJS.w("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);iR=new $CLJS.w(null,"expression","expression",1842843403,null);
$CLJS.jR=new $CLJS.M("date","month-year","date/month-year",1948031290);kR=new $CLJS.M("metabase.mbql.schema","FieldOrAggregationReference","metabase.mbql.schema/FieldOrAggregationReference",-334144392);lR=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);mR=new $CLJS.w(null,"ends-with","ends-with",1183740516,null);nR=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);
$CLJS.oR=new $CLJS.M(null,"joins","joins",1033962699);zca=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.pR=new $CLJS.M(null,"source-field","source-field",933829534);qR=new $CLJS.w(null,"Field","Field",430385967,null);rR=new $CLJS.w(null,"convert-timezone","convert-timezone",1515728526,null);Aca=new $CLJS.w("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);Bca=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);
Cca=new $CLJS.M(null,"items","items",1031954938);sR=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);tR=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);uR=new $CLJS.M(null,"more","more",-2058821800);vR=new $CLJS.M(null,"first-clause","first-clause",-20953491);wR=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);xR=new $CLJS.w(null,"OrderComparable","OrderComparable",772072595,null);
Dca=new $CLJS.w("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);yR=new $CLJS.w(null,"contains","contains",-1977535957,null);Eca=new $CLJS.w("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);zR=new $CLJS.w("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);AR=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.BR=new $CLJS.M(null,"widget-type","widget-type",1836256899);
CR=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.DR=new $CLJS.M(null,"fingerprint","fingerprint",598613022);ER=new $CLJS.w(null,"is-null","is-null",-356519403,null);FR=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);GR=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);HR=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);Fca=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);
$CLJS.IR=new $CLJS.M(null,"required","required",1807647006);JR=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);Gca=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);Hca=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);KR=new $CLJS.w(null,"CaseClauses","CaseClauses",-1749071354,null);LR=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);MR=new $CLJS.M("string","contains","string/contains",1602423827);
NR=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);OR=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);Ica=new $CLJS.w("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);PR=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);Jca=new $CLJS.w(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);
Kca=new $CLJS.w("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);QR=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);RR=new $CLJS.w("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);SR=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);TR=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);
UR=new $CLJS.w(null,"share","share",1051097594,null);Lca=new $CLJS.w("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);Mca=new $CLJS.w("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);Nca=new $CLJS.w("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);VR=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);Oca=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
WR=new $CLJS.w(null,"FieldOrAggregationReference","FieldOrAggregationReference",85477856,null);YR=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);Pca=new $CLJS.w("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.ZR=new $CLJS.M(null,"collection","collection",-683361892);$R=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);
aS=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);$CLJS.bS=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);Qca=new $CLJS.w("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);cS=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);dS=new $CLJS.w(null,"metric","metric",2049329604,null);eS=new $CLJS.w(null,"concat","concat",-467652465,null);fS=new $CLJS.M(null,"variable","variable",-281346492);
$CLJS.gS=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);Rca=new $CLJS.w(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.hS=new $CLJS.M("date","relative","date/relative",25987732);iS=new $CLJS.M("location","city","location/city",-1746973325);Sca=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);Tca=new $CLJS.w("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);
jS=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);kS=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);lS=new $CLJS.M("number","between","number/between",97700581);Uca=new $CLJS.w("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);mS=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);nS=new $CLJS.w(null,"sqrt","sqrt",370479598,null);
oS=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);pS=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);$CLJS.qS=new $CLJS.M(null,"semantic_type","semantic_type",272485089);Vca=new $CLJS.M(null,"metric-id","metric-id",-686486942);rS=new $CLJS.w(null,"*","*",345799209,null);sS=new $CLJS.w(null,"+","+",-740910886,null);tS=new $CLJS.w(null,"-","-",-471816912,null);
$CLJS.uS=new $CLJS.M(null,"breakout","breakout",-732419050);vS=new $CLJS.w(null,"template-tag","template-tag",1951372565,null);wS=new $CLJS.M(null,"allowed-for","allowed-for",122724334);Wca=new $CLJS.M(null,"question","question",-1411720117);xS=new $CLJS.w(null,"asc","asc",1997386096,null);yS=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);Xca=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);
zS=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);AS=new $CLJS.w(null,"\x3c\x3d","\x3c\x3d",1244895369,null);BS=new $CLJS.w(null,"\x3c","\x3c",993667236,null);CS=new $CLJS.w(null,"\x3e","\x3e",1085014381,null);DS=new $CLJS.w(null,"\x3d","\x3d",-1501502141,null);ES=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.FS=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);
GS=new $CLJS.M("string","ends-with","string/ends-with",302681156);Yca=new $CLJS.w("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);HS=new $CLJS.w(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);Zca=new $CLJS.w(null,"MetricID","MetricID",-2128635641,null);IS=new $CLJS.w(null,"and","and",668631710,null);JS=new $CLJS.w(null,"\x3e\x3d","\x3e\x3d",1016916022,null);KS=new $CLJS.w(null,"round","round",-645002441,null);
$ca=new $CLJS.M(null,"to","to",192099007);$CLJS.LS=new $CLJS.M("date","single","date/single",1554682003);ada=new $CLJS.M(null,"action-id","action-id",-1727958578);bda=new $CLJS.w("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);MS=new $CLJS.w(null,"exp","exp",1378825265,null);NS=new $CLJS.w(null,"Filter","Filter",-424893332,null);OS=new $CLJS.w(null,"cum-count","cum-count",-323900016,null);cda=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);
PS=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);QS=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);RS=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);SS=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.TS=new $CLJS.M(null,"source-table","source-table",-225307692);dda=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);
eda=new $CLJS.w("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);US=new $CLJS.w(null,"floor","floor",-772394748,null);fda=new $CLJS.M(null,"middleware","middleware",1462115504);gda=new $CLJS.w("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);VS=new $CLJS.M(null,"requires-features","requires-features",-101116256);WS=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);
XS=new $CLJS.M(null,"clause-name","clause-name",-996419059);YS=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);ZS=new $CLJS.w(null,"now","now",-9994004,null);hda=new $CLJS.w("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);$S=new $CLJS.w(null,"not","not",1044554643,null);aT=new $CLJS.w(null,"avg","avg",1837937727,null);bT=new $CLJS.w(null,"sum-where","sum-where",-519087341,null);
cT=new $CLJS.M(null,"max-results","max-results",-32858165);ida=new $CLJS.w("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);dT=new $CLJS.w(null,"case","case",-1510733573,null);eT=new $CLJS.w(null,"distinct","distinct",-148347594,null);fT=new $CLJS.w(null,"get-second","get-second",-425414791,null);gT=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.hT=new $CLJS.M(null,"join-alias","join-alias",1454206794);
iT=new $CLJS.w(null,"is-empty","is-empty",600228619,null);jda=new $CLJS.w("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);jT=new $CLJS.w(null,"relative-datetime","relative-datetime",-1369266491,null);kT=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);kda=new $CLJS.M(null,"original","original",-445386197);lT=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);mT=new $CLJS.w(null,"abs","abs",1394505050,null);
nT=new $CLJS.w(null,"datetime-diff","datetime-diff",-1521323614,null);lda=new $CLJS.w("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);oT=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);mda=new $CLJS.w(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.pT=new $CLJS.M(null,"date","date",-1463434462);qT=new $CLJS.M(null,"second-clause","second-clause",-461435645);
nda=new $CLJS.w("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);rT=new $CLJS.w(null,"rtrim","rtrim",979195078,null);sT=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);tT=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);uT=new $CLJS.w("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);
vT=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);wT=new $CLJS.w(null,"CaseOptions","CaseOptions",1989286806,null);xT=new $CLJS.w(null,"or","or",1876275696,null);oda=new $CLJS.w("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);pda=new $CLJS.M(null,"constraints","constraints",422775616);qda=new $CLJS.w("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);
yT=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);rda=new $CLJS.M(null,"csv-download","csv-download",2141432084);zT=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);AT=new $CLJS.w(null,"datetime-add","datetime-add",1850134938,null);sda=new $CLJS.w("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);
tda=new $CLJS.w("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);BT=new $CLJS.w("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);CT=new $CLJS.w("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);DT=new $CLJS.w("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);uda=new $CLJS.w("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);
ET=new $CLJS.w(null,"get-quarter","get-quarter",-1326657176,null);vda=new $CLJS.w("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);FT=new $CLJS.w("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);wda=new $CLJS.w("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);xda=new $CLJS.w("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);GT=new $CLJS.w(null,"temporal-extract","temporal-extract",-872749364,null);
yda=new $CLJS.w("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);zda=new $CLJS.w("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);Ada=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.HT=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);$CLJS.IT=new $CLJS.M(null,"database","database",1849087575);JT=new $CLJS.w(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);
KT=new $CLJS.w(null,"get-day-of-week","get-day-of-week",-644295017,null);Bda=new $CLJS.w("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);LT=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);MT=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.NT=new $CLJS.M(null,"expressions","expressions",255689909);
OT=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);Cda=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);PT=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);Dda=new $CLJS.w("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);QT=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);
RT=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);ST=new $CLJS.w(null,"absolute-datetime","absolute-datetime",1080191062,null);Eda=new $CLJS.w(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);Fda=new $CLJS.M(null,"action","action",-811238024);TT=new $CLJS.w(null,"get-day","get-day",1768100384,null);UT=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);
VT=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);WT=new $CLJS.w(null,"not-empty","not-empty",2029453590,null);$CLJS.XT=new $CLJS.M(null,"native","native",-613060878);Gda=new $CLJS.w("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);YT=new $CLJS.M(null,"page","page",849072397);ZT=new $CLJS.w(null,"length","length",-2065447907,null);Hda=new $CLJS.M(null,"dashboard","dashboard",-631747508);
$T=new $CLJS.w(null,"get-week","get-week",752472178,null);aU=new $CLJS.w(null,"get-month","get-month",1271156796,null);bU=new $CLJS.w(null,"dimension","dimension",-2111181571,null);cU=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);dU=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);eU=new $CLJS.w(null,"StringFilterOptions","StringFilterOptions",-847398229,null);
fU=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);gU=new $CLJS.w("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);Ida=new $CLJS.w("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);hU=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);iU=new $CLJS.w(null,"substring","substring",-1513569493,null);Jda=new $CLJS.w("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);
jU=new $CLJS.M(null,"internal","internal",-854870097);Kda=new $CLJS.w("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);kU=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);lU=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);mU=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);
Lda=new $CLJS.w("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);nU=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);oU=new $CLJS.w(null,"ltrim","ltrim",-1000166486,null);Mda=new $CLJS.w("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);Nda=new $CLJS.w("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);
pU=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);qU=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);rU=new $CLJS.w("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);Oda=new $CLJS.w("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);
Pda=new $CLJS.w("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);sU=new $CLJS.w(null,"desc","desc",-560950005,null);$CLJS.tU=new $CLJS.M(null,"template-tags","template-tags",1853115685);Qda=new $CLJS.M(null,"public-question","public-question",629369976);$CLJS.uU=new $CLJS.M(null,"fk-field-id","fk-field-id",1124127357);Rda=new $CLJS.w("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
Sda=new $CLJS.M(null,"binary","binary",-1802232288);vU=new $CLJS.w(null,"time-interval","time-interval",-1949813754,null);Tda=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.wU=new $CLJS.M(null,"source-query","source-query",198004422);$CLJS.xU=new $CLJS.M(null,"settings","settings",1556144875);yU=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);Uda=new $CLJS.w(null,"SegmentID","SegmentID",-1773652704,null);
Vda=new $CLJS.w("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);zU=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);AU=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);Wda=new $CLJS.M(null,"executed-by","executed-by",-739811161);Xda=new $CLJS.w("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
Yda=new $CLJS.w("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);BU=new $CLJS.M(null,"amount","amount",364489504);CU=new $CLJS.w(null,"percentile","percentile",1039342775,null);DU=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);EU=new $CLJS.w(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);FU=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
GU=new $CLJS.w(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);HU=new $CLJS.w(null,"trim","trim",-1880116002,null);Zda=new $CLJS.w(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);IU=new $CLJS.w(null,"coalesce","coalesce",-1999813740,null);JU=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);$da=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
KU=new $CLJS.M("string","\x3d","string/\x3d",983744235);aea=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);bea=new $CLJS.w("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);cea=new $CLJS.w("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);LU=new $CLJS.w(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);MU=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
NU=new $CLJS.w(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);dea=new $CLJS.M(null,"lat-min","lat-min",1630784161);OU=new $CLJS.w(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);PU=new $CLJS.w(null,"inside","inside",-681932758,null);eea=new $CLJS.w("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);QU=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
fea=new $CLJS.w("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);RU=new $CLJS.w(null,"starts-with","starts-with",2007034536,null);gea=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);hea=new $CLJS.w("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.SU=new $CLJS.M(null,"card-id","card-id",-1770060179);TU=new $CLJS.M(null,"variadic","variadic",882626057);
$CLJS.UU=new $CLJS.M(null,"category","category",-593092832);VU=new $CLJS.w(null,"upper","upper",1886775433,null);WU=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);XU=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);YU=new $CLJS.w(null,"optional","optional",-600484260,null);iea=new $CLJS.w("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);
ZU=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);$U=new $CLJS.M(null,"sugar","sugar",-73788488);jea=new $CLJS.M(null,"lat-max","lat-max",841568226);aV=new $CLJS.w(null,"power","power",702679448,null);bV=new $CLJS.w("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);cV=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);
dV=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);eV=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);fV=new $CLJS.w(null,"median","median",-2084869638,null);gV=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);hV=new $CLJS.w(null,"cum-sum","cum-sum",-589533420,null);
kea=new $CLJS.w("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);iV=new $CLJS.M(null,"y","y",-1757859776);jV=new $CLJS.w(null,"aggregation","aggregation",-1056959073,null);$CLJS.kV=new $CLJS.M(null,"binning","binning",1709835866);lV=new $CLJS.w(null,"TimezoneId","TimezoneId",-1406242397,null);mV=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);nV=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);
oV=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);pV=new $CLJS.M(null,"b","b",1482224470);qV=new $CLJS.M(null,"a","a",-2123407586);rV=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);sV=new $CLJS.w(null,"replace","replace",853943757,null);tV=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);lea=new $CLJS.w("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);
uV=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);vV=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);wV=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);xV=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);yV=new $CLJS.w(null,"segment","segment",675610331,null);mea=new $CLJS.w("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);
zV=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);AV=new $CLJS.w("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);BV=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);CV=new $CLJS.M("string","starts-with","string/starts-with",1266861170);nea=new $CLJS.w(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.DV=new $CLJS.M(null,"order-by","order-by",1527318070);
EV=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);oea=new $CLJS.w("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);pea=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.FV=new $CLJS.M(null,"condition","condition",1668437652);GV=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);qea=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);
HV=new $CLJS.w("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);rea=new $CLJS.M(null,"card-name","card-name",-2035606807);IV=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);sea=new $CLJS.w("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);tea=new $CLJS.w("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);JV=new $CLJS.w(null,"log","log",45015523,null);
uea=new $CLJS.w("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);KV=new $CLJS.w(null,"time","time",-1268547887,null);$CLJS.LV=new $CLJS.M(null,"database_type","database_type",-54700895);MV=new $CLJS.w(null,"EqualityComparable","EqualityComparable",-2076258858,null);NV=new $CLJS.w(null,"get-minute","get-minute",-1186349426,null);$CLJS.OV=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.PV=new $CLJS.M(null,"expression-name","expression-name",-1798450709);
$CLJS.QV=new $CLJS.M("date","all-options","date/all-options",549325958);vea=new $CLJS.w("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);RV=new $CLJS.M("location","state","location/state",-114378652);SV=new $CLJS.w(null,"datetime-subtract","datetime-subtract",1915839224,null);wea=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);xea=new $CLJS.M(null,"lon-field","lon-field",517872067);
TV=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);UV=new $CLJS.w(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);VV=new $CLJS.M(null,"numeric","numeric",-1495594714);WV=new $CLJS.w(null,"variable","variable",1359185035,null);XV=new $CLJS.w(null,"lower","lower",-1534114948,null);YV=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);
ZV=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.$V=new $CLJS.M(null,"limit","limit",-1355822363);aW=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);bW=new $CLJS.w(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);yea=new $CLJS.M(null,"pulse","pulse",-244494476);var cW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vI],null),dW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EI],null),eW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.sI],null),fW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mI],null),gW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.zI],null),hW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.DI],null),zea=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZO],null),Aea=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XO],null),iW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.OM],null),jW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.SM],null),kW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KM],null),lW=new $CLJS.dh(null,new $CLJS.k(null,12,[$CLJS.ti,null,$CLJS.Gv,null,$CLJS.nj,null,$CLJS.gi,null,$CLJS.Ji,null,$CLJS.wj,null,$CLJS.ik,null,$CLJS.Fj,null,$CLJS.ni,null,$CLJS.Ej,null,$CLJS.ui,null,$CLJS.Ei,null],null),null),mW=new $CLJS.dh(null,new $CLJS.k(null,7,[$CLJS.Fv,null,$CLJS.nL,null,$CLJS.gi,null,$CLJS.Ai,null,$CLJS.qL,null,$CLJS.hj,null,$CLJS.Ev,null],null),null),
nW=$CLJS.Hv.g(lW,mW),Bea=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"date bucketing unit"],null)],null),lW),Cea=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"time bucketing unit"],null)],null),mW),oW=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"datetime bucketing unit"],null)],null),nW),pW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xN],null),Dea=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,
1,[$CLJS.Au,"temporal extract unit"],null),$CLJS.rL,$CLJS.Ei,$CLJS.ik,$CLJS.iN,$CLJS.DN,$CLJS.EN,$CLJS.Fj,$CLJS.Ji,$CLJS.Ai,$CLJS.hj,$CLJS.oL],null),Eea=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"datetime-diff unit"],null),$CLJS.nL,$CLJS.Ev,$CLJS.Fv,$CLJS.Gv,$CLJS.nj,$CLJS.wj,$CLJS.ti,$CLJS.ni],null),qW=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"temporal-extract week extraction mode"],null),$CLJS.cN,$CLJS.lN,$CLJS.qN],null),rW=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"relative-datetime unit"],null),$CLJS.gi,$CLJS.Ev,$CLJS.Fv,$CLJS.Gv,$CLJS.nj,$CLJS.wj,$CLJS.ti,$CLJS.ni],null),yW,Gea,GW,HW,IW,JW,KW,LW,MW,xY,Iea,yY,Jea,Kea,zY,Lea,Mea,Nea;$CLJS.X(CQ,KP($CLJS.mN,$CLJS.G(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.Lx],null),$CLJS.yt],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,rW],null)])));var sW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CQ],null);
$CLJS.X(lT,KP($CLJS.kN,$CLJS.G(["n",$CLJS.yt,"unit",rW])));var Fea=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lT],null);
$CLJS.X(YQ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Au,"valid :absolute-datetime clause",$CLJS.Qi,function(a){if($CLJS.Ta(JP($CLJS.gN,a)))a=XQ;else{a=$CLJS.jd(a);var b=$CLJS.FP(iW);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.pT:$CLJS.nN}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[XQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"not an :absolute-datetime clause"],null),$CLJS.Pe(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pT,KP($CLJS.gN,
$CLJS.G(["date",iW,"unit",Bea]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nN,KP($CLJS.gN,$CLJS.G(["datetime",jW,"unit",oW]))],null)],null));var tW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YQ],null);$CLJS.X(nR,KP($CLJS.pC,$CLJS.G(["time",kW,"unit",Cea])));var uW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nR],null),vW=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.lt,new $CLJS.k(null,1,[$CLJS.Au,"date or datetime literal"],null),tW,jW,iW],null);
$CLJS.X(WP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lt,new $CLJS.k(null,1,[$CLJS.Au,"time literal"],null),uW,kW],null));$CLJS.X(TV,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lt,new $CLJS.k(null,1,[$CLJS.Au,"temporal literal"],null),vW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WP],null)],null));var wW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TV],null);
$CLJS.X($da,LP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof tW?new $CLJS.Gc(function(){return tW},$CLJS.qd(Aca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,jU,$CLJS.mj,$CLJS.rk,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ST,"metabase/mbql/schema.cljc",69,$CLJS.gN,1,!0,168,168,$CLJS.Kc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(tW)?tW.H:null])):null));return $CLJS.n(a)?a:ST}(),tW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof sW?new $CLJS.Gc(function(){return sW},$CLJS.qd(vea,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,
$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jT,"metabase/mbql/schema.cljc",29,$CLJS.mN,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mN,$CLJS.bz,$CLJS.V($CLJS.Oj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.Lx],null),$CLJS.yt],null)),$CLJS.wi,$CLJS.V($CLJS.Oj,$CLJS.V(YU,HS))],null),$CLJS.Kc,"Schema for a valid relative-datetime clause.",
$CLJS.n(sW)?sW.H:null])):null));return $CLJS.n(a)?a:jT}(),sW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof uW?new $CLJS.Gc(function(){return uW},$CLJS.qd(xda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,jU,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KV,"metabase/mbql/schema.cljc",27,$CLJS.pC,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.pC,$CLJS.pC,$CLJS.V($CLJS.Oj,Zda),$CLJS.wi,$CLJS.V($CLJS.Oj,Rca)],null),$CLJS.Kc,"Schema for a valid time clause.",$CLJS.n(uW)?uW.H:null])):null));return $CLJS.n(a)?a:KV}(),uW],null)])));
$CLJS.X(IV,KP($CLJS.Cj,$CLJS.G(["value",$CLJS.Dt,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LV,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,cW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SG,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,dW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qS,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.kt,eW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wi,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,oW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,cW],null)],null)],null)],null)])));var xW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IV],null);$CLJS.X(pS,KP($CLJS.zE,$CLJS.G(["expression-name",cW,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,$CLJS.fk],null)])));
yW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pS],null);
Gea=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.Ge(b);b=$CLJS.J.g(c,$CLJS.GI);c=$CLJS.J.g(c,$CLJS.oI);return $CLJS.F.g(b,$CLJS.oI)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.Ge(b);b=$CLJS.J.g(c,$CLJS.GI);c=$CLJS.J.g(c,$CLJS.FI);return $CLJS.F.g(b,$CLJS.FI)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fk,new $CLJS.k(null,1,[$CLJS.Au,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GI,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"binning strategy"],null),$CLJS.oI,$CLJS.FI,$CLJS.gi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oI,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),fW],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.FI,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,$CLJS.sl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"bin width must be \x3e\x3d 0."],null),$CLJS.Oe($CLJS.Nl)],null)],null)],null)],null)));$CLJS.zW=function zW(a){switch(arguments.length){case 1:return zW.h(arguments[0]);case 2:return zW.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.zW.h=function(a){var b=$CLJS.Ge(a);a=$CLJS.J.g(b,$CLJS.CE);b=$CLJS.J.g(b,$CLJS.rK);return $CLJS.zW.g(a,b)};$CLJS.zW.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.QF(a,$CLJS.wG)?lW:$CLJS.QF(a,$CLJS.UH)?mW:$CLJS.QF(a,$CLJS.eH)?nW:null:null;return $CLJS.n(a)?$CLJS.Id(a,b):!0};$CLJS.zW.A=2;
$CLJS.X(ZU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.fk,new $CLJS.k(null,1,[$CLJS.Au,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,dW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pR,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,hW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rK,new $CLJS.k(null,
1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,oW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hT,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,cW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kV,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,Gea],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.zW],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Oe($CLJS.GI)],null)],null));
$CLJS.X(zV,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.Ge(b);b=$CLJS.J.g(b,$CLJS.CE);return"string"===typeof c?b:!0}],null)],null)}(KP($CLJS.lK,$CLJS.G(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,hW,cW],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZU],null)],null)]))));var AW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zV],null);
$CLJS.X(yT,LP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof yW?new $CLJS.Gc(function(){return yW},$CLJS.qd(zR,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iR,"metabase/mbql/schema.cljc",60,$CLJS.zE,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zE,$CLJS.PV,$CLJS.V($CLJS.Oj,EQ),$CLJS.ej,$CLJS.V($CLJS.Oj,$CLJS.V(YU,$CLJS.fk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(yW)?yW.H:null])):null));return $CLJS.n(a)?a:iR}(),yW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof AW?new $CLJS.Gc(function(){return AW},
$CLJS.qd(RR,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Nr,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$CLJS.yi,$CLJS.T,$CLJS.jk],["0.39.0",$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QQ,"metabase/mbql/schema.cljc",51,$CLJS.lK,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(AW)?AW.H:null])):null));return $CLJS.n(a)?a:QQ}(),AW],null)])));
$CLJS.BW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yT],null);$CLJS.X(QP,KP($CLJS.aK,$CLJS.G(["aggregation-clause-index",$CLJS.yt,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,$CLJS.fk],null)])));var CW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QP],null);
$CLJS.X(kR,LP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof CW?new $CLJS.Gc(function(){return CW},$CLJS.qd(Xda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),jV,"metabase/mbql/schema.cljc",23,$CLJS.aK,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.aK,wea,$CLJS.V($CLJS.Oj,$CLJS.yt),$CLJS.ej,$CLJS.V($CLJS.Oj,$CLJS.V(YU,$CLJS.fk))],null),$CLJS.Kc,"Schema for a valid aggregation clause.",$CLJS.n(CW)?CW.H:null])):null));return $CLJS.n(a)?a:jV}(),CW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof yW?new $CLJS.Gc(function(){return yW},
$CLJS.qd(zR,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iR,"metabase/mbql/schema.cljc",60,$CLJS.zE,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zE,$CLJS.PV,$CLJS.V($CLJS.Oj,EQ),$CLJS.ej,
$CLJS.V($CLJS.Oj,$CLJS.V(YU,$CLJS.fk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(yW)?yW.H:null])):null));return $CLJS.n(a)?a:iR}(),yW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof AW?new $CLJS.Gc(function(){return AW},$CLJS.qd(RR,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Nr,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$CLJS.yi,
$CLJS.T,$CLJS.jk],["0.39.0",$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QQ,"metabase/mbql/schema.cljc",51,$CLJS.lK,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(AW)?AW.H:null])):null));return $CLJS.n(a)?a:QQ}(),AW],null)])));
var DW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kR],null),EW=new $CLJS.dh(null,new $CLJS.k(null,11,[$CLJS.Qu,null,$CLJS.FK,null,$CLJS.IJ,null,$CLJS.KK,null,$CLJS.NJ,null,$CLJS.oK,null,$CLJS.WJ,null,$CLJS.ev,null,$CLJS.DK,null,$CLJS.nK,null,$CLJS.LK,null],null),null),FW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wQ],null);
$CLJS.X(ZP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,1,[$CLJS.Qi,function(a){return"string"===typeof a?$CLJS.Xj:$CLJS.n(JP(EW,a))?sQ:$CLJS.n(JP($CLJS.Cj,a))?$CLJS.Cj:$CLJS.Vu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xj,$CLJS.Xj],null),new $CLJS.P(null,2,5,$CLJS.Q,[sQ,FW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,xW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vu,$CLJS.BW],null)],null));GW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZP],null);
HW=new $CLJS.dh(null,new $CLJS.k(null,26,[$CLJS.KJ,null,$CLJS.CN,null,$CLJS.Ps,null,$CLJS.yN,null,$CLJS.vN,null,$CLJS.$J,null,$CLJS.qu,null,$CLJS.HJ,null,$CLJS.zN,null,$CLJS.sN,null,$CLJS.oK,null,$CLJS.HK,null,$CLJS.wN,null,$CLJS.qK,null,$CLJS.LJ,null,$CLJS.eN,null,$CLJS.Os,null,$CLJS.OJ,null,$CLJS.WJ,null,$CLJS.ZM,null,$CLJS.KA,null,$CLJS.fN,null,$CLJS.rN,null,$CLJS.jN,null,$CLJS.JK,null,$CLJS.jK,null],null),null);
IW=new $CLJS.dh(null,new $CLJS.k(null,9,[$CLJS.Ks,null,$CLJS.Es,null,$CLJS.lt,null,$CLJS.nt,null,$CLJS.Gs,null,$CLJS.EK,null,$CLJS.st,null,$CLJS.Ls,null,$CLJS.Is,null],null),null);JW=new $CLJS.dh(null,new $CLJS.k(null,17,[$CLJS.On,null,$CLJS.CK,null,$CLJS.uK,null,$CLJS.mK,null,$CLJS.GK,null,$CLJS.IK,null,$CLJS.SJ,null,$CLJS.YJ,null,$CLJS.dQ,null,$CLJS.TJ,null,$CLJS.zK,null,$CLJS.sK,null,$CLJS.gk,null,$CLJS.TA,null,$CLJS.fK,null,$CLJS.gK,null,$CLJS.yK,null],null),null);
KW=new $CLJS.dh(null,new $CLJS.k(null,5,[$CLJS.oN,null,$CLJS.aN,null,$CLJS.AN,null,$CLJS.Os,null,$CLJS.uN,null],null),null);LW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QU],null);MW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lR],null);$CLJS.NW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pU],null);$CLJS.OW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XU],null);
$CLJS.X(dV,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Au,"numeric expression argument",$CLJS.Qi,function(a){return"number"===typeof a?$CLJS.vv:$CLJS.n(JP(HW,a))?SS:$CLJS.n(JP(JW,a))?$CLJS.aK:$CLJS.n(JP($CLJS.Cj,a))?$CLJS.Cj:$CLJS.lK}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vv,$CLJS.sl],null),new $CLJS.P(null,2,5,$CLJS.Q,[SS,LW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aK,$CLJS.OW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,xW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lK,
$CLJS.BW],null)],null));var PW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dV],null);
$CLJS.X(kU,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Au,"datetime expression argument",$CLJS.Qi,function(a){return $CLJS.n(JP(JW,a))?$CLJS.aK:$CLJS.n(JP($CLJS.Cj,a))?$CLJS.Cj:$CLJS.n(JP(KW,a))?sR:$CLJS.Vu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aK,$CLJS.OW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,xW],null),new $CLJS.P(null,2,5,$CLJS.Q,[sR,$CLJS.NW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vu,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,vW,$CLJS.BW],null)],null)],null));
var QW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kU],null);
$CLJS.X(OT,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Au,"expression argument",$CLJS.Qi,function(a){return"number"===typeof a?$CLJS.vv:$CLJS.Dd(a)?$CLJS.Ct:$CLJS.n(JP(IW,a))?cU:$CLJS.n(JP(HW,a))?SS:$CLJS.n(JP(KW,a))?sR:"string"===typeof a?$CLJS.Xj:$CLJS.n(JP(EW,a))?sQ:$CLJS.n(JP($CLJS.Cj,a))?$CLJS.Cj:$CLJS.Vu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vv,$CLJS.sl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ct,$CLJS.Ct],null),new $CLJS.P(null,2,5,$CLJS.Q,[cU,MW],null),new $CLJS.P(null,
2,5,$CLJS.Q,[SS,LW],null),new $CLJS.P(null,2,5,$CLJS.Q,[sR,$CLJS.NW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xj,$CLJS.Xj],null),new $CLJS.P(null,2,5,$CLJS.Q,[sQ,FW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,xW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vu,$CLJS.BW],null)],null));var RW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OT],null);$CLJS.X(MT,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lt,new $CLJS.k(null,1,[$CLJS.Au,"numeric expression arg or interval"],null),Fea,PW],null));
var SW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MT],null);$CLJS.X(jQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Au,"int greater than zero or numeric expression",$CLJS.Qi,function(a){return"number"===typeof a?$CLJS.vv:$CLJS.Vu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vv,fW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vu,LW],null)],null));var Hea=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jQ],null);
$CLJS.X(tR,KP($CLJS.oK,$CLJS.G(["a",RW,"b",RW,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,RW],null)])));var TW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tR],null);$CLJS.X(VP,KP($CLJS.IJ,$CLJS.G(["s",GW,"start",Hea,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,PW],null)])));var UW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VP],null);$CLJS.X(dU,KP($CLJS.KA,$CLJS.G(["s",GW])));var VW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dU],null);$CLJS.X(yQ,KP($CLJS.DK,$CLJS.G(["s",GW])));
var WW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yQ],null);$CLJS.X(BQ,KP($CLJS.LK,$CLJS.G(["s",GW])));var XW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BQ],null);$CLJS.X(VQ,KP($CLJS.FK,$CLJS.G(["s",GW])));var YW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VQ],null);$CLJS.X(HR,KP($CLJS.Qu,$CLJS.G(["s",GW])));var ZW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HR],null);$CLJS.X(oQ,KP($CLJS.ev,$CLJS.G(["s",GW])));var $W=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oQ],null);
$CLJS.X(cV,KP($CLJS.KK,$CLJS.G(["s",GW,"match",$CLJS.Xj,"replacement",$CLJS.Xj])));var aX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cV],null);$CLJS.X(YV,KP($CLJS.NJ,$CLJS.G(["a",GW,"b",GW,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,GW],null)])));var bX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YV],null);$CLJS.X(zS,KP($CLJS.nK,$CLJS.G(["s",GW,"pattern",$CLJS.Xj])));var cX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zS],null);
$CLJS.X(xV,KP($CLJS.Os,$CLJS.G(["x",SW,"y",SW,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,SW],null)])));var dX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xV],null);$CLJS.X(vV,KP($CLJS.qu,$CLJS.G(["x",PW,"y",SW,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,SW],null)])));var eX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vV],null);$CLJS.X(uV,KP($CLJS.HK,$CLJS.G(["x",PW,"y",PW,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,PW],null)])));var fX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uV],null);
$CLJS.X(wV,KP($CLJS.Ps,$CLJS.G(["x",PW,"y",PW,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,PW],null)])));var gX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wV],null);$CLJS.X(LT,KP($CLJS.jK,$CLJS.G(["x",PW])));var hX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LT],null);$CLJS.X(FU,KP($CLJS.KJ,$CLJS.G(["x",PW])));var iX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FU],null);$CLJS.X(aW,KP($CLJS.qK,$CLJS.G(["x",PW])));var jX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aW],null);$CLJS.X(QS,KP($CLJS.OJ,$CLJS.G(["x",PW])));
var kX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QS],null);$CLJS.X(WS,KP($CLJS.LJ,$CLJS.G(["x",PW,"y",PW])));var lX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WS],null);$CLJS.X(sT,KP($CLJS.$J,$CLJS.G(["x",PW])));var mX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sT],null);$CLJS.X(kQ,KP($CLJS.HJ,$CLJS.G(["x",PW])));var nX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kQ],null);$CLJS.X(RT,KP($CLJS.JK,$CLJS.G(["x",PW])));var oX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RT],null);
$CLJS.X(KQ,KP($CLJS.jN,$CLJS.G(["datetime-x",QW,"datetime-y",QW,"unit",Eea])));var pX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KQ],null);$CLJS.X(cR,KP($CLJS.yN,$CLJS.G(["datetime",QW,"unit",Dea,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,qW],null)])));var qX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cR],null);$CLJS.X(YS,KP($CLJS.fN,$CLJS.G(["date",QW])));var rX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YS],null);$CLJS.X(zT,KP($CLJS.CN,$CLJS.G(["date",QW])));
var sX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zT],null);$CLJS.X(XP,KP($CLJS.ZM,$CLJS.G(["date",QW])));var tX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XP],null);$CLJS.X(ZV,KP($CLJS.sN,$CLJS.G(["date",QW,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,qW],null)])));var uX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZV],null);$CLJS.X(jS,KP($CLJS.rN,$CLJS.G(["date",QW])));var vX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jS],null);$CLJS.X(tT,KP($CLJS.wN,$CLJS.G(["date",QW])));
var wX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tT],null);$CLJS.X(GQ,KP($CLJS.zN,$CLJS.G(["datetime",QW])));var xX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GQ],null);$CLJS.X(oS,KP($CLJS.vN,$CLJS.G(["datetime",QW])));var yX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oS],null);$CLJS.X(zQ,KP($CLJS.eN,$CLJS.G(["datetime",QW])));var zX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zQ],null);$CLJS.X(SR,KP($CLJS.aN,$CLJS.G(["datetime",QW,"to",pW,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,pW],null)])));
var AX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SR],null),BX=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"datetime arithmetic unit"],null),$CLJS.qL,$CLJS.nL,$CLJS.Ev,$CLJS.Fv,$CLJS.Gv,$CLJS.nj,$CLJS.wj,$CLJS.ti,$CLJS.ni],null);$CLJS.X(UP,KP($CLJS.uN,$CLJS.G(["datetime",QW,"amount",PW,"unit",BX])));var CX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UP],null);
$CLJS.X(tQ,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return KP(arguments[0],1<b.length?new $CLJS.y(b.slice(1),0,null):null)}($CLJS.oN));var DX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tQ],null);$CLJS.X(TP,KP($CLJS.AN,$CLJS.G(["datetime",QW,"amount",PW,"unit",BX])));var EX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TP],null);
$CLJS.X(pU,LP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof dX?new $CLJS.Gc(function(){return dX},$CLJS.qd(FT,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),sS,"metabase/mbql/schema.cljc",51,$CLJS.Os,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Os,$CLJS.CI,$CLJS.V($CLJS.Oj,JT),iV,$CLJS.V($CLJS.Oj,JT),uR,$CLJS.V($CLJS.Oj,$CLJS.V($CLJS.Oi,JT))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(dX)?dX.H:null])):null));return $CLJS.n(a)?a:sS}(),dX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof CX?new $CLJS.Gc(function(){return CX},
$CLJS.qd(hda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[LR,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AT,"metabase/mbql/schema.cljc",67,$CLJS.uN,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.uN,$CLJS.nN,$CLJS.V($CLJS.Oj,UV),BU,$CLJS.V($CLJS.Oj,
GU),$CLJS.wi,$CLJS.V($CLJS.Oj,OU)],null),$CLJS.Kc,"Schema for a valid datetime-add clause.",$CLJS.n(CX)?CX.H:null])):null));return $CLJS.n(a)?a:AT}(),CX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof EX?new $CLJS.Gc(function(){return EX},$CLJS.qd(bca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,
$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[LR,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SV,"metabase/mbql/schema.cljc",72,$CLJS.AN,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.AN,$CLJS.nN,$CLJS.V($CLJS.Oj,UV),BU,$CLJS.V($CLJS.Oj,GU),$CLJS.wi,$CLJS.V($CLJS.Oj,OU)],null),$CLJS.Kc,"Schema for a valid datetime-subtract clause.",$CLJS.n(EX)?EX.H:null])):null));
return $CLJS.n(a)?a:SV}(),EX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof AX?new $CLJS.Gc(function(){return AX},$CLJS.qd(fea,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.aN,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rR,"metabase/mbql/schema.cljc",71,$CLJS.aN,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.aN,$CLJS.nN,$CLJS.V($CLJS.Oj,UV),$ca,$CLJS.V($CLJS.Oj,lV),fca,$CLJS.V($CLJS.Oj,$CLJS.V(YU,lV))],null),$CLJS.Kc,"Schema for a valid convert-timezone clause.",$CLJS.n(AX)?AX.H:null])):null));return $CLJS.n(a)?a:rR}(),AX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&
"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof DX?new $CLJS.Gc(function(){return DX},$CLJS.qd(Ida,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.oN,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZS,"metabase/mbql/schema.cljc",
45,$CLJS.oN,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.oN],null),$CLJS.Kc,"Schema for a valid now clause.",$CLJS.n(DX)?DX.H:null])):null));return $CLJS.n(a)?a:ZS}(),DX],null)])));$CLJS.FX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vT],null);$CLJS.X($P,KP($CLJS.st,$CLJS.G(["first-clause",$CLJS.FX,"second-clause",$CLJS.FX,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,$CLJS.FX],null)])));var GX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$P],null);
$CLJS.X(OR,KP($CLJS.lt,$CLJS.G(["first-clause",$CLJS.FX,"second-clause",$CLJS.FX,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,$CLJS.FX],null)])));var HX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OR],null);$CLJS.X(vQ,KP($CLJS.nt,$CLJS.G(["clause",$CLJS.FX])));
var IX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vQ],null),JX=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,3,[$CLJS.Au,":field or :expression reference or :relative-datetime",$CLJS.Cu,$CLJS.Pe(":field or :expression reference or :relative-datetime"),$CLJS.Qi,function(a){return $CLJS.n(JP($CLJS.mN,a))?$CLJS.mN:$CLJS.Vu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mN,sW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vu,$CLJS.BW],null)],null);
$CLJS.X(mU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.k(null,1,[$CLJS.Au,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.lt,$CLJS.Ct,$CLJS.sl,$CLJS.Xj,wW,JX,RW,xW],null)],null));var KX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mU],null);
$CLJS.X(ES,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Au,"order comparable",$CLJS.Qi,function(a){return $CLJS.n(JP($CLJS.Cj,a))?$CLJS.Cj:$CLJS.Vu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,xW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vu,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.lt,$CLJS.sl,$CLJS.Xj,wW,RW,JX],null)],null)],null));var LX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ES],null);
$CLJS.X(oV,KP($CLJS.Ls,$CLJS.G(["field",KX,"value-or-field",KX,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,KX],null)])));var MX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oV],null);$CLJS.X(rV,KP($CLJS.EK,$CLJS.G(["field",KX,"value-or-field",KX,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,KX],null)])));var NX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rV],null);$CLJS.X(nV,KP($CLJS.Is,$CLJS.G(["field",LX,"value-or-field",LX])));
var OX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nV],null);$CLJS.X(mV,KP($CLJS.Es,$CLJS.G(["field",LX,"value-or-field",LX])));var PX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mV],null);$CLJS.X(WQ,KP($CLJS.Ks,$CLJS.G(["field",LX,"value-or-field",LX])));var QX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WQ],null);$CLJS.X(bR,KP($CLJS.Gs,$CLJS.G(["field",LX,"value-or-field",LX])));var RX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bR],null);$CLJS.X(VT,KP($CLJS.QJ,$CLJS.G(["field",LX,"min",LX,"max",LX])));
var SX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VT],null);$CLJS.X(yU,KP($CLJS.VJ,$CLJS.G(["lat-field",LX,"lon-field",LX,"lat-max",LX,"lon-min",LX,"lat-min",LX,"lon-max",LX])));var TX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yU],null);$CLJS.X(GR,KP($CLJS.PJ,$CLJS.G(["field",$CLJS.BW])));var UX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GR],null);$CLJS.X(yS,KP($CLJS.ZJ,$CLJS.G(["field",$CLJS.BW])));var VX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yS],null);$CLJS.X($R,KP($CLJS.RJ,$CLJS.G(["field",$CLJS.BW])));
var WX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$R],null);$CLJS.X(cQ,KP($CLJS.XJ,$CLJS.G(["field",$CLJS.BW])));var XX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cQ],null),YX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tK,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Ct],null)],null);$CLJS.X(mS,KP($CLJS.cK,$CLJS.G(["field",GW,"string-or-field",GW,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,YX],null)])));var ZX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mS],null);
$CLJS.X(gT,KP($CLJS.wK,$CLJS.G(["field",GW,"string-or-field",GW,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,YX],null)])));var $X=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gT],null);$CLJS.X(YR,KP($CLJS.kK,$CLJS.G(["field",GW,"string-or-field",GW,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,YX],null)])));var aY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YR],null);$CLJS.X(PT,KP($CLJS.dK,$CLJS.G(["field",GW,"string-or-field",GW,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,YX],null)])));
var bY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PT],null);$CLJS.X(lQ,KP($CLJS.xK,$CLJS.G(["field",$CLJS.BW,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,$CLJS.yt,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ht,$CLJS.Lx,$CLJS.dB,$CLJS.UJ],null)],null),"unit",rW,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BK,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Ct],null)],null)],null)])));var cY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lQ],null);
$CLJS.X(fU,KP($CLJS.MJ,$CLJS.G(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nI],null),cW],null)])));var dY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fU],null);
$CLJS.X(lR,LP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof GX?new $CLJS.Gc(function(){return GX},$CLJS.qd(bV,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),IS,"metabase/mbql/schema.cljc",15,$CLJS.st,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.st,vR,$CLJS.V($CLJS.Oj,NS),qT,$CLJS.V($CLJS.Oj,NS),FR,$CLJS.V($CLJS.Oj,$CLJS.V($CLJS.Oi,NS))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(GX)?GX.H:null])):null));return $CLJS.n(a)?a:IS}(),GX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof HX?new $CLJS.Gc(function(){return HX},
$CLJS.qd(uT,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xT,"metabase/mbql/schema.cljc",14,$CLJS.lt,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.lt,vR,$CLJS.V($CLJS.Oj,NS),qT,$CLJS.V($CLJS.Oj,NS),FR,$CLJS.V($CLJS.Oj,$CLJS.V($CLJS.Oi,NS))],null),$CLJS.Kc,
"Schema for a valid or clause.",$CLJS.n(HX)?HX.H:null])):null));return $CLJS.n(a)?a:xT}(),HX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof IX?new $CLJS.Gc(function(){return IX},$CLJS.qd(gU,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$S,"metabase/mbql/schema.cljc",15,$CLJS.nt,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nt,$CLJS.OV,$CLJS.V($CLJS.Oj,NS)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(IX)?IX.H:null])):null));return $CLJS.n(a)?a:$S}(),IX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof OX?new $CLJS.Gc(function(){return OX},$CLJS.qd(DT,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,
$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BS,"metabase/mbql/schema.cljc",13,$CLJS.Is,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Is,$CLJS.lK,$CLJS.V($CLJS.Oj,xR),MU,$CLJS.V($CLJS.Oj,xR)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.n(OX)?OX.H:null])):null));return $CLJS.n(a)?a:BS}(),OX],null),
new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof QX?new $CLJS.Gc(function(){return QX},$CLJS.qd(HV,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AS,"metabase/mbql/schema.cljc",14,$CLJS.Ks,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ks,$CLJS.lK,$CLJS.V($CLJS.Oj,xR),MU,$CLJS.V($CLJS.Oj,xR)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(QX)?QX.H:null])):null));return $CLJS.n(a)?a:AS}(),QX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof PX?new $CLJS.Gc(function(){return PX},$CLJS.qd(BT,new $CLJS.k(null,
1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CS,"metabase/mbql/schema.cljc",13,$CLJS.Es,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Es,$CLJS.lK,$CLJS.V($CLJS.Oj,xR),MU,$CLJS.V($CLJS.Oj,xR)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(PX)?PX.H:null])):null));
return $CLJS.n(a)?a:CS}(),PX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof RX?new $CLJS.Gc(function(){return RX},$CLJS.qd(AV,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JS,"metabase/mbql/schema.cljc",14,$CLJS.Gs,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Gs,$CLJS.lK,$CLJS.V($CLJS.Oj,xR),MU,$CLJS.V($CLJS.Oj,xR)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(RX)?RX.H:null])):null));return $CLJS.n(a)?a:JS}(),RX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof MX?new $CLJS.Gc(function(){return MX},$CLJS.qd(CT,new $CLJS.k(null,
1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DS,"metabase/mbql/schema.cljc",13,$CLJS.Ls,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ls,$CLJS.lK,$CLJS.V($CLJS.Oj,MV),MU,$CLJS.V($CLJS.Oj,MV),nU,$CLJS.V($CLJS.Oj,$CLJS.V($CLJS.Oi,MV))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",
$CLJS.n(MX)?MX.H:null])):null));return $CLJS.n(a)?a:DS}(),MX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof NX?new $CLJS.Gc(function(){return NX},$CLJS.qd(LQ,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hR,"metabase/mbql/schema.cljc",14,$CLJS.EK,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.EK,$CLJS.lK,$CLJS.V($CLJS.Oj,MV),MU,$CLJS.V($CLJS.Oj,MV),nU,$CLJS.V($CLJS.Oj,$CLJS.V($CLJS.Oi,MV))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(NX)?NX.H:null])):null));return $CLJS.n(a)?a:hR}(),NX],null)])));
$CLJS.X(vT,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Au,"valid filter expression",$CLJS.Qi,function(a){return $CLJS.n(JP(KW,a))?$CLJS.nN:$CLJS.n(JP(HW,a))?VV:$CLJS.n(JP(EW,a))?$CLJS.Xj:$CLJS.n(JP(IW,a))?$CLJS.Ct:$CLJS.Vu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nN,$CLJS.NW],null),new $CLJS.P(null,2,5,$CLJS.Q,[VV,LW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xj,FW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ct,MW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vu,LP($CLJS.G([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof GX?new $CLJS.Gc(function(){return GX},$CLJS.qd(bV,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IS,"metabase/mbql/schema.cljc",
15,$CLJS.st,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.st,vR,$CLJS.V($CLJS.Oj,NS),qT,$CLJS.V($CLJS.Oj,NS),FR,$CLJS.V($CLJS.Oj,$CLJS.V($CLJS.Oi,NS))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(GX)?GX.H:null])):null));return $CLJS.n(a)?a:IS}(),GX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof HX?new $CLJS.Gc(function(){return HX},$CLJS.qd(uT,new $CLJS.k(null,1,[$CLJS.Zj,
!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xT,"metabase/mbql/schema.cljc",14,$CLJS.lt,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.lt,vR,$CLJS.V($CLJS.Oj,NS),qT,$CLJS.V($CLJS.Oj,NS),FR,$CLJS.V($CLJS.Oj,$CLJS.V($CLJS.Oi,NS))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.n(HX)?
HX.H:null])):null));return $CLJS.n(a)?a:xT}(),HX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof IX?new $CLJS.Gc(function(){return IX},$CLJS.qd(gU,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$S,"metabase/mbql/schema.cljc",15,$CLJS.nt,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nt,$CLJS.OV,$CLJS.V($CLJS.Oj,NS)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(IX)?IX.H:null])):null));return $CLJS.n(a)?a:$S}(),IX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof MX?new $CLJS.Gc(function(){return MX},$CLJS.qd(CT,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,
$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DS,"metabase/mbql/schema.cljc",13,$CLJS.Ls,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ls,$CLJS.lK,$CLJS.V($CLJS.Oj,MV),MU,$CLJS.V($CLJS.Oj,MV),nU,$CLJS.V($CLJS.Oj,$CLJS.V($CLJS.Oi,MV))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(MX)?MX.H:null])):null));
return $CLJS.n(a)?a:DS}(),MX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof NX?new $CLJS.Gc(function(){return NX},$CLJS.qd(LQ,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hR,"metabase/mbql/schema.cljc",14,$CLJS.EK,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.EK,$CLJS.lK,$CLJS.V($CLJS.Oj,MV),MU,$CLJS.V($CLJS.Oj,MV),nU,$CLJS.V($CLJS.Oj,$CLJS.V($CLJS.Oi,MV))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(NX)?NX.H:null])):null));return $CLJS.n(a)?a:hR}(),NX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof OX?new $CLJS.Gc(function(){return OX},
$CLJS.qd(DT,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BS,"metabase/mbql/schema.cljc",13,$CLJS.Is,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Is,$CLJS.lK,$CLJS.V($CLJS.Oj,xR),MU,$CLJS.V($CLJS.Oj,xR)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",
$CLJS.n(OX)?OX.H:null])):null));return $CLJS.n(a)?a:BS}(),OX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof PX?new $CLJS.Gc(function(){return PX},$CLJS.qd(BT,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CS,"metabase/mbql/schema.cljc",13,$CLJS.Es,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Es,$CLJS.lK,$CLJS.V($CLJS.Oj,xR),MU,$CLJS.V($CLJS.Oj,xR)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(PX)?PX.H:null])):null));return $CLJS.n(a)?a:CS}(),PX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof QX?new $CLJS.Gc(function(){return QX},$CLJS.qd(HV,new $CLJS.k(null,
1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AS,"metabase/mbql/schema.cljc",14,$CLJS.Ks,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ks,$CLJS.lK,$CLJS.V($CLJS.Oj,xR),MU,$CLJS.V($CLJS.Oj,xR)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(QX)?QX.H:null])):
null));return $CLJS.n(a)?a:AS}(),QX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof RX?new $CLJS.Gc(function(){return RX},$CLJS.qd(AV,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JS,"metabase/mbql/schema.cljc",14,$CLJS.Gs,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Gs,$CLJS.lK,$CLJS.V($CLJS.Oj,xR),MU,$CLJS.V($CLJS.Oj,xR)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(RX)?RX.H:null])):null));return $CLJS.n(a)?a:JS}(),RX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof SX?new $CLJS.Gc(function(){return SX},$CLJS.qd(zda,new $CLJS.k(null,
1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NQ,"metabase/mbql/schema.cljc",19,$CLJS.QJ,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.QJ,$CLJS.lK,$CLJS.V($CLJS.Oj,xR),$CLJS.On,$CLJS.V($CLJS.Oj,xR),$CLJS.gk,$CLJS.V($CLJS.Oj,xR)],null),$CLJS.Kc,"Schema for a valid between clause.",
$CLJS.n(SX)?SX.H:null])):null));return $CLJS.n(a)?a:NQ}(),SX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof ZX?new $CLJS.Gc(function(){return ZX},$CLJS.qd(Bda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),RU,"metabase/mbql/schema.cljc",23,$CLJS.cK,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.cK,$CLJS.lK,$CLJS.V($CLJS.Oj,LU),gR,$CLJS.V($CLJS.Oj,LU),$CLJS.ej,$CLJS.V($CLJS.Oj,$CLJS.V(YU,eU))],null),$CLJS.Kc,"Schema for a valid starts-with clause.",$CLJS.n(ZX)?ZX.H:null])):null));return $CLJS.n(a)?a:RU}(),ZX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof $X?new $CLJS.Gc(function(){return $X},
$CLJS.qd(Dca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mR,"metabase/mbql/schema.cljc",21,$CLJS.wK,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.wK,$CLJS.lK,$CLJS.V($CLJS.Oj,LU),gR,$CLJS.V($CLJS.Oj,LU),$CLJS.ej,$CLJS.V($CLJS.Oj,$CLJS.V(YU,eU))],null),
$CLJS.Kc,"Schema for a valid ends-with clause.",$CLJS.n($X)?$X.H:null])):null));return $CLJS.n(a)?a:mR}(),$X],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof aY?new $CLJS.Gc(function(){return aY},$CLJS.qd(Pca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yR,"metabase/mbql/schema.cljc",20,$CLJS.kK,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kK,$CLJS.lK,$CLJS.V($CLJS.Oj,LU),gR,$CLJS.V($CLJS.Oj,LU),$CLJS.ej,$CLJS.V($CLJS.Oj,$CLJS.V(YU,eU))],null),$CLJS.Kc,"Schema for a valid contains clause.",$CLJS.n(aY)?aY.H:null])):null));return $CLJS.n(a)?a:yR}(),aY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==
typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof bY?new $CLJS.Gc(function(){return bY},$CLJS.qd(tea,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$U,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aQ,"metabase/mbql/schema.cljc",36,$CLJS.dK,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.dK,$CLJS.lK,$CLJS.V($CLJS.Oj,LU),gR,$CLJS.V($CLJS.Oj,LU),$CLJS.ej,$CLJS.V($CLJS.Oj,$CLJS.V(YU,eU))],null),$CLJS.Kc,"Schema for a valid does-not-contain clause.",$CLJS.n(bY)?bY.H:null])):null));return $CLJS.n(a)?a:aQ}(),bY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof TX?new $CLJS.Gc(function(){return TX},$CLJS.qd(cea,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),
$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$U,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PU,"metabase/mbql/schema.cljc",26,$CLJS.VJ,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.VJ,lca,$CLJS.V($CLJS.Oj,xR),xea,$CLJS.V($CLJS.Oj,xR),jea,$CLJS.V($CLJS.Oj,xR),oca,$CLJS.V($CLJS.Oj,xR),dea,$CLJS.V($CLJS.Oj,xR),kca,$CLJS.V($CLJS.Oj,
xR)],null),$CLJS.Kc,"Schema for a valid inside clause.",$CLJS.n(TX)?TX.H:null])):null));return $CLJS.n(a)?a:PU}(),TX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof WX?new $CLJS.Gc(function(){return WX},$CLJS.qd(Qca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$U,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iT,"metabase/mbql/schema.cljc",28,$CLJS.RJ,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RJ,$CLJS.lK,$CLJS.V($CLJS.Oj,qR)],null),$CLJS.Kc,"Schema for a valid is-empty clause.",$CLJS.n(WX)?WX.H:null])):null));return $CLJS.n(a)?a:iT}(),WX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&
"undefined"!==typeof XX?new $CLJS.Gc(function(){return XX},$CLJS.qd(Oda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$U,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WT,"metabase/mbql/schema.cljc",29,$CLJS.XJ,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XJ,$CLJS.lK,$CLJS.V($CLJS.Oj,qR)],null),
$CLJS.Kc,"Schema for a valid not-empty clause.",$CLJS.n(XX)?XX.H:null])):null));return $CLJS.n(a)?a:WT}(),XX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof UX?new $CLJS.Gc(function(){return UX},$CLJS.qd(oea,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$U,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ER,"metabase/mbql/schema.cljc",27,$CLJS.PJ,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PJ,$CLJS.lK,$CLJS.V($CLJS.Oj,qR)],null),$CLJS.Kc,"Schema for a valid is-null clause.",$CLJS.n(UX)?UX.H:null])):null));return $CLJS.n(a)?a:ER}(),UX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&
"undefined"!==typeof VX?new $CLJS.Gc(function(){return VX},$CLJS.qd(bda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$U,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TQ,"metabase/mbql/schema.cljc",28,$CLJS.ZJ,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZJ,$CLJS.lK,$CLJS.V($CLJS.Oj,qR)],null),
$CLJS.Kc,"Schema for a valid not-null clause.",$CLJS.n(VX)?VX.H:null])):null));return $CLJS.n(a)?a:TQ}(),VX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof cY?new $CLJS.Gc(function(){return cY},$CLJS.qd(cca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$U,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vU,"metabase/mbql/schema.cljc",33,$CLJS.xK,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.xK,$CLJS.lK,$CLJS.V($CLJS.Oj,qR),$CLJS.bz,$CLJS.V($CLJS.Oj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,$CLJS.yt,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ht,$CLJS.Lx,$CLJS.dB,$CLJS.UJ],null)],null)),$CLJS.wi,$CLJS.V($CLJS.Oj,HS),$CLJS.ej,$CLJS.V($CLJS.Oj,$CLJS.V(YU,Eda))],null),$CLJS.Kc,"Schema for a valid time-interval clause.",
$CLJS.n(cY)?cY.H:null])):null));return $CLJS.n(a)?a:vU}(),cY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof dY?new $CLJS.Gc(function(){return dY},$CLJS.qd(jda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$U,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yV,"metabase/mbql/schema.cljc",27,$CLJS.MJ,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MJ,sca,$CLJS.V($CLJS.Oj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,Uda,EQ],null))],null),$CLJS.Kc,"Schema for a valid segment clause.",$CLJS.n(dY)?dY.H:null])):null));return $CLJS.n(a)?a:yV}(),dY],null)]))],null)],null));
$CLJS.X(hU,KP($CLJS.WJ,$CLJS.G(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ot,new $CLJS.k(null,1,[$CLJS.Au,":case subclause"],null),$CLJS.FX,RW],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fk,new $CLJS.k(null,1,[$CLJS.Au,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),RW],null)],null)],null)])));
var eY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hU],null);
$CLJS.X(QU,LP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof dX?new $CLJS.Gc(function(){return dX},$CLJS.qd(FT,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),sS,"metabase/mbql/schema.cljc",51,$CLJS.Os,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Os,$CLJS.CI,$CLJS.V($CLJS.Oj,JT),iV,$CLJS.V($CLJS.Oj,JT),uR,$CLJS.V($CLJS.Oj,$CLJS.V($CLJS.Oi,JT))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(dX)?dX.H:null])):null));return $CLJS.n(a)?a:sS}(),dX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof eX?new $CLJS.Gc(function(){return eX},
$CLJS.qd(vda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tS,"metabase/mbql/schema.cljc",51,$CLJS.qu,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.qu,$CLJS.CI,$CLJS.V($CLJS.Oj,GU),
iV,$CLJS.V($CLJS.Oj,JT),uR,$CLJS.V($CLJS.Oj,$CLJS.V($CLJS.Oi,JT))],null),$CLJS.Kc,"Schema for a valid - clause.",$CLJS.n(eX)?eX.H:null])):null));return $CLJS.n(a)?a:tS}(),eX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP?new $CLJS.Gc(function(){return fX},$CLJS.qd(uda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,XS,$CLJS.Ui,$CLJS.mj,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,
new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Wu,"metabase/mbql/schema.cljc",$CLJS.HK,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.HK,$CLJS.CI,$CLJS.V($CLJS.Oj,GU),iV,$CLJS.V($CLJS.Oj,GU),uR,$CLJS.V($CLJS.Oj,$CLJS.V($CLJS.Oi,GU))],null),$CLJS.Kc,"Schema for a valid / clause.",$CLJS.n(fX)?fX.H:null])):null));return $CLJS.n(a)?a:$CLJS.Wu}(),fX],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof gX?new $CLJS.Gc(function(){return gX},$CLJS.qd(wda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),rS,"metabase/mbql/schema.cljc",51,$CLJS.Ps,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ps,$CLJS.CI,$CLJS.V($CLJS.Oj,GU),iV,$CLJS.V($CLJS.Oj,GU),uR,$CLJS.V($CLJS.Oj,$CLJS.V($CLJS.Oi,GU))],null),$CLJS.Kc,"Schema for a valid * clause.",$CLJS.n(gX)?gX.H:null])):null));return $CLJS.n(a)?a:rS}(),gX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof TW?new $CLJS.Gc(function(){return TW},
$CLJS.qd(rU,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IU,"metabase/mbql/schema.cljc",58,$CLJS.oK,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.oK,qV,$CLJS.V($CLJS.Oj,fQ),pV,$CLJS.V($CLJS.Oj,
fQ),uR,$CLJS.V($CLJS.Oj,$CLJS.V($CLJS.Oi,fQ))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(TW)?TW.H:null])):null));return $CLJS.n(a)?a:IU}(),TW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof VW?new $CLJS.Gc(function(){return VW},$CLJS.qd(Nda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,
$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZT,"metabase/mbql/schema.cljc",56,$CLJS.KA,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KA,$CLJS.RB,$CLJS.V($CLJS.Oj,LU)],null),$CLJS.Kc,"Schema for a valid length clause.",$CLJS.n(VW)?VW.H:null])):null));return $CLJS.n(a)?a:ZT}(),VW],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof hX?new $CLJS.Gc(function(){return hX},$CLJS.qd(Eca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),US,"metabase/mbql/schema.cljc",55,$CLJS.jK,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jK,$CLJS.CI,$CLJS.V($CLJS.Oj,GU)],null),$CLJS.Kc,"Schema for a valid floor clause.",$CLJS.n(hX)?hX.H:null])):null));return $CLJS.n(a)?a:US}(),hX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof iX?new $CLJS.Gc(function(){return iX},$CLJS.qd(yca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),
$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DQ,"metabase/mbql/schema.cljc",54,$CLJS.KJ,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KJ,$CLJS.CI,$CLJS.V($CLJS.Oj,GU)],null),$CLJS.Kc,"Schema for a valid ceil clause.",$CLJS.n(iX)?
iX.H:null])):null));return $CLJS.n(a)?a:DQ}(),iX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof jX?new $CLJS.Gc(function(){return jX},$CLJS.qd(Mca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KS,"metabase/mbql/schema.cljc",55,$CLJS.qK,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qK,$CLJS.CI,$CLJS.V($CLJS.Oj,GU)],null),$CLJS.Kc,"Schema for a valid round clause.",$CLJS.n(jX)?jX.H:null])):null));return $CLJS.n(a)?a:KS}(),jX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&
"undefined"!==typeof kX?new $CLJS.Gc(function(){return kX},$CLJS.qd(uea,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mT,"metabase/mbql/schema.cljc",53,$CLJS.OJ,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.OJ,$CLJS.CI,$CLJS.V($CLJS.Oj,GU)],null),$CLJS.Kc,"Schema for a valid abs clause.",$CLJS.n(kX)?kX.H:null])):null));return $CLJS.n(a)?a:mT}(),kX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof lX?new $CLJS.Gc(function(){return lX},$CLJS.qd(hea,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,
OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[EV,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aV,"metabase/mbql/schema.cljc",69,$CLJS.LJ,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.LJ,$CLJS.CI,$CLJS.V($CLJS.Oj,GU),iV,$CLJS.V($CLJS.Oj,GU)],null),$CLJS.Kc,"Schema for a valid power clause.",$CLJS.n(lX)?lX.H:null])):null));return $CLJS.n(a)?a:aV}(),lX],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof mX?new $CLJS.Gc(function(){return mX},$CLJS.qd(eda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[EV,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),nS,"metabase/mbql/schema.cljc",68,$CLJS.$J,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$J,$CLJS.CI,$CLJS.V($CLJS.Oj,GU)],null),$CLJS.Kc,"Schema for a valid sqrt clause.",$CLJS.n(mX)?mX.H:null])):null));return $CLJS.n(a)?a:nS}(),mX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof nX?new $CLJS.Gc(function(){return nX},$CLJS.qd(iea,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),
$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[EV,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MS,"metabase/mbql/schema.cljc",67,$CLJS.HJ,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HJ,$CLJS.CI,$CLJS.V($CLJS.Oj,GU)],null),$CLJS.Kc,"Schema for a valid exp clause.",$CLJS.n(nX)?
nX.H:null])):null));return $CLJS.n(a)?a:MS}(),nX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof oX?new $CLJS.Gc(function(){return oX},$CLJS.qd(rca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[EV,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JV,"metabase/mbql/schema.cljc",67,$CLJS.JK,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JK,$CLJS.CI,$CLJS.V($CLJS.Oj,GU)],null),$CLJS.Kc,"Schema for a valid log clause.",$CLJS.n(oX)?oX.H:null])):null));return $CLJS.n(a)?a:JV}(),oX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&
"undefined"!==typeof eY?new $CLJS.Gc(function(){return eY},$CLJS.qd(qQ,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.JJ,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dT,"metabase/mbql/schema.cljc",61,$CLJS.WJ,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.WJ,$CLJS.ez,$CLJS.V($CLJS.Oj,KR),$CLJS.ej,$CLJS.V($CLJS.Oj,$CLJS.V(YU,wT))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(eY)?eY.H:null])):null));return $CLJS.n(a)?a:dT}(),eY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof pX?new $CLJS.Gc(function(){return pX},$CLJS.qd(uca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,
$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.jN,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nT,"metabase/mbql/schema.cljc",65,$CLJS.jN,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jN,Gca,$CLJS.V($CLJS.Oj,UV),Hca,$CLJS.V($CLJS.Oj,UV),$CLJS.wi,$CLJS.V($CLJS.Oj,mda)],null),$CLJS.Kc,"Schema for a valid datetime-diff clause.",
$CLJS.n(pX)?pX.H:null])):null));return $CLJS.n(a)?a:nT}(),pX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof qX?new $CLJS.Gc(function(){return qX},$CLJS.qd(Rda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.yN,"null"],null),null),$CLJS.qd(EU,
new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GT,"metabase/mbql/schema.cljc",71,$CLJS.yN,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.yN,$CLJS.nN,$CLJS.V($CLJS.Oj,UV),$CLJS.wi,$CLJS.V($CLJS.Oj,nea),$CLJS.Ci,$CLJS.V($CLJS.Oj,$CLJS.V(YU,bW))],null),$CLJS.Kc,"Schema for a valid temporal-extract clause.",$CLJS.n(qX)?qX.H:null])):null));return $CLJS.n(a)?a:GT}(),qX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof rX?new $CLJS.Gc(function(){return rX},$CLJS.qd(Yca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$U,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.yN,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aR,"metabase/mbql/schema.cljc",71,$CLJS.fN,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fN,$CLJS.pT,$CLJS.V($CLJS.Oj,UV)],null),$CLJS.Kc,"Schema for a valid get-year clause.",$CLJS.n(rX)?rX.H:null])):null));return $CLJS.n(a)?a:aR}(),rX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof sX?new $CLJS.Gc(function(){return sX},$CLJS.qd(Nca,new $CLJS.k(null,1,[$CLJS.Zj,!0],
null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$U,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.yN,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ET,"metabase/mbql/schema.cljc",74,$CLJS.CN,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CN,$CLJS.pT,$CLJS.V($CLJS.Oj,UV)],null),$CLJS.Kc,"Schema for a valid get-quarter clause.",
$CLJS.n(sX)?sX.H:null])):null));return $CLJS.n(a)?a:ET}(),sX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof tX?new $CLJS.Gc(function(){return tX},$CLJS.qd(tda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$U,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.yN,"null"],null),null),
$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aU,"metabase/mbql/schema.cljc",72,$CLJS.ZM,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZM,$CLJS.pT,$CLJS.V($CLJS.Oj,UV)],null),$CLJS.Kc,"Schema for a valid get-month clause.",$CLJS.n(tX)?tX.H:null])):null));return $CLJS.n(a)?a:aU}(),tX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==
typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof uX?new $CLJS.Gc(function(){return uX},$CLJS.qd(mea,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$U,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.yN,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$T,"metabase/mbql/schema.cljc",
71,$CLJS.sN,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.sN,$CLJS.pT,$CLJS.V($CLJS.Oj,UV),$CLJS.Ci,$CLJS.V($CLJS.Oj,$CLJS.V(YU,bW))],null),$CLJS.Kc,"Schema for a valid get-week clause.",$CLJS.n(uX)?uX.H:null])):null));return $CLJS.n(a)?a:$T}(),uX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof vX?new $CLJS.Gc(function(){return vX},$CLJS.qd(ica,new $CLJS.k(null,1,[$CLJS.Zj,
!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$U,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.yN,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TT,"metabase/mbql/schema.cljc",70,$CLJS.rN,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rN,$CLJS.pT,$CLJS.V($CLJS.Oj,UV)],null),$CLJS.Kc,"Schema for a valid get-day clause.",
$CLJS.n(vX)?vX.H:null])):null));return $CLJS.n(a)?a:TT}(),vX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof wX?new $CLJS.Gc(function(){return wX},$CLJS.qd(nda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$U,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.yN,"null"],null),null),
$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KT,"metabase/mbql/schema.cljc",78,$CLJS.wN,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wN,$CLJS.pT,$CLJS.V($CLJS.Oj,UV)],null),$CLJS.Kc,"Schema for a valid get-day-of-week clause.",$CLJS.n(wX)?wX.H:null])):null));return $CLJS.n(a)?a:KT}(),wX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==
typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof xX?new $CLJS.Gc(function(){return xX},$CLJS.qd(lda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$U,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.yN,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gQ,"metabase/mbql/schema.cljc",
71,$CLJS.zN,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zN,$CLJS.nN,$CLJS.V($CLJS.Oj,UV)],null),$CLJS.Kc,"Schema for a valid get-hour clause.",$CLJS.n(xX)?xX.H:null])):null));return $CLJS.n(a)?a:gQ}(),xX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof yX?new $CLJS.Gc(function(){return yX},$CLJS.qd(Vda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,
$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$U,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.yN,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NV,"metabase/mbql/schema.cljc",73,$CLJS.vN,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vN,$CLJS.nN,$CLJS.V($CLJS.Oj,UV)],null),$CLJS.Kc,"Schema for a valid get-minute clause.",$CLJS.n(yX)?yX.H:null])):null));
return $CLJS.n(a)?a:NV}(),yX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof zX?new $CLJS.Gc(function(){return zX},$CLJS.qd(ida,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$U,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.yN,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fT,"metabase/mbql/schema.cljc",73,$CLJS.eN,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eN,$CLJS.nN,$CLJS.V($CLJS.Oj,UV)],null),$CLJS.Kc,"Schema for a valid get-second clause.",$CLJS.n(zX)?zX.H:null])):null));return $CLJS.n(a)?a:fT}(),zX],null)])));
$CLJS.X(wQ,LP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof UW?new $CLJS.Gc(function(){return UW},$CLJS.qd(Lda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iU,"metabase/mbql/schema.cljc",59,$CLJS.IJ,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.IJ,$CLJS.RB,$CLJS.V($CLJS.Oj,LU),$CLJS.qx,$CLJS.V($CLJS.Oj,Jca),$CLJS.KA,$CLJS.V($CLJS.Oj,$CLJS.V(YU,GU))],null),$CLJS.Kc,"Schema for a valid substring clause.",$CLJS.n(UW)?UW.H:null])):null));return $CLJS.n(a)?a:iU}(),UW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof WW?new $CLJS.Gc(function(){return WW},
$CLJS.qd(kea,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HU,"metabase/mbql/schema.cljc",54,$CLJS.DK,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DK,$CLJS.RB,$CLJS.V($CLJS.Oj,LU)],
null),$CLJS.Kc,"Schema for a valid trim clause.",$CLJS.n(WW)?WW.H:null])):null));return $CLJS.n(a)?a:HU}(),WW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof YW?new $CLJS.Gc(function(){return YW},$CLJS.qd(oda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,
1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oU,"metabase/mbql/schema.cljc",55,$CLJS.FK,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FK,$CLJS.RB,$CLJS.V($CLJS.Oj,LU)],null),$CLJS.Kc,"Schema for a valid ltrim clause.",$CLJS.n(YW)?YW.H:null])):null));return $CLJS.n(a)?a:oU}(),YW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&
"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof XW?new $CLJS.Gc(function(){return XW},$CLJS.qd(Mda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rT,"metabase/mbql/schema.cljc",
55,$CLJS.LK,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LK,$CLJS.RB,$CLJS.V($CLJS.Oj,LU)],null),$CLJS.Kc,"Schema for a valid rtrim clause.",$CLJS.n(XW)?XW.H:null])):null));return $CLJS.n(a)?a:rT}(),XW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof aX?new $CLJS.Gc(function(){return aX},$CLJS.qd(dca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,
$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sV,"metabase/mbql/schema.cljc",57,$CLJS.KK,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.KK,$CLJS.RB,$CLJS.V($CLJS.Oj,LU),pca,$CLJS.V($CLJS.Oj,$CLJS.Xj),$CLJS.Uu,$CLJS.V($CLJS.Oj,$CLJS.Xj)],null),$CLJS.Kc,"Schema for a valid replace clause.",
$CLJS.n(aX)?aX.H:null])):null));return $CLJS.n(a)?a:sV}(),aX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof $W?new $CLJS.Gc(function(){return $W},$CLJS.qd(Pda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,
new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XV,"metabase/mbql/schema.cljc",55,$CLJS.ev,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ev,$CLJS.RB,$CLJS.V($CLJS.Oj,LU)],null),$CLJS.Kc,"Schema for a valid lower clause.",$CLJS.n($W)?$W.H:null])):null));return $CLJS.n(a)?a:XV}(),$W],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==
typeof PP&&"undefined"!==typeof ZW?new $CLJS.Gc(function(){return ZW},$CLJS.qd(lea,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VU,"metabase/mbql/schema.cljc",55,$CLJS.Qu,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qu,$CLJS.RB,$CLJS.V($CLJS.Oj,LU)],null),$CLJS.Kc,"Schema for a valid upper clause.",$CLJS.n(ZW)?ZW.H:null])):null));return $CLJS.n(a)?a:VU}(),ZW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof bX?new $CLJS.Gc(function(){return bX},$CLJS.qd(Dda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,
$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eS,"metabase/mbql/schema.cljc",56,$CLJS.NJ,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.NJ,qV,$CLJS.V($CLJS.Oj,LU),pV,$CLJS.V($CLJS.Oj,LU),uR,$CLJS.V($CLJS.Oj,$CLJS.V($CLJS.Oi,LU))],null),$CLJS.Kc,"Schema for a valid concat clause.",$CLJS.n(bX)?
bX.H:null])):null));return $CLJS.n(a)?a:eS}(),bX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof cX?new $CLJS.Gc(function(){return cX},$CLJS.qd(Kda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.NT,"null",$CLJS.$u,"null"],null),null),
$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZQ,"metabase/mbql/schema.cljc",74,$CLJS.nK,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.nK,$CLJS.RB,$CLJS.V($CLJS.Oj,LU),$CLJS.Pu,$CLJS.V($CLJS.Oj,$CLJS.Xj)],null),$CLJS.Kc,"Schema for a valid regex-match-first clause.",$CLJS.n(cX)?cX.H:null])):null));return $CLJS.n(a)?a:ZQ}(),cX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==
typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof TW?new $CLJS.Gc(function(){return TW},$CLJS.qd(rU,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.NT,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
IU,"metabase/mbql/schema.cljc",58,$CLJS.oK,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.oK,qV,$CLJS.V($CLJS.Oj,fQ),pV,$CLJS.V($CLJS.Oj,fQ),uR,$CLJS.V($CLJS.Oj,$CLJS.V($CLJS.Oi,fQ))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(TW)?TW.H:null])):null));return $CLJS.n(a)?a:IU}(),TW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof eY?new $CLJS.Gc(function(){return eY},
$CLJS.qd(qQ,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.JJ,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dT,"metabase/mbql/schema.cljc",61,$CLJS.WJ,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.WJ,$CLJS.ez,$CLJS.V($CLJS.Oj,KR),$CLJS.ej,
$CLJS.V($CLJS.Oj,$CLJS.V(YU,wT))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(eY)?eY.H:null])):null));return $CLJS.n(a)?a:dT}(),eY],null)])));
$CLJS.fY=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Au,":field or :expression reference or expression",$CLJS.Qi,function(a){return $CLJS.n(JP(HW,a))?VV:$CLJS.n(JP(EW,a))?$CLJS.Xj:$CLJS.n(JP(IW,a))?$CLJS.Ct:$CLJS.n(JP(KW,a))?$CLJS.nN:$CLJS.n(JP($CLJS.WJ,a))?$CLJS.WJ:$CLJS.Vu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[VV,LW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xj,FW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ct,MW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nN,$CLJS.NW],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WJ,eY],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vu,$CLJS.BW],null)],null);$CLJS.X(BV,KP($CLJS.TA,$CLJS.G(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,$CLJS.BW],null)])));var gY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BV],null);$CLJS.X(oT,KP($CLJS.mK,$CLJS.G(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jt,$CLJS.BW],null)])));var hY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oT],null);$CLJS.X(uQ,KP($CLJS.fK,$CLJS.G(["field-or-expression",$CLJS.fY])));
var iY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uQ],null);$CLJS.X(tV,KP($CLJS.IK,$CLJS.G(["field-or-expression",$CLJS.fY])));var jY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tV],null);$CLJS.X(VR,KP($CLJS.SJ,$CLJS.G(["field-or-expression",$CLJS.fY])));var kY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VR],null);$CLJS.X(JU,KP($CLJS.yK,$CLJS.G(["field-or-expression",$CLJS.fY])));var lY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JU],null);$CLJS.X(QR,KP($CLJS.On,$CLJS.G(["field-or-expression",$CLJS.fY])));
var mY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QR],null);$CLJS.X(CR,KP($CLJS.gk,$CLJS.G(["field-or-expression",$CLJS.fY])));var nY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CR],null);$CLJS.X(wR,KP($CLJS.GK,$CLJS.G(["field-or-expression",$CLJS.fY,"pred",$CLJS.FX])));var oY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wR],null);$CLJS.X(NR,KP($CLJS.uK,$CLJS.G(["pred",$CLJS.FX])));var pY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NR],null);$CLJS.X(AU,KP($CLJS.sK,$CLJS.G(["pred",$CLJS.FX])));
var qY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AU],null);$CLJS.X(RQ,KP($CLJS.CK,$CLJS.G(["field-or-expression",$CLJS.fY])));var rY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RQ],null);$CLJS.X(RP,KP($CLJS.TJ,$CLJS.G(["field-or-expression",$CLJS.fY])));var sY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RP],null);$CLJS.X(aS,KP($CLJS.zK,$CLJS.G(["field-or-expression",$CLJS.fY])));var tY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aS],null);$CLJS.X(PS,KP($CLJS.YJ,$CLJS.G(["field-or-expression",$CLJS.fY,"percentile",PW])));
var uY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PS],null);$CLJS.X(DU,KP($CLJS.gK,$CLJS.G(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lI],null),cW],null)])));var vY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DU],null);
$CLJS.X(zU,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Au,"unnamed aggregation clause or numeric expression",$CLJS.Qi,function(a){return $CLJS.n(JP(HW,a))?SS:$CLJS.Vu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[SS,LW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vu,LP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof iY?new $CLJS.Gc(function(){return iY},$CLJS.qd(Jda,
new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.JJ,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aT,"metabase/mbql/schema.cljc",60,$CLJS.fK,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fK,WU,$CLJS.V($CLJS.Oj,NU)],null),$CLJS.Kc,"Schema for a valid avg clause.",
$CLJS.n(iY)?iY.H:null])):null));return $CLJS.n(a)?a:aT}(),iY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof jY?new $CLJS.Gc(function(){return jY},$CLJS.qd(Uca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.JJ,"null"],null),null),$CLJS.qd(EU,
new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hV,"metabase/mbql/schema.cljc",64,$CLJS.IK,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IK,WU,$CLJS.V($CLJS.Oj,NU)],null),$CLJS.Kc,"Schema for a valid cum-sum clause.",$CLJS.n(jY)?jY.H:null])):null));return $CLJS.n(a)?a:hV}(),jY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==
typeof PP&&"undefined"!==typeof kY?new $CLJS.Gc(function(){return kY},$CLJS.qd(Lca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.JJ,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eT,"metabase/mbql/schema.cljc",65,$CLJS.SJ,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SJ,WU,$CLJS.V($CLJS.Oj,NU)],null),$CLJS.Kc,"Schema for a valid distinct clause.",$CLJS.n(kY)?kY.H:null])):null));return $CLJS.n(a)?a:eT}(),kY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof rY?new $CLJS.Gc(function(){return rY},$CLJS.qd(eea,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,
$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.vK,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nQ,"metabase/mbql/schema.cljc",76,$CLJS.CK,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CK,WU,$CLJS.V($CLJS.Oj,NU)],null),$CLJS.Kc,"Schema for a valid stddev clause.",$CLJS.n(rY)?rY.H:null])):null));return $CLJS.n(a)?a:nQ}(),rY],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof lY?new $CLJS.Gc(function(){return lY},$CLJS.qd(yda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.JJ,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JQ,"metabase/mbql/schema.cljc",60,$CLJS.yK,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yK,WU,$CLJS.V($CLJS.Oj,NU)],null),$CLJS.Kc,"Schema for a valid sum clause.",$CLJS.n(lY)?lY.H:null])):null));return $CLJS.n(a)?a:JQ}(),lY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof mY?new $CLJS.Gc(function(){return mY},$CLJS.qd(mca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,
$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.JJ,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Yt,"metabase/mbql/schema.cljc",60,$CLJS.On,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.On,WU,$CLJS.V($CLJS.Oj,NU)],null),$CLJS.Kc,"Schema for a valid min clause.",$CLJS.n(mY)?mY.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Yt}(),mY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof nY?new $CLJS.Gc(function(){return nY},$CLJS.qd(hca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.JJ,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.cu,"metabase/mbql/schema.cljc",60,$CLJS.gk,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,WU,$CLJS.V($CLJS.Oj,NU)],null),$CLJS.Kc,"Schema for a valid max clause.",$CLJS.n(nY)?nY.H:null])):null));return $CLJS.n(a)?a:$CLJS.cu}(),nY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&
"undefined"!==typeof vY?new $CLJS.Gc(function(){return vY},$CLJS.qd(Ica,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dS,"metabase/mbql/schema.cljc",18,$CLJS.gK,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gK,Vca,$CLJS.V($CLJS.Oj,new $CLJS.P(null,3,5,
$CLJS.Q,[$CLJS.lt,Zca,EQ],null))],null),$CLJS.Kc,"Schema for a valid metric clause.",$CLJS.n(vY)?vY.H:null])):null));return $CLJS.n(a)?a:dS}(),vY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof qY?new $CLJS.Gc(function(){return qY},$CLJS.qd(gda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,
$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.JJ,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UR,"metabase/mbql/schema.cljc",62,$CLJS.sK,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sK,$CLJS.go,$CLJS.V($CLJS.Oj,NS)],null),$CLJS.Kc,"Schema for a valid share clause.",$CLJS.n(qY)?qY.H:null])):null));return $CLJS.n(a)?a:UR}(),qY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof pY?new $CLJS.Gc(function(){return pY},$CLJS.qd(nca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.JJ,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FQ,"metabase/mbql/schema.cljc",68,$CLJS.uK,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uK,$CLJS.go,$CLJS.V($CLJS.Oj,NS)],null),$CLJS.Kc,"Schema for a valid count-where clause.",$CLJS.n(pY)?pY.H:null])):null));return $CLJS.n(a)?a:FQ}(),pY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof oY?new $CLJS.Gc(function(){return oY},$CLJS.qd(sea,new $CLJS.k(null,1,[$CLJS.Zj,!0],
null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.JJ,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bT,"metabase/mbql/schema.cljc",66,$CLJS.GK,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.GK,WU,$CLJS.V($CLJS.Oj,NU),$CLJS.go,$CLJS.V($CLJS.Oj,NS)],null),$CLJS.Kc,"Schema for a valid sum-where clause.",
$CLJS.n(oY)?oY.H:null])):null));return $CLJS.n(a)?a:bT}(),oY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof eY?new $CLJS.Gc(function(){return eY},$CLJS.qd(qQ,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.JJ,"null"],null),null),$CLJS.qd(EU,
new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dT,"metabase/mbql/schema.cljc",61,$CLJS.WJ,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.WJ,$CLJS.ez,$CLJS.V($CLJS.Oj,KR),$CLJS.ej,$CLJS.V($CLJS.Oj,$CLJS.V(YU,wT))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(eY)?eY.H:null])):null));return $CLJS.n(a)?a:dT}(),eY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&
"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof tY?new $CLJS.Gc(function(){return tY},$CLJS.qd(Yda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.hK,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fV,"metabase/mbql/schema.cljc",
68,$CLJS.zK,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zK,WU,$CLJS.V($CLJS.Oj,NU)],null),$CLJS.Kc,"Schema for a valid median clause.",$CLJS.n(tY)?tY.H:null])):null));return $CLJS.n(a)?a:fV}(),tY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof uY?new $CLJS.Gc(function(){return uY},$CLJS.qd(sda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,
XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.hK,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CU,"metabase/mbql/schema.cljc",72,$CLJS.YJ,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.YJ,WU,$CLJS.V($CLJS.Oj,NU),$CLJS.YJ,$CLJS.V($CLJS.Oj,GU)],null),$CLJS.Kc,"Schema for a valid percentile clause.",$CLJS.n(uY)?uY.H:null])):
null));return $CLJS.n(a)?a:CU}(),uY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof sY?new $CLJS.Gc(function(){return sY},$CLJS.qd(xca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iQ,"metabase/mbql/schema.cljc",77,$CLJS.TJ,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TJ,WU,$CLJS.V($CLJS.Oj,NU)],null),$CLJS.Kc,"Schema for a valid var clause.",$CLJS.n(sY)?sY.H:null])):null));return $CLJS.n(a)?a:iQ}(),sY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof hY?new $CLJS.Gc(function(){return hY},$CLJS.qd(jca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,
$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$U,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.JJ,"null"],null),null),$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OS,"metabase/mbql/schema.cljc",74,$CLJS.mK,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mK,$CLJS.lK,$CLJS.V($CLJS.Oj,$CLJS.V(YU,qR))],null),$CLJS.Kc,"Schema for a valid cum-count clause.",
$CLJS.n(hY)?hY.H:null])):null));return $CLJS.n(a)?a:OS}(),hY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof gY?new $CLJS.Gc(function(){return gY},$CLJS.qd(Kca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([VS,$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,$U,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.JJ,"null"],null),null),
$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Bi,"metabase/mbql/schema.cljc",70,$CLJS.TA,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TA,$CLJS.lK,$CLJS.V($CLJS.Oj,$CLJS.V(YU,qR))],null),$CLJS.Kc,"Schema for a valid count clause.",$CLJS.n(gY)?gY.H:null])):null));return $CLJS.n(a)?a:$CLJS.Bi}(),gY],null)]))],null)],null));
$CLJS.X(QT,KP($CLJS.dQ,$CLJS.G(["aggregation",zU,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fk,new $CLJS.k(null,1,[$CLJS.Au,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),cW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qI,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),cW],null)],null)])));
$CLJS.X(XU,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,2,[$CLJS.Au,"aggregation clause or numeric expression",$CLJS.Qi,function(a){return $CLJS.n(JP($CLJS.dQ,a))?$CLJS.dQ:JR}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dQ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QT],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[JR,zU],null)],null));$CLJS.X(mQ,KP($CLJS.iK,$CLJS.G(["field",DW])));var wY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mQ],null);$CLJS.X(RS,KP($CLJS.bK,$CLJS.G(["field",DW])));
xY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RS],null);
Iea=LP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof wY?new $CLJS.Gc(function(){return wY},$CLJS.qd(bea,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
xS,"metabase/mbql/schema.cljc",15,$CLJS.iK,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iK,$CLJS.lK,$CLJS.V($CLJS.Oj,WR)],null),$CLJS.Kc,"Schema for a valid asc clause.",$CLJS.n(wY)?wY.H:null])):null));return $CLJS.n(a)?a:xS}(),wY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof xY?new $CLJS.Gc(function(){return xY},$CLJS.qd(Tca,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,
$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sU,"metabase/mbql/schema.cljc",16,$CLJS.bK,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bK,$CLJS.lK,$CLJS.V($CLJS.Oj,WR)],null),$CLJS.Kc,"Schema for a valid desc clause.",$CLJS.n(xY)?xY.H:null])):null));return $CLJS.n(a)?a:sU}(),xY],null)]));
yY=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ht,$CLJS.pQ,$CLJS.fR,$CLJS.SP,$CLJS.vv,$CLJS.HE,$CLJS.pT],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,cW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qI,cW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),cW],null)],null);
Jea=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,yY,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.pQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bQ,cW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HT,fW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IT,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),fW],null)],null)],null);
Kea=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,yY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.fR],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SU,fW],null)],null)],null);
zY=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,yY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Dt],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IR,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Ct],null)],null)],null);Lea=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gV],null);
Mea=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,zY,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.SP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SP,AW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qt,$CLJS.Wi,$CLJS.Dt],null)],null)],null)],null);
$CLJS.AY=new $CLJS.dh(null,new $CLJS.k(null,4,[$CLJS.pT,null,$CLJS.vv,null,$CLJS.Ct,null,$CLJS.HE,null],null),null);Nea=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ht],null),$CLJS.AY);
$CLJS.X(lU,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,1,[$CLJS.Qi,$CLJS.lj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SP,Mea],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pQ,Jea],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fR,Kea],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Po,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,zY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,Nea],null)],null)],null)],null)],null));
var BY=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tU,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qt,cW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Me(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.F.g(c,
$CLJS.S.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZR,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,cW],null)],null)],null),Oea=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,BY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rQ,$CLJS.Dt],null)],null)],null),CY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UT],null),DY=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,1,[$CLJS.Qi,function(a){return $CLJS.n($CLJS.wP($CLJS.yd,
$CLJS.XT)(a))?$CLJS.XT:$CLJS.pI}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,BY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XT,$CLJS.Dt],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pI,CY],null)],null),EY=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,cW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SG,dW],null),new $CLJS.P(null,2,5,$CLJS.Q,[Ada,cW],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.qS,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,eW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DR,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.fk],null)],null)],null),FY=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,Aea,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.Au,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),Pea=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ht],null),
new $CLJS.dh(null,new $CLJS.k(null,4,[$CLJS.oJ,null,$CLJS.SI,null,$CLJS.gJ,null,$CLJS.mJ,null],null),null)),GY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eV],null);
$CLJS.X(TR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TS,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),FY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wU,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),DY],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FV,$CLJS.FX],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GI,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),Pea],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WI,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.lt,new $CLJS.k(null,1,[$CLJS.Au,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ht,$CLJS.cC,$CLJS.Xu],null),GY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$I,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),cW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uU,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,hW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FS,new $CLJS.k(null,
1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,EY],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.wP($CLJS.WD($CLJS.TS,$CLJS.wU),$CLJS.Oe($CLJS.wP($CLJS.TS,$CLJS.wU)))],null)],null));var Qea=$CLJS.Q,HY;var IY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TR],null)],null);
if($CLJS.xd(IY)&&$CLJS.F.g($CLJS.B(IY),$CLJS.yj)){var JY=$CLJS.z(IY);$CLJS.B(JY);var KY=$CLJS.C(JY),Rea=$CLJS.yd($CLJS.B(KY))?KY:$CLJS.de(null,KY),LY=$CLJS.z(Rea),Sea=$CLJS.B(LY),Tea=$CLJS.C(LY);HY=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.R.j(Sea,$CLJS.On,1)],null),Tea)}else HY=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,IY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"non-empty"],null),$CLJS.z],null)],null);
$CLJS.X(UQ,new $CLJS.P(null,3,5,Qea,[$CLJS.st,HY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"All join aliases must be unique."],null),function(a){return $CLJS.uP($CLJS.jf($CLJS.Ua,$CLJS.ff.g($CLJS.$I,a)))}],null)],null));var Uea=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UQ],null);
$CLJS.X(eV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hl,new $CLJS.k(null,1,[$CLJS.Au,"Distinct, non-empty sequence of Field clauses"],null),MP(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.k(null,1,[$CLJS.On,1],null),$CLJS.BW],null))],null));
$CLJS.X(UT,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wU,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),DY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TS,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),FY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aK,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.k(null,1,[$CLJS.On,1],null),$CLJS.OW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uS,new $CLJS.k(null,1,
[$CLJS.Jt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.k(null,1,[$CLJS.On,1],null),$CLJS.BW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NT,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qt,cW,$CLJS.fY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WI,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),GY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SQ,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.FX],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$V,new $CLJS.k(null,
1,[$CLJS.Jt,!0],null),gW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DV,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),MP(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.k(null,1,[$CLJS.On,1],null),Iea],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[YT,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[YT,fW],null),new $CLJS.P(null,2,5,$CLJS.Q,[Cca,fW],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oR,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),Uea],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FS,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,EY],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.F.g(1,$CLJS.E($CLJS.bh(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wU,$CLJS.TS],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,
new $CLJS.k(null,1,[$CLJS.Au,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.Ge(a);a=$CLJS.J.g(b,$CLJS.uS);b=$CLJS.J.g(b,$CLJS.WI);return $CLJS.ud($CLJS.Qv.g($CLJS.ih(a),$CLJS.ih(b)))}],null)],null));
$CLJS.MY=$CLJS.Ig([$CLJS.UU,$CLJS.pT,PR,AR,GS,RV,lS,$CLJS.QV,$CLJS.vv,kS,xQ,KU,GV,$CLJS.MQ,CV,MR,$CLJS.LS,iS,$CLJS.Yi,$CLJS.hS,eQ,$CLJS.jR,$CLJS.gS,kT,$CLJS.Ct,$CLJS.HE,cS],[new $CLJS.k(null,1,[wS,new $CLJS.dh(null,new $CLJS.k(null,5,[$CLJS.UU,null,$CLJS.pT,null,$CLJS.vv,null,$CLJS.Ct,null,$CLJS.HE,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.lj,$CLJS.pT,wS,new $CLJS.dh(null,new $CLJS.k(null,5,[$CLJS.UU,null,$CLJS.pT,null,$CLJS.QV,null,$CLJS.LS,null,$CLJS.Yi,null],null),null)],null),new $CLJS.k(null,
3,[$CLJS.lj,$CLJS.Xj,$CLJS.yI,TU,wS,new $CLJS.dh(null,new $CLJS.k(null,1,[PR,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.lj,VV,$CLJS.yI,TU,wS,new $CLJS.dh(null,new $CLJS.k(null,5,[$CLJS.UU,null,AR,null,$CLJS.vv,null,$CLJS.Yi,null,eQ,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.lj,$CLJS.Xj,$CLJS.yI,AQ,wS,new $CLJS.dh(null,new $CLJS.k(null,1,[GS,null],null),null)],null),new $CLJS.k(null,1,[wS,new $CLJS.dh(null,new $CLJS.k(null,1,[RV,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.lj,
VV,$CLJS.yI,Sda,wS,new $CLJS.dh(null,new $CLJS.k(null,1,[lS,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.lj,$CLJS.pT,wS,new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.QV,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.lj,VV,wS,new $CLJS.dh(null,new $CLJS.k(null,5,[$CLJS.UU,null,AR,null,$CLJS.vv,null,$CLJS.Yi,null,eQ,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.lj,VV,$CLJS.yI,AQ,wS,new $CLJS.dh(null,new $CLJS.k(null,1,[kS,null],null),null)],null),new $CLJS.k(null,1,[wS,new $CLJS.dh(null,
new $CLJS.k(null,1,[xQ,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.lj,$CLJS.Xj,$CLJS.yI,TU,wS,new $CLJS.dh(null,new $CLJS.k(null,8,[$CLJS.UU,null,RV,null,xQ,null,KU,null,iS,null,$CLJS.Yi,null,eQ,null,$CLJS.HE,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.lj,$CLJS.Xj,$CLJS.yI,AQ,wS,new $CLJS.dh(null,new $CLJS.k(null,1,[GV,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.lj,$CLJS.pT,wS,new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.QV,null,$CLJS.MQ,null],null),null)],null),new $CLJS.k(null,
3,[$CLJS.lj,$CLJS.Xj,$CLJS.yI,AQ,wS,new $CLJS.dh(null,new $CLJS.k(null,1,[CV,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.lj,$CLJS.Xj,$CLJS.yI,AQ,wS,new $CLJS.dh(null,new $CLJS.k(null,1,[MR,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.lj,$CLJS.pT,wS,new $CLJS.dh(null,new $CLJS.k(null,5,[$CLJS.UU,null,$CLJS.pT,null,$CLJS.QV,null,$CLJS.LS,null,$CLJS.Yi,null],null),null)],null),new $CLJS.k(null,1,[wS,new $CLJS.dh(null,new $CLJS.k(null,1,[iS,null],null),null)],null),new $CLJS.k(null,1,[wS,
new $CLJS.dh(null,new $CLJS.k(null,1,[$CLJS.Yi,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.lj,$CLJS.pT,wS,new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.QV,null,$CLJS.hS,null],null),null)],null),new $CLJS.k(null,1,[wS,new $CLJS.dh(null,new $CLJS.k(null,1,[eQ,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.lj,$CLJS.pT,wS,new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.QV,null,$CLJS.jR,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.lj,$CLJS.pT,wS,new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.QV,
null,$CLJS.gS,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.lj,VV,$CLJS.yI,TU,wS,new $CLJS.dh(null,new $CLJS.k(null,1,[kT,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.lj,$CLJS.Ct,wS,new $CLJS.dh(null,new $CLJS.k(null,3,[$CLJS.UU,null,$CLJS.Yi,null,$CLJS.Ct,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.lj,$CLJS.Xj,wS,new $CLJS.dh(null,new $CLJS.k(null,8,[$CLJS.UU,null,RV,null,xQ,null,KU,null,iS,null,$CLJS.Yi,null,eQ,null,$CLJS.HE,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.lj,
VV,$CLJS.yI,AQ,wS,new $CLJS.dh(null,new $CLJS.k(null,1,[cS,null],null),null)],null)]);$CLJS.X(gV,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"valid parameter type"],null)],null),$CLJS.Xg($CLJS.MY)));$CLJS.X(IQ,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"valid template tag widget type"],null),$CLJS.Xu],null),$CLJS.Xg($CLJS.MY)));
$CLJS.X(qU,KP(tca,$CLJS.G(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,cW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yi,cW],null)],null)],null)])));var NY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qU],null);$CLJS.X(hQ,KP($CLJS.SP,$CLJS.G(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,$CLJS.BW,NY],null)])));var OY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hQ],null);$CLJS.X(dR,KP(fS,$CLJS.G(["target",NY])));
var PY=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dR],null),Vea=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,$CLJS.BW,LP($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof OY?new $CLJS.Gc(function(){return OY},$CLJS.qd(qda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bU,"metabase/mbql/schema.cljc",21,$CLJS.SP,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SP,$CLJS.FN,$CLJS.V($CLJS.Oj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,qR,vS],null))],null),$CLJS.Kc,"Schema for a valid dimension clause.",$CLJS.n(OY)?OY.H:null])):null));return $CLJS.n(a)?a:bU}(),OY],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=XS.h($CLJS.rd("undefined"!==typeof $CLJS.NP&&
"undefined"!==typeof OP&&"undefined"!==typeof PP&&"undefined"!==typeof PY?new $CLJS.Gc(function(){return PY},$CLJS.qd(Gda,new $CLJS.k(null,1,[$CLJS.Zj,!0],null)),$CLJS.Ig([$CLJS.Vi,$CLJS.S,$CLJS.kk,$CLJS.zi,XS,$CLJS.Ui,$CLJS.mj,$CLJS.rk,OQ,$CLJS.yi,$CLJS.T,$CLJS.jk],[$CLJS.qd(EU,new $CLJS.k(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WV,"metabase/mbql/schema.cljc",20,fS,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[fS,$CLJS.FN,
$CLJS.V($CLJS.Oj,vS)],null),$CLJS.Kc,"Schema for a valid variable clause.",$CLJS.n(PY)?PY.H:null])):null));return $CLJS.n(a)?a:WV}(),PY],null)]))],null),GP=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PQ],null);
$CLJS.X(PQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.st,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.wP($CLJS.WD($CLJS.XT,$CLJS.rQ),$CLJS.Oe($CLJS.wP($CLJS.XT,$CLJS.rQ)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.Ge(b);b=$CLJS.J.g(c,$CLJS.XT);var d=$CLJS.J.g(c,$CLJS.rQ);c=$CLJS.J.g(c,$CLJS.lj);c=c instanceof $CLJS.M?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IT,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lt,new $CLJS.k(null,1,[$CLJS.Au,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aP],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.$O],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ht,$CLJS.rQ,$CLJS.XT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XT,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),Oea],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rQ,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),CY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eR,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.P(null,
8,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,Lea],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),cW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FN,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),Vea],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Dt],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),cW],null),new $CLJS.P(null,3,5,$CLJS.Q,[zca,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),
cW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Dt],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xU,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,3,5,$CLJS.Q,[qea,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),pW],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[pda,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,3,5,$CLJS.Q,[cT,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),gW],null),new $CLJS.P(null,3,5,$CLJS.Q,[HQ,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),gW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.Ge(a);a=$CLJS.J.g(b,cT);b=$CLJS.J.g(b,HQ);return $CLJS.Ta($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[fda,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,3,5,$CLJS.Q,[aea,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Ct],null),new $CLJS.P(null,3,5,$CLJS.Q,[vca,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Ct],null),new $CLJS.P(null,3,5,$CLJS.Q,[eca,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Ct],null),new $CLJS.P(null,3,5,$CLJS.Q,[Bca,new $CLJS.k(null,1,[$CLJS.Jt,
!0],null),$CLJS.Ct],null),new $CLJS.P(null,3,5,$CLJS.Q,[Tda,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.Ct],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[gca,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.Ct],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[gea,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.Ct],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qC,new $CLJS.k(null,
1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$Q,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.ht,Fda,Cda,$CLJS.ZR,rda,Hda,dda,qca,wca,Fca,Sca,Qda,yea,Wca,Oca],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Wda,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,fW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[ada,
new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,fW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SU,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,zea],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[rea,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,cW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[cda,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,fW],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[pea,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qt,$CLJS.Dt,$CLJS.Dt],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Xca,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,fW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bS,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qt,$CLJS.Dt,
$CLJS.Dt],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YP,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.Dt],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Oe($CLJS.FS)],null)],null));var QY=$CLJS.FP(GP);
(function(){var a=$ba();return function(b){if($CLJS.n(QY.h?QY.h(b):QY.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.CP(b);throw $CLJS.li($CLJS.KI("Invalid query: {0}",$CLJS.G([$CLJS.Ph.l($CLJS.G([c]))])),new $CLJS.k(null,2,[$CLJS.kC,c,kda,b],null));}})();