var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.join.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Lja,c6,Mja,Nja,Oja,e6,f6,g6,h6,Pja,Qja,k6,i6,j6,l6,m6,Rja,Sja,Tja,n6,Uja,Vja,o6,Wja,Xja;Lja=function(a,b){return $CLJS.E(a)<=$CLJS.E(b)&&$CLJS.Me(function(c){return $CLJS.Id(b,c)},a)};c6=function(a,b){if(b=$CLJS.z(b)){var c=$CLJS.z(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.z(c)?(c=c6($CLJS.J.g(a,b),c),$CLJS.ud(c)?$CLJS.Ak.g(a,b):$CLJS.R.j(a,b,c)):$CLJS.Ak.g(a,b)}return a};
Mja=function(a,b,c,d){return function t(f,h,l,m){var u=$CLJS.z(h);h=$CLJS.B(u);u=$CLJS.C(u);var v=$CLJS.Wl(f,h);return $CLJS.n(v)?u?$CLJS.R.j(f,h,t($CLJS.Bb(v),u,l,m)):$CLJS.R.j(f,h,$CLJS.O.j(l,$CLJS.Bb(v),m)):f}(a,b,c,d)};Nja=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return Mja(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.y(b.slice(3),0,null):null)};$CLJS.d6=function(a){return $CLJS.BI.h($CLJS.II(a))};
Oja=function(a,b,c,d){var e=$CLJS.F.g($CLJS.NT,$CLJS.B(b))?$CLJS.s_(d,$CLJS.r_(c)):d;return Nja(a,b,function(f){return $CLJS.Cf(function(){return function m(l){return new $CLJS.me(null,function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.Bd(t)){var u=$CLJS.kc(t),v=$CLJS.E(u),x=$CLJS.qe(v);a:for(var A=0;;)if(A<v){var D=$CLJS.md(u,A);D=$CLJS.F.g($CLJS.d6(D),$CLJS.d6(c))?e:D;x.add(D);A+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),m($CLJS.lc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.B(t);return $CLJS.de($CLJS.F.g($CLJS.d6(x),
$CLJS.d6(c))?e:x,m($CLJS.Lc(t)))}return null}},null,null)}(f)}())})};
e6=function(a,b,c){var d=$CLJS.XD(a,b);if($CLJS.n(d)){var e=$CLJS.d6(c),f=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.B(b),$CLJS.kd(b)],null);c=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);d=$CLJS.eg.j($CLJS.Bf,$CLJS.Ik.h($CLJS.Hk.g($CLJS.fh([e]),$CLJS.d6)),d);d=$CLJS.F.g(b,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.WI],null))&&$CLJS.Me(function(h){return $CLJS.b4(h,$CLJS.zE)},d)?null:d;if($CLJS.z(d))return $CLJS.DP(a,b,d);if($CLJS.F.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oR,$CLJS.lP],null),new $CLJS.P(null,2,5,$CLJS.Q,
[c,f],null)))throw $CLJS.li($CLJS.LI("Cannot remove the final join condition"),new $CLJS.k(null,1,[$CLJS.lP,$CLJS.XD(a,b)],null));return $CLJS.F.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oR,$CLJS.WI],null),new $CLJS.P(null,2,5,$CLJS.Q,[c,f],null))?$CLJS.tZ.v(a,$CLJS.td(b),$CLJS.Ak,f):c6(a,b)}return a};f6=function(a,b){a=$CLJS.Ge(a);a=$CLJS.J.g(a,$CLJS.iP);b=0>b?$CLJS.E(a)+b:b;return b+1<$CLJS.E(a)?b+1:null};
g6=function(a,b){var c=$CLJS.C4.g(a,b),d=$CLJS.oh(0,$CLJS.E(c));return $CLJS.cf.l(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.DV],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.uS],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.XZ],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.WI],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.aK],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.NT],null)],null),function(){return function h(f){return new $CLJS.me(null,function(){for(var l=f;;)if(l=$CLJS.z(l)){if($CLJS.Bd(l)){var m=
$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v),A=$CLJS.cd(c,x);$CLJS.WI.h(A)instanceof $CLJS.M||u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oR,x,$CLJS.WI],null));v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),h($CLJS.lc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.B(l);m=$CLJS.cd(c,u);if($CLJS.WI.h(m)instanceof $CLJS.M)l=$CLJS.Lc(l);else return $CLJS.de(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oR,u,$CLJS.WI],null),h($CLJS.Lc(l)))}else return null},null,null)}(d)}(),$CLJS.G([function(){return function h(f){return new $CLJS.me(null,
function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.Bd(l)){var m=$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v);u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oR,x,$CLJS.lP],null));v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),h($CLJS.lc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.B(l);return $CLJS.de(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oR,u,$CLJS.lP],null),h($CLJS.Lc(l)))}return null}},null,null)}(d)}()]))};
h6=function(a,b,c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);e=$CLJS.Ge(e);var f=$CLJS.J.g(e,$CLJS.rK),h=$CLJS.J.g(e,$CLJS.kV),l=$CLJS.I(c,2,null);return $CLJS.B($CLJS.NL(function(m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);$CLJS.I(m,0,null);$CLJS.I(m,1,null);m=$CLJS.I(m,2,null);return $CLJS.F.g($CLJS.B(m),d)&&$CLJS.F.g($CLJS.rK.h($CLJS.jd(m)),f)&&$CLJS.F.g($CLJS.kV.h($CLJS.jd(m)),h)&&$CLJS.F.g($CLJS.kd(m),l)},$CLJS.Vl($CLJS.Gt,$CLJS.DV.h($CLJS.z0(a,b)))))};
Pja=function(a,b,c,d){c=h6(a,b,c);return $CLJS.n(c)?$CLJS.J_.l(a,b,$CLJS.tZ,$CLJS.G([new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.DV,c,2,1],null),$CLJS.Hk.g(function(e){return $CLJS.dE($CLJS.Oe($CLJS.Ra),e)},$CLJS.Zk),d])):a};Qja=function(a,b,c){c=h6(a,b,c);return $CLJS.n(c)?$CLJS.J_.l(a,b,e6,$CLJS.G([new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.DV],null),$CLJS.XD($CLJS.z0(a,b),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DV,c],null))])):a};
k6=function(a,b,c,d,e,f){var h=$CLJS.J_.l(a,b,f,$CLJS.G([d,e])),l=$CLJS.d6(e);return $CLJS.ah.g(a,h)?$CLJS.B($CLJS.z($CLJS.jf($CLJS.Ua,function(m,t){try{if($CLJS.Ad(t)&&1===$CLJS.E(t))try{var u=$CLJS.cd(t,0);if($CLJS.ge(u,$CLJS.NT))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var K=$CLJS.r_(e);K=i6.W?i6.W(h,b,c,$CLJS.zE,$CLJS.N,K):i6.call(null,h,b,c,$CLJS.zE,$CLJS.N,K);return j6.v?j6.v(K,b,c,l):j6.call(null,K,b,c,l)}()],null);throw $CLJS.Y;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Y)try{u=
$CLJS.cd(t,0);if($CLJS.ge(u,$CLJS.aK))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var U=i6.W?i6.W(h,b,c,$CLJS.aK,$CLJS.N,l):i6.call(null,h,b,c,$CLJS.aK,$CLJS.N,l);return j6.v?j6.v(U,b,c,l):j6.call(null,U,b,c,l)}()],null);throw $CLJS.Y;}catch(U){if(U instanceof Error){var v=U;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw U;}else throw m;else throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Y)try{if($CLJS.Ad(t)&&1===$CLJS.E(t))try{var x=$CLJS.cd(t,0);if($CLJS.ge(x,$CLJS.uS))return new $CLJS.P(null,
1,5,$CLJS.Q,[j6.v?j6.v(h,b,c,l):j6.call(null,h,b,c,l)],null);throw $CLJS.Y;}catch(U){if(U instanceof Error)if(v=U,v===$CLJS.Y)try{x=$CLJS.cd(t,0);if($CLJS.ge(x,$CLJS.WI))return new $CLJS.P(null,1,5,$CLJS.Q,[j6.v?j6.v(h,b,c,l):j6.call(null,h,b,c,l)],null);throw $CLJS.Y;}catch(Z){if(Z instanceof Error){var A=Z;if(A===$CLJS.Y)throw $CLJS.Y;throw A;}throw Z;}else throw v;else throw U;}else throw $CLJS.Y;}catch(U){if(U instanceof Error)if(v=U,v===$CLJS.Y)try{if($CLJS.Ad(t)&&3===$CLJS.E(t))try{var D=$CLJS.cd(t,
0);if($CLJS.ge(D,$CLJS.oR))try{var H=$CLJS.cd(t,2);if($CLJS.ge(H,$CLJS.WI))return new $CLJS.P(null,1,5,$CLJS.Q,[j6.v?j6.v(h,b,c,l):j6.call(null,h,b,c,l)],null);throw $CLJS.Y;}catch(Z){if(Z instanceof Error){A=Z;if(A===$CLJS.Y)throw $CLJS.Y;throw A;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){A=Z;if(A===$CLJS.Y)throw $CLJS.Y;throw A;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){A=Z;if(A===$CLJS.Y)return new $CLJS.P(null,1,5,$CLJS.Q,[h],null);throw A;}throw Z;}else throw v;
else throw U;}else throw m;else throw K;}}($CLJS.Bf,d)))):h};
i6=function(a,b,c,d,e,f){var h=$CLJS.z0(a,b),l=$CLJS.gf(function(m){var t=$CLJS.XD(h,m);return $CLJS.n(t)?$CLJS.Ul(function(u){return $CLJS.B($CLJS.z($CLJS.jf($CLJS.Ua,function D(x,A){try{if($CLJS.Ad(A)&&3===$CLJS.E(A))try{var H=$CLJS.cd(A,0);if($CLJS.F.g(H,d))try{var K=$CLJS.cd(A,1);if($CLJS.ud(e)||Lja($CLJS.ih(e),$CLJS.ih(K)))try{var U=$CLJS.cd(A,2);if($CLJS.F.g(U,f))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null)],null);throw $CLJS.Y;}catch(ca){if(ca instanceof Error){var Z=
ca;if(Z===$CLJS.Y)throw $CLJS.Y;throw Z;}throw ca;}else throw $CLJS.Y;}catch(ca){if(ca instanceof Error){Z=ca;if(Z===$CLJS.Y)throw $CLJS.Y;throw Z;}throw ca;}else throw $CLJS.Y;}catch(ca){if(ca instanceof Error){Z=ca;if(Z===$CLJS.Y)throw $CLJS.Y;throw Z;}throw ca;}else throw $CLJS.Y;}catch(ca){if(ca instanceof Error){Z=ca;if(Z===$CLJS.Y)return $CLJS.rZ(D,x,A);throw Z;}throw ca;}}($CLJS.Bf,u))))},t):null},$CLJS.G([g6(a,b)]));return $CLJS.bb(function(m,t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);
return k6(m,b,c,u,t,e6)},a,l)};j6=function(a,b,c,d){b=f6(c,b);if($CLJS.n(b)){var e=$CLJS.z0(c,b);c=$CLJS.Ne(function(f){f=$CLJS.Ge(f);var h=$CLJS.J.g(f,$CLJS.B_),l=$CLJS.J.g(f,$CLJS.h3);return $CLJS.F.g($CLJS.G0,h)&&$CLJS.F.g(d,l)?$CLJS.N0.h(f):null},$CLJS.M1.j(c,b,e));return $CLJS.n(c)?i6(a,b,a,$CLJS.lK,$CLJS.N,c):a}return a};
l6=function(a,b,c,d,e){var f=$CLJS.k_.h(c),h=$CLJS.z0(a,b);c=$CLJS.NL(function(t){var u=$CLJS.XD(h,t);if($CLJS.n(u)){var v=$CLJS.d6(f);return $CLJS.n($CLJS.Ne($CLJS.Hk.j($CLJS.fh([v]),$CLJS.BI,$CLJS.jd),u))?t:null}return null},g6(a,b));var l=(d=$CLJS.F.g($CLJS.KK,d))?$CLJS.k_.h(e):null;e=d?function(t,u,v){return Oja(t,u,v,l)}:e6;var m=$CLJS.F.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.uS],null),c);a=d&&m&&$CLJS.F.g($CLJS.B(f),$CLJS.B(l))&&$CLJS.F.g($CLJS.kd(f),$CLJS.kd(l))?Pja(a,b,f,$CLJS.bh($CLJS.jd(l),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kV,$CLJS.rK],null))):m?Qja(a,b,f):a;return $CLJS.n(c)?k6(a,b,a,c,f,e):a};m6=function(a,b){return $CLJS.b4(a,$CLJS.lK)&&$CLJS.F.g($CLJS.i4(a),b)};Rja=function(a,b,c){return function h(e,f){try{if(m6(f,b))return $CLJS.j4(f,c);throw $CLJS.Y;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Y)return $CLJS.sZ(h,e,f);throw l;}throw m;}}($CLJS.Bf,a)};
Sja=function(a,b,c){var d=$CLJS.oR.h(a),e=-1<b&&b<$CLJS.E(d)?new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.XD(d,new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.$I],null))],null):null;b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.ah.g(e,c):b)){var f=$CLJS.A_();$CLJS.a4(f,$CLJS.ff.g($CLJS.$I,d));c=f(c);return Rja($CLJS.DP(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oR,b,$CLJS.$I],null),c),e,c)}return a};
Tja=function(a,b,c){if($CLJS.Gd(c))return c;var d=function(){var e=$CLJS.fh([c]);return"string"===typeof c?$CLJS.Hk.g(e,$CLJS.$I):e}();return $CLJS.Ne(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.Vl($CLJS.Gt,$CLJS.oR.h($CLJS.z0(a,b))))};
n6=function(a,b,c,d){b=$CLJS.Pv.g($CLJS.ih($CLJS.M1.j(b,c,$CLJS.z0(b,c))),$CLJS.ih($CLJS.M1.j(a,c,$CLJS.z0(a,c))));return $CLJS.bb(function(e,f){return $CLJS.O.N(i6,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};Uja=function(a,b,c){a=n6(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lK,new $CLJS.k(null,1,[$CLJS.hT,$CLJS.h4.h(d)],null),$CLJS.Yi.h(d)],null)});c=f6(a,c);return $CLJS.n(c)?n6(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lK,$CLJS.N,$CLJS.N0.h(d)],null)}):a};
Vja=function(a,b,c){return $CLJS.Gd(c)?$CLJS.XD($CLJS.z0(a,b),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oR,c,$CLJS.$I],null)):$CLJS.yd(c)?$CLJS.$I.h(c):c};o6=function(a,b,c,d){var e=Vja(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.J_)(a,b,function(h){var l=$CLJS.oR.h(h);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.d_(h,$CLJS.oR,l)});return(0,$CLJS.J_)(f,b,function(h){return $CLJS.uZ.j(h,$CLJS.oR,function(l){return $CLJS.Jk.g(function(m){return $CLJS.r4(f,b,m)},l)})})}(),Uja(c,a,b)):a};
Wja=function(a,b){return null!=$CLJS.B($CLJS.z($CLJS.jf($CLJS.Ua,function f(d,e){try{if(m6(e,b))return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(l){if(l instanceof Error){var h=l;if(h===$CLJS.Y)return $CLJS.rZ(f,d,e);throw h;}throw l;}}($CLJS.Bf,a))))};Xja=new $CLJS.M(null,"remove","remove",-131428414);$CLJS.Yja=function(){function a(d,e,f){return $CLJS.yd(f)&&$CLJS.F.g($CLJS.Dj.h(f),$CLJS.dP)?$CLJS.p6.j?$CLJS.p6.j(d,e,f):$CLJS.p6.call(null,d,e,f):l6(d,e,f,Xja,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Zja=function(){function a(d,e,f,h){return $CLJS.yd(f)&&$CLJS.F.g($CLJS.Dj.h(f),$CLJS.dP)?$CLJS.q6.v?$CLJS.q6.v(d,e,f,h):$CLJS.q6.call(null,d,e,f,h):l6(d,e,f,$CLJS.KK,h)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,h){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,h)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.$ja=function(){function a(d,e,f,h){f=Tja(d,e,f);return $CLJS.n(f)?$CLJS.J_.l(d,e,Sja,$CLJS.G([f,h])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,h){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,h)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.p6=function(){function a(d,e,f){return o6(d,e,f,function(h,l){return $CLJS.He($CLJS.iZ(function(m){return!($CLJS.F.g($CLJS.$I.h(m),l)||Wja(m,l))},h))})}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.q6=function(){function a(d,e,f,h){return null==h?$CLJS.p6.j(d,e,f):o6(d,e,f,function(l,m){return $CLJS.Jk.g(function(t){return $CLJS.F.g($CLJS.$I.h(t),m)?h:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,h){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,h)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();