var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var OL,PL,qaa,RL,raa,saa,taa,SL,QL;$CLJS.ML=function(a,b){return $CLJS.fc($CLJS.bb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.ae.g($CLJS.J.j(c,e,$CLJS.Bf),d))},$CLJS.cc($CLJS.N),b))};$CLJS.NL=function(a,b){return $CLJS.bb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
OL=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.QF(b,$CLJS.wG)?$CLJS.yL:$CLJS.QF(b,$CLJS.UH)?$CLJS.tL:$CLJS.QF(b,$CLJS.eH)?$CLJS.vL:null;return $CLJS.n(b)?$CLJS.wJ(b,a):!0};
PL=function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);a=$CLJS.ML(function(d){return $CLJS.QF($CLJS.OI(d),$CLJS.PG)},a);var b=$CLJS.Ge(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.ah.g($CLJS.E(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.E(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.OI($CLJS.B(a));return $CLJS.Ne(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(OL(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
qaa=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.st,new $CLJS.k(null,1,[$CLJS.Au,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QL],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QL],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Cu,function(b){b=$CLJS.Ge(b);b=$CLJS.J.g(b,$CLJS.Cj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(PL(b))].join("")}],null),$CLJS.Oe(PL)],null)],null)};
RL=function(a){var b=$CLJS.Q,c=qaa(a);a=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ys,new $CLJS.k(null,1,[$CLJS.Au,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ws,new $CLJS.k(null,1,[$CLJS.On,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pJ],null)],null)],null)],null);
return new $CLJS.P(null,3,5,b,[$CLJS.lt,c,a],null)};raa=function(a){return $CLJS.Qd($CLJS.zJ,$CLJS.ff.g(function(b){var c=$CLJS.OI(b),d=$CLJS.QF(c,$CLJS.cJ);b=d?$CLJS.wJ($CLJS.pK,b):d;return $CLJS.n(b)?$CLJS.Nj:c},a))};saa=function(a){a=$CLJS.NL(function(b){return!$CLJS.QF(b,$CLJS.PG)},$CLJS.ff.g($CLJS.OI,a));return $CLJS.QF(a,$CLJS.cJ)?$CLJS.Uj:a};taa=function(a){return $CLJS.n($CLJS.Ne(function(b){return $CLJS.QF($CLJS.OI(b),$CLJS.PG)},a))?saa(a):raa(a)};
SL=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.TL=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);QL=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.at,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,QL],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);var c=$CLJS.OI(a);return $CLJS.Me(function(d){return OL(d,c)},b)}],null)],null));
$CLJS.X(SL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ws,new $CLJS.k(null,1,[$CLJS.On,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pJ],null)],null)],null));$CLJS.MK.g($CLJS.Os,RL($CLJS.Os));$CLJS.MK.g($CLJS.qu,RL($CLJS.qu));$CLJS.GJ($CLJS.Ps,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,SL],null)]));$CLJS.GJ($CLJS.HK,$CLJS.G([$CLJS.qu,$CLJS.OH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,SL],null)]));
for(var UL=$CLJS.z(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,$CLJS.qu,$CLJS.Ps],null)),VL=null,WL=0,ZL=0;;)if(ZL<WL){var uaa=VL.X(null,ZL);$CLJS.xJ(uaa,$CLJS.TL);ZL+=1}else{var $L=$CLJS.z(UL);if($L){var aM=$L;if($CLJS.Bd(aM)){var bM=$CLJS.kc(aM),vaa=$CLJS.lc(aM),waa=bM,xaa=$CLJS.E(bM);UL=vaa;VL=waa;WL=xaa}else{var yaa=$CLJS.B(aM);$CLJS.xJ(yaa,$CLJS.TL);UL=$CLJS.C(aM);VL=null;WL=0}ZL=0}else break}$CLJS.NI.m(null,$CLJS.TL,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);return taa(a)});
$CLJS.EJ($CLJS.OJ,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pJ],null)],null)]));$CLJS.xJ($CLJS.OJ,$CLJS.iJ);
for(var cM=$CLJS.z(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JK,$CLJS.HJ,$CLJS.$J],null)),dM=null,eM=0,fM=0;;)if(fM<eM){var zaa=dM.X(null,fM);$CLJS.EJ(zaa,$CLJS.G([$CLJS.qu,$CLJS.OH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pJ],null)],null)]));fM+=1}else{var gM=$CLJS.z(cM);if(gM){var hM=gM;if($CLJS.Bd(hM)){var iM=$CLJS.kc(hM),Aaa=$CLJS.lc(hM),Baa=iM,Caa=$CLJS.E(iM);cM=Aaa;dM=Baa;eM=Caa}else{var Daa=$CLJS.B(hM);$CLJS.EJ(Daa,$CLJS.G([$CLJS.qu,$CLJS.OH,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pJ],null)],null)]));cM=$CLJS.C(hM);dM=null;eM=0}fM=0}else break}
for(var jM=$CLJS.z(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KJ,$CLJS.jK,$CLJS.qK],null)),kM=null,lM=0,mM=0;;)if(mM<lM){var Eaa=kM.X(null,mM);$CLJS.EJ(Eaa,$CLJS.G([$CLJS.qu,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pJ],null)],null)]));mM+=1}else{var nM=$CLJS.z(jM);if(nM){var oM=nM;if($CLJS.Bd(oM)){var pM=$CLJS.kc(oM),Faa=$CLJS.lc(oM),Gaa=pM,Haa=$CLJS.E(pM);jM=Faa;kM=Gaa;lM=Haa}else{var Iaa=$CLJS.B(oM);$CLJS.EJ(Iaa,$CLJS.G([$CLJS.qu,$CLJS.vj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pJ],null)],null)]));jM=$CLJS.C(oM);kM=null;lM=0}mM=0}else break}$CLJS.EJ($CLJS.LJ,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pJ],null)],null)]));
$CLJS.NI.m(null,$CLJS.LJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.QF($CLJS.OI(b),$CLJS.vj)&&$CLJS.QF($CLJS.OI(a),$CLJS.vj)?$CLJS.vj:$CLJS.OH});