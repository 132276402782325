var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.util.malli.registry.js");
'use strict';var HM,IM,Waa,Xaa,Yaa,LM,MM,NM,PM,Zaa,RM;HM=function(a){switch(arguments.length){case 2:return $CLJS.wJ(arguments[0],arguments[1]);case 3:return $CLJS.vJ(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};IM=function(a){return["(?:",$CLJS.p.h($CLJS.O.g($CLJS.p,a)),")?"].join("")};Waa=new $CLJS.M("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
Xaa=new $CLJS.M("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.JM=new $CLJS.M("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);Yaa=new $CLJS.M("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);$CLJS.KM=new $CLJS.M("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
LM=new $CLJS.M("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);MM=new $CLJS.M("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);NM=new $CLJS.M("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);$CLJS.OM=new $CLJS.M("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);
PM=new $CLJS.M("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);Zaa=new $CLJS.M("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.QM=new $CLJS.M("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);RM=new $CLJS.M("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);
$CLJS.SM=new $CLJS.M("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.NI.m(null,$CLJS.GE,function(){return $CLJS.zj});$CLJS.X(RM,$CLJS.Ct);$CLJS.NI.m(null,$CLJS.KE,function(){return $CLJS.Jj});$CLJS.X(RM,$CLJS.Ct);$CLJS.X(Zaa,$CLJS.yt);$CLJS.NI.m(null,$CLJS.uE,function(){return $CLJS.vj});$CLJS.X(Xaa,$CLJS.xt);$CLJS.NI.m(null,$CLJS.sE,function(){return $CLJS.OH});$CLJS.X(Yaa,$CLJS.Xj);
var $aa=[":\\d{2}",IM($CLJS.G(["\\.\\d{1,6}"]))].join(""),TM=["\\d{2}:\\d{2}",IM($CLJS.G([$aa]))].join(""),UM=["\\d{4}-\\d{2}-\\d{2}T",TM].join(""),VM=["(?:Z|(?:[+-]",TM,"))"].join(""),aba=$CLJS.sh("^\\d{4}-\\d{2}-\\d{2}$"),bba=$CLJS.sh(["^",TM,"$"].join("")),cba=$CLJS.sh(["^",TM,VM,"$"].join("")),dba=$CLJS.sh(["^",UM,"$"].join("")),eba=$CLJS.sh(["^",UM,VM,"$"].join(""));$CLJS.X(MM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.Au,"date string literal"],null),aba],null));
$CLJS.X(LM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.Au,"local time string literal"],null),bba],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.Au,"offset time string literal"],null),cba],null)],null));
$CLJS.X(NM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.Au,"local date time string literal"],null),dba],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.Au,"offset date time string literal"],null),eba],null)],null));
$CLJS.NI.m(null,$CLJS.tE,function(a){return $CLJS.n($CLJS.wJ?$CLJS.wJ(NM,a):HM.call(null,NM,a))?new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.bk,null,$CLJS.eH,null],null),null):$CLJS.n($CLJS.wJ?$CLJS.wJ(MM,a):HM.call(null,MM,a))?new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.wG,null,$CLJS.bk,null],null),null):$CLJS.n($CLJS.wJ?$CLJS.wJ(LM,a):HM.call(null,LM,a))?new $CLJS.dh(null,new $CLJS.k(null,2,[$CLJS.UH,null,$CLJS.bk,null],null),null):$CLJS.bk});$CLJS.X($CLJS.OM,MM);$CLJS.X($CLJS.KM,LM);
$CLJS.X($CLJS.SM,NM);$CLJS.X(Waa,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lt,$CLJS.OM,$CLJS.KM,$CLJS.SM],null));$CLJS.WM=$CLJS.sh("^\\d{4}-\\d{2}$");$CLJS.X($CLJS.QM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.Au,"year-month string literal"],null),$CLJS.WM],null));$CLJS.XM=$CLJS.sh("^\\d{4}$");$CLJS.X($CLJS.JM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.k(null,1,[$CLJS.Au,"year string literal"],null),$CLJS.XM],null));
$CLJS.X(PM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ii,$CLJS.EI],null)],null)],null));$CLJS.MK.g($CLJS.Cj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ot,new $CLJS.k(null,1,[$CLJS.Au,"Value :value clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.Cj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PM],null),$CLJS.ul],null));