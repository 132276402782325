var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var RY,SY,TY,VY,WY,XY,YY,ZY,$Y,aZ;RY=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);SY=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);TY=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.UY=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);VY=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);WY=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);XY=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
YY=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);ZY=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);$Y=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);aZ=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(RY,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ht,new $CLJS.k(null,1,[$CLJS.Au,"Valid template tag :widget-type"],null),$CLJS.Xu],null),$CLJS.Xg($CLJS.MY)));$CLJS.X(VY,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ht,$CLJS.pQ,$CLJS.fR,$CLJS.SP,$CLJS.vv,$CLJS.HE,$CLJS.pT],null));
$CLJS.X(YY,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,$CLJS.vI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qI,$CLJS.vI],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,$CLJS.vI,$CLJS.Bt],null)],null)],null));
$CLJS.X(ZY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.ul],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IR,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.Ct],null)],null)],null));
$CLJS.X(WY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZY],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.SP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SP,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.k(null,1,[$CLJS.Jt,!0],
null),$CLJS.fk],null)],null)],null));$CLJS.X(XY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YY],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.pQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bQ,$CLJS.vI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HT,$CLJS.bP],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IT,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.$O],null)],null)],null));
$CLJS.X(aZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.fR],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SU,$CLJS.ZO],null)],null)],null));$CLJS.X($Y,$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ht],null),$CLJS.AY));
$CLJS.X(SY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZY],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$Y],null)],null)],null)],null));
$CLJS.X(TY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VY],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,1,[$CLJS.Qi,$CLJS.lj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SP,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WY],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pQ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XY],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fR,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aZ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Po,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SY],null)],null)],null)],null));
$CLJS.X($CLJS.UY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qt,$CLJS.vI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Me(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.F.g(c,$CLJS.S.h(b))},a)}],null)],null));