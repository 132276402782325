var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var OK,QK,RK,UK,WK,XK,YK,ZK,$K,aL,bL,cL,dL,eL,fL,hL,iL,aaa;$CLJS.NK=new $CLJS.M(null,"supported-field","supported-field",-2061545519);OK=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.PK=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);QK=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);RK=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);
$CLJS.SK=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.TK=new $CLJS.M(null,"column-name","column-name",551523580);UK=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.VK=new $CLJS.M(null,"display-info","display-info",-816930907);WK=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);XK=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
YK=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);ZK=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);$K=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);aL=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);bL=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);cL=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
dL=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);eL=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);fL=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.gL=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);hL=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);iL=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.jL=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);aaa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.GJ($CLJS.TA,$CLJS.G([$CLJS.qu,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bJ],null)],null)],null)],null)]));$CLJS.GJ($CLJS.mK,$CLJS.G([$CLJS.qu,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bJ],null)],null)],null)],null)]));
$CLJS.EJ($CLJS.fK,$CLJS.G([$CLJS.qu,$CLJS.OH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pJ],null)],null)]));$CLJS.EJ($CLJS.SJ,$CLJS.G([$CLJS.qu,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bJ],null)],null)]));$CLJS.EJ($CLJS.uK,$CLJS.G([$CLJS.qu,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aJ],null)],null)]));
$CLJS.EJ($CLJS.gk,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jJ],null)],null)]));$CLJS.xJ($CLJS.gk,$CLJS.iJ);$CLJS.EJ($CLJS.zK,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pJ],null)],null)]));$CLJS.xJ($CLJS.zK,$CLJS.iJ);$CLJS.EJ($CLJS.On,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jJ],null)],null)]));$CLJS.xJ($CLJS.On,$CLJS.iJ);
$CLJS.X(ZK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.st,new $CLJS.k(null,1,[$CLJS.Au,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.EJ($CLJS.YJ,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZK],null)]));$CLJS.xJ($CLJS.YJ,$CLJS.iJ);
$CLJS.EJ($CLJS.sK,$CLJS.G([$CLJS.qu,$CLJS.OH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aJ],null)],null)]));$CLJS.EJ($CLJS.CK,$CLJS.G([$CLJS.qu,$CLJS.OH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pJ],null)],null)]));$CLJS.EJ($CLJS.yK,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pJ],null)],null)]));
$CLJS.EJ($CLJS.IK,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pJ],null)],null)]));$CLJS.xJ($CLJS.yK,$CLJS.iJ);$CLJS.xJ($CLJS.IK,$CLJS.iJ);$CLJS.EJ($CLJS.GK,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aJ],null)],null)]));$CLJS.xJ($CLJS.GK,$CLJS.iJ);
$CLJS.EJ($CLJS.TJ,$CLJS.G([$CLJS.qu,$CLJS.OH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pJ],null)],null)]));$CLJS.X(iL,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.lt,hL,eL,XK,QK,OK,WK,YK,$K,aL,dL,bL,aaa,fL,RK,cL,$CLJS.ul],null));$CLJS.X($CLJS.PK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.k(null,1,[$CLJS.On,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iL],null)],null));
$CLJS.kL=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.k(null,4,[$CLJS.eK,$CLJS.TA,$CLJS.SK,!1,$CLJS.gL,$CLJS.JJ,$CLJS.VK,function(){return new $CLJS.k(null,3,[$CLJS.qI,$CLJS.LI("Count of rows"),$CLJS.TK,$CLJS.LI("Count"),$CLJS.iv,$CLJS.LI("Total number of rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.eK,$CLJS.yK,$CLJS.NK,$CLJS.dk,$CLJS.SK,!0,$CLJS.gL,$CLJS.JJ,$CLJS.VK,function(){return new $CLJS.k(null,3,[$CLJS.qI,$CLJS.LI("Sum of ..."),$CLJS.TK,$CLJS.LI("Sum"),$CLJS.iv,$CLJS.LI("Sum of all the values of a column.")],
null)}],null),new $CLJS.k(null,5,[$CLJS.eK,$CLJS.fK,$CLJS.NK,$CLJS.dk,$CLJS.SK,!0,$CLJS.gL,$CLJS.JJ,$CLJS.VK,function(){return new $CLJS.k(null,3,[$CLJS.qI,$CLJS.LI("Average of ..."),$CLJS.TK,$CLJS.LI("Average"),$CLJS.iv,$CLJS.LI("Average of all the values of a column")],null)}],null),new $CLJS.k(null,5,[$CLJS.eK,$CLJS.zK,$CLJS.NK,$CLJS.dk,$CLJS.SK,!0,$CLJS.gL,$CLJS.hK,$CLJS.VK,function(){return new $CLJS.k(null,3,[$CLJS.qI,$CLJS.LI("Median of ..."),$CLJS.TK,$CLJS.LI("Median"),$CLJS.iv,$CLJS.LI("Median of all the values of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.eK,$CLJS.SJ,$CLJS.NK,$CLJS.Dt,$CLJS.SK,!0,$CLJS.gL,$CLJS.JJ,$CLJS.VK,function(){return new $CLJS.k(null,3,[$CLJS.qI,$CLJS.LI("Number of distinct values of ..."),$CLJS.TK,$CLJS.LI("Distinct values"),$CLJS.iv,$CLJS.LI("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.eK,$CLJS.IK,$CLJS.NK,$CLJS.dk,$CLJS.SK,!0,$CLJS.gL,$CLJS.JJ,$CLJS.VK,function(){return new $CLJS.k(null,3,[$CLJS.qI,$CLJS.LI("Cumulative sum of ..."),
$CLJS.TK,$CLJS.LI("Sum"),$CLJS.iv,$CLJS.LI("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.k(null,4,[$CLJS.eK,$CLJS.mK,$CLJS.SK,!1,$CLJS.gL,$CLJS.JJ,$CLJS.VK,function(){return new $CLJS.k(null,3,[$CLJS.qI,$CLJS.LI("Cumulative count of rows"),$CLJS.TK,$CLJS.LI("Count"),$CLJS.iv,$CLJS.LI("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.k(null,5,[$CLJS.eK,$CLJS.CK,$CLJS.NK,$CLJS.dk,$CLJS.SK,
!0,$CLJS.gL,$CLJS.vK,$CLJS.VK,function(){return new $CLJS.k(null,3,[$CLJS.qI,$CLJS.LI("Standard deviation of ..."),$CLJS.TK,$CLJS.LI("SD"),$CLJS.iv,$CLJS.LI("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.eK,$CLJS.On,$CLJS.NK,$CLJS.Xi,$CLJS.SK,!0,$CLJS.gL,$CLJS.JJ,$CLJS.VK,function(){return new $CLJS.k(null,3,[$CLJS.qI,$CLJS.LI("Minimum of ..."),$CLJS.TK,$CLJS.LI("Min"),$CLJS.iv,$CLJS.LI("Minimum value of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.eK,$CLJS.gk,$CLJS.NK,$CLJS.Xi,$CLJS.SK,!0,$CLJS.gL,$CLJS.JJ,$CLJS.VK,function(){return new $CLJS.k(null,3,[$CLJS.qI,$CLJS.LI("Maximum of ..."),$CLJS.TK,$CLJS.LI("Max"),$CLJS.iv,$CLJS.LI("Maximum value of a column")],null)}],null)],null);
$CLJS.X(UK,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.jL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eK,$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ht],null),$CLJS.ff.h($CLJS.eK),$CLJS.kL)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NK,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,$CLJS.Wi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SK,$CLJS.Ct],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.gL,$CLJS.Wi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VK,$CLJS.od],null)],null));