var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.registry.js");
'use strict';var CZ,DZ,efa,HZ,IZ,gfa,JZ,KZ,MZ,NZ,OZ,PZ,QZ,SZ,TZ,UZ,VZ,hfa,YZ,ZZ,ffa;
CZ=function(a,b){a=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bf,a],null)],null);for(var c=$CLJS.Bf;;){var d=$CLJS.sd(a);if($CLJS.n(d)){var e=d,f=$CLJS.I(e,0,null),h=$CLJS.I(e,1,null),l=e,m=$CLJS.td(a);a=function(t,u,v,x,A,D,H,K){return function(U){return $CLJS.eg.j(v,$CLJS.ff.h(function(Z,ca,ra,Ca,hb){return function(Ob){var Tb=$CLJS.I(Ob,0,null);Ob=$CLJS.I(Ob,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ae.g(hb,Tb),Ob],null)}}(t,u,v,x,A,D,H,K)),U)}}(a,c,m,e,f,h,l,d);
$CLJS.n(b.h?b.h(h):b.call(null,h))?(a=m,c=$CLJS.ae.g(c,l)):a=$CLJS.yd(h)?a(h):$CLJS.xd(h)?a($CLJS.Vl($CLJS.Gt,h)):m}else return c}};DZ=function(a,b,c){return $CLJS.Ad(c)&&$CLJS.F.g(a,$CLJS.B(c))&&!$CLJS.Id(b,$CLJS.J.g(c,2))};$CLJS.EZ=function(a){var b=$CLJS.eg.j($CLJS.eh,$CLJS.ff.h($CLJS.Hk.g($CLJS.dJ,$CLJS.jd)),$CLJS.NT.h(a));return CZ($CLJS.Ak.l(a,$CLJS.oR,$CLJS.G([$CLJS.cZ])),function(c){return DZ($CLJS.zE,b,c)})};
$CLJS.FZ=function(a){var b=$CLJS.eg.j($CLJS.eh,$CLJS.ff.h($CLJS.Hk.g($CLJS.BI,$CLJS.jd)),$CLJS.aK.h(a));return CZ($CLJS.Ak.l(a,$CLJS.oR,$CLJS.G([$CLJS.cZ])),function(c){return DZ($CLJS.aK,b,c)})};efa=function(a){function b(d){return $CLJS.gf(c,$CLJS.G([$CLJS.oR.h(d)]))}function c(d){return $CLJS.de($CLJS.$I.h(d),$CLJS.gf(b,$CLJS.G([$CLJS.iP.h(d)])))}return $CLJS.n($CLJS.GZ.h(a))?$CLJS.Pe(!0):$CLJS.ih(b(a))};
HZ=function(a){if($CLJS.xd(a))for(var b=$CLJS.Pe(!1),c=$CLJS.z(a),d=$CLJS.B(c),e=$CLJS.C(c),f=b,h=0,l=a;;){var m=f,t=h,u=l,v=$CLJS.z(u),x=$CLJS.B(v),A=$CLJS.C(v),D=x,H=A,K=$CLJS.WD(m,efa(D));f=$CLJS.B($CLJS.z($CLJS.jf($CLJS.Ua,function(U,Z,ca,ra,Ca,hb){return function dc(Tb,zc){try{if($CLJS.Ad(zc)&&3===$CLJS.E(zc))try{var Ac=$CLJS.cd(zc,0);if($CLJS.ge(Ac,$CLJS.lK))try{var wb=$CLJS.cd(zc,1);if($CLJS.n($CLJS.hT.h(wb)))try{var Ib=$CLJS.cd(zc,1);if(null!=Ib?Ib.C&256||$CLJS.r===Ib.We||(Ib.C?0:$CLJS.Xa($CLJS.sb,
Ib)):$CLJS.Xa($CLJS.sb,Ib))try{var dd=$CLJS.J.j(Ib,$CLJS.hT,$CLJS.xZ);if($CLJS.n($CLJS.Oe(ra)(dd))){var ri=$CLJS.J.g(Ib,$CLJS.hT);$CLJS.cd(zc,2);return new $CLJS.P(null,1,5,$CLJS.Q,[["Invalid :field reference in stage ",$CLJS.p.h(hb),": no join named ",$CLJS.Ph.l($CLJS.G([ri]))].join("")],null)}throw $CLJS.Y;}catch(Se){if(Se instanceof Error){var rf=Se;if(rf===$CLJS.Y)throw $CLJS.Y;throw rf;}throw Se;}else throw $CLJS.Y;}catch(Se){if(Se instanceof Error){rf=Se;if(rf===$CLJS.Y)throw $CLJS.Y;throw rf;
}throw Se;}else throw $CLJS.Y;}catch(Se){if(Se instanceof Error){rf=Se;if(rf===$CLJS.Y)throw $CLJS.Y;throw rf;}throw Se;}else throw $CLJS.Y;}catch(Se){if(Se instanceof Error){rf=Se;if(rf===$CLJS.Y)throw $CLJS.Y;throw rf;}throw Se;}else throw $CLJS.Y;}catch(Se){if(Se instanceof Error){rf=Se;if(rf===$CLJS.Y)return $CLJS.rZ(dc,Tb,zc);throw rf;}throw Se;}}}(f,h,l,K,m,t,u,v,x,A,D,H,b,0,a,a,c,d,e,d,e)($CLJS.Bf,$CLJS.Ak.l(D,$CLJS.oR,$CLJS.G([ffa]))))));if($CLJS.n(f))return f;if($CLJS.z(H))h=t+1,f=K,l=H;
else return null}else return null};IZ=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);gfa=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);JZ=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);KZ=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);
$CLJS.LZ=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);MZ=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);NZ=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);OZ=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);
PZ=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);QZ=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);$CLJS.RZ=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);SZ=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);TZ=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);
UZ=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);VZ=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.WZ=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.GZ=new $CLJS.M(null,"source-card","source-card",-1580820390);hfa=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);$CLJS.XZ=new $CLJS.M(null,"filters","filters",974726919);
YZ=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);ZZ=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);$CLJS.$Z=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);ffa=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.X(OZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.RZ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XT,$CLJS.Ua],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yj,$CLJS.ul],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZR,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),$CLJS.vI],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tU,new $CLJS.k(null,1,[$CLJS.Jt,
!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UY],null)],null)],null));$CLJS.X(IZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.k(null,1,[$CLJS.On,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pK],null)],null));$CLJS.X(TZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.k(null,1,[$CLJS.On,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pK],null)],null));
$CLJS.X(UZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aJ],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.MJ],null),$CLJS.fk,$CLJS.Xj],null)],null));$CLJS.X(SZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,new $CLJS.k(null,1,[$CLJS.On,1],null),UZ],null));
var a_=$CLJS.WD(function(a){a=$CLJS.B($CLJS.EZ(a));return $CLJS.n(a)?["Invalid :expression reference: no expression named ",$CLJS.Ph.l($CLJS.G([$CLJS.XD(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null))]))].join(""):null},function(a){a=$CLJS.B($CLJS.FZ(a));return $CLJS.n(a)?["Invalid :aggregation reference: no aggregation with uuid ",$CLJS.p.h($CLJS.XD(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null)))].join(""):null});
$CLJS.X(VZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,2,[$CLJS.Au,"Valid references for a single query stage",$CLJS.Cu,function(a){a=$CLJS.Ge(a);a=$CLJS.J.g(a,$CLJS.Cj);return a_(a)}],null),$CLJS.Oe(a_)],null));
$CLJS.X($CLJS.$Z,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.LZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oR,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NT,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.uS,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),IZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aK,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WI,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),TZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XZ,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),SZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DV,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nP],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TS,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XO],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GZ,new $CLJS.k(null,1,[$CLJS.Jt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZO],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Id(a,$CLJS.wU)}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,
new $CLJS.k(null,1,[$CLJS.Au,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Oe($CLJS.wP($CLJS.TS,$CLJS.GZ))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VZ],null)],null));$CLJS.X(JZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$Z],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XO],null)],null)],null)],null));
$CLJS.X(KZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$Z],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZO],null)],null)],null)],null));$CLJS.X(NZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JZ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KZ],null)],null));
$CLJS.X(PZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$Z],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,1,[$CLJS.Au,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Oe($CLJS.WD($CLJS.TS,$CLJS.GZ))],null)],null));$CLJS.X(QZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ht,$CLJS.RZ,$CLJS.LZ],null));
$CLJS.X(gfa,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,QZ],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,1,[$CLJS.Qi,$CLJS.Dj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OZ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$Z],null)],null)],null)],null));
$CLJS.X(ZZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,QZ],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rt,new $CLJS.k(null,1,[$CLJS.Qi,$CLJS.Dj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OZ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NZ],null)],null)],null)],null));$CLJS.X(MZ,PZ);
$CLJS.X(YZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.it,new $CLJS.k(null,2,[$CLJS.Au,"Valid references for all query stages",$CLJS.Cu,function(a){a=$CLJS.Ge(a);a=$CLJS.J.g(a,$CLJS.Cj);return HZ.h?HZ.h(a):HZ.call(null,a)}],null),$CLJS.Oe(HZ)],null));
$CLJS.X($CLJS.eP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZZ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MZ],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YZ],null)],null));
$CLJS.X(hfa,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.st,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.WZ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,$CLJS.$O,$CLJS.aP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iP,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eP],null)],null)],null),$CLJS.Yea],null));